"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function e(t, n, r) {
	function s(o, u) {
		if (!n[o]) {
			if (!t[o]) {
				var a = typeof require == "function" && require;if (!u && a) return a(o, !0);if (i) return i(o, !0);throw new Error("Cannot find module '" + o + "'");
			}var f = n[o] = { exports: {} };t[o][0].call(f.exports, function (e) {
				var n = t[o][1][e];return s(n ? n : e);
			}, f, f.exports, e, t, n, r);
		}return n[o].exports;
	}var i = typeof require == "function" && require;for (var o = 0; o < r.length; o++) {
		s(r[o]);
	}return s;
})({ 1: [function (require, module, exports) {
		console.log($(window).width());
		if ($(window).width() >= 1200) {

			var clientX = -100;
			var clientY = -100;
			var innerCursor = document.querySelector(".cursor--small");

			var initCursor = function initCursor() {
				document.addEventListener("mousemove", function (e) {
					clientX = e.clientX;
					clientY = e.clientY;
				});

				var render = function render() {
					innerCursor.style.transform = "translate(" + clientX + "px, " + clientY + "px)";

					requestAnimationFrame(render);
				};
				requestAnimationFrame(render);
			};

			initCursor();

			var lastX = 0;
			var lastY = 0;
			var isStuck = false;
			var showCursor = false;
			var group = void 0,
			    stuckX = void 0,
			    stuckY = void 0,
			    fillOuterCursor = void 0;

			var initCanvas = function initCanvas() {
				var canvas = document.querySelector(".cursor--canvas");
				var shapeBounds = {
					width: 75,
					height: 75
				};
				paper.setup(canvas);
				var strokeColor = "#fff";
				var strokeWidth = 1;
				var segments = 8;
				var radius = 15;

				var noiseScale = 150;
				var noiseRange = 4;
				var isNoisy = false;

				var polygon = new paper.Path.RegularPolygon(new paper.Point(0, 0), segments, radius);
				polygon.strokeColor = strokeColor;
				polygon.strokeWidth = strokeWidth;
				polygon.smooth();
				group = new paper.Group([polygon]);
				group.applyMatrix = false;

				var noiseObjects = polygon.segments.map(function () {
					return new SimplexNoise();
				});
				var bigCoordinates = [];

				var lerp = function lerp(a, b, n) {
					return (1 - n) * a + n * b;
				};

				var map = function map(value, in_min, in_max, out_min, out_max) {
					return (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
				};

				paper.view.onFrame = function (event) {
					if (!isStuck) {
						lastX = lerp(lastX, clientX, 0.2);
						lastY = lerp(lastY, clientY, 0.2);
						group.position = new paper.Point(lastX, lastY);
					} else if (isStuck) {
						lastX = lerp(lastX, stuckX, 0.2);
						lastY = lerp(lastY, stuckY, 0.2);
						group.position = new paper.Point(lastX, lastY);
					}

					if (isStuck && polygon.bounds.width < shapeBounds.width) {
						polygon.scale(1.08);
					} else if (!isStuck && polygon.bounds.width > 30) {
						if (isNoisy) {
							polygon.segments.forEach(function (segment, i) {
								segment.point.set(bigCoordinates[i][0], bigCoordinates[i][1]);
							});
							isNoisy = false;
							bigCoordinates = [];
						}
						var scaleDown = 0.92;
						polygon.scale(scaleDown);
					}

					if (isStuck && polygon.bounds.width >= shapeBounds.width) {
						isNoisy = true;
						if (bigCoordinates.length === 0) {
							polygon.segments.forEach(function (segment, i) {
								bigCoordinates[i] = [segment.point.x, segment.point.y];
							});
						}

						polygon.segments.forEach(function (segment, i) {

							var noiseX = noiseObjects[i].noise2D(event.count / noiseScale, 0);
							var noiseY = noiseObjects[i].noise2D(event.count / noiseScale, 1);

							var distortionX = map(noiseX, -1, 1, -noiseRange, noiseRange);
							var distortionY = map(noiseY, -1, 1, -noiseRange, noiseRange);

							var newX = bigCoordinates[i][0] + distortionX;
							var newY = bigCoordinates[i][1] + distortionY;

							segment.point.set(newX, newY);
						});
					}
					polygon.smooth();
				};
			};

			initCanvas();

			var initHovers = function initHovers() {

				var handleMouseEnter = function handleMouseEnter(e) {
					var navItem = e.currentTarget;
					var navItemBox = navItem.getBoundingClientRect();
					stuckX = Math.round(navItemBox.left + navItemBox.width / 2);
					stuckY = Math.round(navItemBox.top + navItemBox.height / 2);
					isStuck = true;
				};

				var handleMouseLeave = function handleMouseLeave() {
					isStuck = false;
				};

				var linkItems = document.querySelectorAll(".menuList__link, .logo__wrapLink, .link, .js_cursor");
				linkItems.forEach(function (item) {
					item.addEventListener("mouseenter", handleMouseEnter);
					item.addEventListener("mouseleave", handleMouseLeave);
				});
			};

			initHovers();
		}
	}, {}], 2: [function (require, module, exports) {
		'use strict';

		var $ = require('jquery');

		module.exports = function () {
			var tabsWork = function tabsWork(e, jqSelf) {
				var choosenIndex = jqSelf.attr('data-tabitem');
				var tabParent = jqSelf.parents('.js-tabs');

				tabParent.find('.tabs__item').removeClass('isActive');
				jqSelf.addClass('isActive');
				tabParent.find('.tabs__contentItem').removeClass('isOpen');
				tabParent.find('.tabs__contentItem[data-tabcontent="' + choosenIndex + '"]').addClass('isOpen');
			};

			$('body').on('click', '.js-tabs .tabs__item', function (e) {
				var self = $(this);
				tabsWork(e, self);
			});
		};
	}, { "jquery": 4 }], 3: [function (require, module, exports) {
		'use strict';


		window.$ = window.jQuery = require('jquery');













		var tabs = require('./components/tabs.js');tabs();


		var cursor = require('./components/customCursor.js');

		window.lightSlider = require('lightslider');
	}, { "./components/customCursor.js": 1, "./components/tabs.js": 2, "jquery": 4, "lightslider": 5 }], 4: [function (require, module, exports) {

		(function (global, factory) {

			if ((typeof module === "undefined" ? "undefined" : _typeof(module)) === "object" && _typeof(module.exports) === "object") {
				module.exports = global.document ? factory(global, true) : function (w) {
					if (!w.document) {
						throw new Error("jQuery requires a window with a document");
					}
					return factory(w);
				};
			} else {
				factory(global);
			}

		})(typeof window !== "undefined" ? window : this, function (window, noGlobal) {

			var deletedIds = [];

			var document = window.document;

			var _slice = deletedIds.slice;

			var concat = deletedIds.concat;

			var push = deletedIds.push;

			var indexOf = deletedIds.indexOf;

			var class2type = {};

			var toString = class2type.toString;

			var hasOwn = class2type.hasOwnProperty;

			var support = {};

			var version = "1.12.4",


			jQuery = function jQuery(selector, context) {

				return new jQuery.fn.init(selector, context);
			},


			rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g,


			rmsPrefix = /^-ms-/,
			    rdashAlpha = /-([\da-z])/gi,


			fcamelCase = function fcamelCase(all, letter) {
				return letter.toUpperCase();
			};

			jQuery.fn = jQuery.prototype = {

				jquery: version,

				constructor: jQuery,

				selector: "",

				length: 0,

				toArray: function toArray() {
					return _slice.call(this);
				},

				get: function get(num) {
					return num != null ?

					num < 0 ? this[num + this.length] : this[num] :

					_slice.call(this);
				},

				pushStack: function pushStack(elems) {

					var ret = jQuery.merge(this.constructor(), elems);

					ret.prevObject = this;
					ret.context = this.context;

					return ret;
				},

				each: function each(callback) {
					return jQuery.each(this, callback);
				},

				map: function map(callback) {
					return this.pushStack(jQuery.map(this, function (elem, i) {
						return callback.call(elem, i, elem);
					}));
				},

				slice: function slice() {
					return this.pushStack(_slice.apply(this, arguments));
				},

				first: function first() {
					return this.eq(0);
				},

				last: function last() {
					return this.eq(-1);
				},

				eq: function eq(i) {
					var len = this.length,
					    j = +i + (i < 0 ? len : 0);
					return this.pushStack(j >= 0 && j < len ? [this[j]] : []);
				},

				end: function end() {
					return this.prevObject || this.constructor();
				},

				push: push,
				sort: deletedIds.sort,
				splice: deletedIds.splice
			};

			jQuery.extend = jQuery.fn.extend = function () {
				var src,
				    copyIsArray,
				    copy,
				    name,
				    options,
				    clone,
				    target = arguments[0] || {},
				    i = 1,
				    length = arguments.length,
				    deep = false;

				if (typeof target === "boolean") {
					deep = target;

					target = arguments[i] || {};
					i++;
				}

				if ((typeof target === "undefined" ? "undefined" : _typeof(target)) !== "object" && !jQuery.isFunction(target)) {
					target = {};
				}

				if (i === length) {
					target = this;
					i--;
				}

				for (; i < length; i++) {

					if ((options = arguments[i]) != null) {

						for (name in options) {
							src = target[name];
							copy = options[name];

							if (target === copy) {
								continue;
							}

							if (deep && copy && (jQuery.isPlainObject(copy) || (copyIsArray = jQuery.isArray(copy)))) {

								if (copyIsArray) {
									copyIsArray = false;
									clone = src && jQuery.isArray(src) ? src : [];
								} else {
									clone = src && jQuery.isPlainObject(src) ? src : {};
								}

								target[name] = jQuery.extend(deep, clone, copy);

							} else if (copy !== undefined) {
								target[name] = copy;
							}
						}
					}
				}

				return target;
			};

			jQuery.extend({

				expando: "jQuery" + (version + Math.random()).replace(/\D/g, ""),

				isReady: true,

				error: function error(msg) {
					throw new Error(msg);
				},

				noop: function noop() {},

				isFunction: function isFunction(obj) {
					return jQuery.type(obj) === "function";
				},

				isArray: Array.isArray || function (obj) {
					return jQuery.type(obj) === "array";
				},

				isWindow: function isWindow(obj) {
					return obj != null && obj == obj.window;
				},

				isNumeric: function isNumeric(obj) {

					var realStringObj = obj && obj.toString();
					return !jQuery.isArray(obj) && realStringObj - parseFloat(realStringObj) + 1 >= 0;
				},

				isEmptyObject: function isEmptyObject(obj) {
					var name;
					for (name in obj) {
						return false;
					}
					return true;
				},

				isPlainObject: function isPlainObject(obj) {
					var key;

					if (!obj || jQuery.type(obj) !== "object" || obj.nodeType || jQuery.isWindow(obj)) {
						return false;
					}

					try {

						if (obj.constructor && !hasOwn.call(obj, "constructor") && !hasOwn.call(obj.constructor.prototype, "isPrototypeOf")) {
							return false;
						}
					} catch (e) {

						return false;
					}

					if (!support.ownFirst) {
						for (key in obj) {
							return hasOwn.call(obj, key);
						}
					}

					for (key in obj) {}

					return key === undefined || hasOwn.call(obj, key);
				},

				type: function type(obj) {
					if (obj == null) {
						return obj + "";
					}
					return (typeof obj === "undefined" ? "undefined" : _typeof(obj)) === "object" || typeof obj === "function" ? class2type[toString.call(obj)] || "object" : typeof obj === "undefined" ? "undefined" : _typeof(obj);
				},

				globalEval: function globalEval(data) {
					if (data && jQuery.trim(data)) {

						(window.execScript || function (data) {
							window["eval"].call(window, data); 
						})(data);
					}
				},

				camelCase: function camelCase(string) {
					return string.replace(rmsPrefix, "ms-").replace(rdashAlpha, fcamelCase);
				},

				nodeName: function nodeName(elem, name) {
					return elem.nodeName && elem.nodeName.toLowerCase() === name.toLowerCase();
				},

				each: function each(obj, callback) {
					var length,
					    i = 0;

					if (isArrayLike(obj)) {
						length = obj.length;
						for (; i < length; i++) {
							if (callback.call(obj[i], i, obj[i]) === false) {
								break;
							}
						}
					} else {
						for (i in obj) {
							if (callback.call(obj[i], i, obj[i]) === false) {
								break;
							}
						}
					}

					return obj;
				},

				trim: function trim(text) {
					return text == null ? "" : (text + "").replace(rtrim, "");
				},

				makeArray: function makeArray(arr, results) {
					var ret = results || [];

					if (arr != null) {
						if (isArrayLike(Object(arr))) {
							jQuery.merge(ret, typeof arr === "string" ? [arr] : arr);
						} else {
							push.call(ret, arr);
						}
					}

					return ret;
				},

				inArray: function inArray(elem, arr, i) {
					var len;

					if (arr) {
						if (indexOf) {
							return indexOf.call(arr, elem, i);
						}

						len = arr.length;
						i = i ? i < 0 ? Math.max(0, len + i) : i : 0;

						for (; i < len; i++) {

							if (i in arr && arr[i] === elem) {
								return i;
							}
						}
					}

					return -1;
				},

				merge: function merge(first, second) {
					var len = +second.length,
					    j = 0,
					    i = first.length;

					while (j < len) {
						first[i++] = second[j++];
					}

					if (len !== len) {
						while (second[j] !== undefined) {
							first[i++] = second[j++];
						}
					}

					first.length = i;

					return first;
				},

				grep: function grep(elems, callback, invert) {
					var callbackInverse,
					    matches = [],
					    i = 0,
					    length = elems.length,
					    callbackExpect = !invert;

					for (; i < length; i++) {
						callbackInverse = !callback(elems[i], i);
						if (callbackInverse !== callbackExpect) {
							matches.push(elems[i]);
						}
					}

					return matches;
				},

				map: function map(elems, callback, arg) {
					var length,
					    value,
					    i = 0,
					    ret = [];

					if (isArrayLike(elems)) {
						length = elems.length;
						for (; i < length; i++) {
							value = callback(elems[i], i, arg);

							if (value != null) {
								ret.push(value);
							}
						}

					} else {
						for (i in elems) {
							value = callback(elems[i], i, arg);

							if (value != null) {
								ret.push(value);
							}
						}
					}

					return concat.apply([], ret);
				},

				guid: 1,

				proxy: function proxy(fn, context) {
					var args, proxy, tmp;

					if (typeof context === "string") {
						tmp = fn[context];
						context = fn;
						fn = tmp;
					}

					if (!jQuery.isFunction(fn)) {
						return undefined;
					}

					args = _slice.call(arguments, 2);
					proxy = function proxy() {
						return fn.apply(context || this, args.concat(_slice.call(arguments)));
					};

					proxy.guid = fn.guid = fn.guid || jQuery.guid++;

					return proxy;
				},

				now: function now() {
					return +new Date();
				},

				support: support
			});

			if (typeof Symbol === "function") {
				jQuery.fn[Symbol.iterator] = deletedIds[Symbol.iterator];
			}

			jQuery.each("Boolean Number String Function Array Date RegExp Object Error Symbol".split(" "), function (i, name) {
				class2type["[object " + name + "]"] = name.toLowerCase();
			});

			function isArrayLike(obj) {

				var length = !!obj && "length" in obj && obj.length,
				    type = jQuery.type(obj);

				if (type === "function" || jQuery.isWindow(obj)) {
					return false;
				}

				return type === "array" || length === 0 || typeof length === "number" && length > 0 && length - 1 in obj;
			}
			var Sizzle =
			function (window) {

				var i,
				    support,
				    Expr,
				    getText,
				    isXML,
				    tokenize,
				    compile,
				    select,
				    outermostContext,
				    sortInput,
				    hasDuplicate,


				setDocument,
				    document,
				    docElem,
				    documentIsHTML,
				    rbuggyQSA,
				    rbuggyMatches,
				    matches,
				    contains,


				expando = "sizzle" + 1 * new Date(),
				    preferredDoc = window.document,
				    dirruns = 0,
				    done = 0,
				    classCache = createCache(),
				    tokenCache = createCache(),
				    compilerCache = createCache(),
				    sortOrder = function sortOrder(a, b) {
					if (a === b) {
						hasDuplicate = true;
					}
					return 0;
				},


				MAX_NEGATIVE = 1 << 31,


				hasOwn = {}.hasOwnProperty,
				    arr = [],
				    pop = arr.pop,
				    push_native = arr.push,
				    push = arr.push,
				    slice = arr.slice,

				indexOf = function indexOf(list, elem) {
					var i = 0,
					    len = list.length;
					for (; i < len; i++) {
						if (list[i] === elem) {
							return i;
						}
					}
					return -1;
				},
				    booleans = "checked|selected|async|autofocus|autoplay|controls|defer|disabled|hidden|ismap|loop|multiple|open|readonly|required|scoped",



				whitespace = "[\\x20\\t\\r\\n\\f]",


				identifier = "(?:\\\\.|[\\w-]|[^\\x00-\\xa0])+",


				attributes = "\\[" + whitespace + "*(" + identifier + ")(?:" + whitespace +
				"*([*^$|!~]?=)" + whitespace +
				"*(?:'((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\"|(" + identifier + "))|)" + whitespace + "*\\]",
				    pseudos = ":(" + identifier + ")(?:\\((" +
				"('((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\")|" +
				"((?:\\\\.|[^\\\\()[\\]]|" + attributes + ")*)|" +
				".*" + ")\\)|)",


				rwhitespace = new RegExp(whitespace + "+", "g"),
				    rtrim = new RegExp("^" + whitespace + "+|((?:^|[^\\\\])(?:\\\\.)*)" + whitespace + "+$", "g"),
				    rcomma = new RegExp("^" + whitespace + "*," + whitespace + "*"),
				    rcombinators = new RegExp("^" + whitespace + "*([>+~]|" + whitespace + ")" + whitespace + "*"),
				    rattributeQuotes = new RegExp("=" + whitespace + "*([^\\]'\"]*?)" + whitespace + "*\\]", "g"),
				    rpseudo = new RegExp(pseudos),
				    ridentifier = new RegExp("^" + identifier + "$"),
				    matchExpr = {
					"ID": new RegExp("^#(" + identifier + ")"),
					"CLASS": new RegExp("^\\.(" + identifier + ")"),
					"TAG": new RegExp("^(" + identifier + "|[*])"),
					"ATTR": new RegExp("^" + attributes),
					"PSEUDO": new RegExp("^" + pseudos),
					"CHILD": new RegExp("^:(only|first|last|nth|nth-last)-(child|of-type)(?:\\(" + whitespace + "*(even|odd|(([+-]|)(\\d*)n|)" + whitespace + "*(?:([+-]|)" + whitespace + "*(\\d+)|))" + whitespace + "*\\)|)", "i"),
					"bool": new RegExp("^(?:" + booleans + ")$", "i"),
					"needsContext": new RegExp("^" + whitespace + "*[>+~]|:(even|odd|eq|gt|lt|nth|first|last)(?:\\(" + whitespace + "*((?:-\\d)?\\d*)" + whitespace + "*\\)|)(?=[^-]|$)", "i")
				},
				    rinputs = /^(?:input|select|textarea|button)$/i,
				    rheader = /^h\d$/i,
				    rnative = /^[^{]+\{\s*\[native \w/,


				rquickExpr = /^(?:#([\w-]+)|(\w+)|\.([\w-]+))$/,
				    rsibling = /[+~]/,
				    rescape = /'|\\/g,


				runescape = new RegExp("\\\\([\\da-f]{1,6}" + whitespace + "?|(" + whitespace + ")|.)", "ig"),
				    funescape = function funescape(_, escaped, escapedWhitespace) {
					var high = "0x" + escaped - 0x10000;
					return high !== high || escapedWhitespace ? escaped : high < 0 ?
					String.fromCharCode(high + 0x10000) :
					String.fromCharCode(high >> 10 | 0xD800, high & 0x3FF | 0xDC00);
				},


				unloadHandler = function unloadHandler() {
					setDocument();
				};

				try {
					push.apply(arr = slice.call(preferredDoc.childNodes), preferredDoc.childNodes);
					arr[preferredDoc.childNodes.length].nodeType;
				} catch (e) {
					push = { apply: arr.length ?

						function (target, els) {
							push_native.apply(target, slice.call(els));
						} :

						function (target, els) {
							var j = target.length,
							    i = 0;
							while (target[j++] = els[i++]) {}
							target.length = j - 1;
						}
					};
				}

				function Sizzle(selector, context, results, seed) {
					var m,
					    i,
					    elem,
					    nid,
					    nidselect,
					    match,
					    groups,
					    newSelector,
					    newContext = context && context.ownerDocument,


					nodeType = context ? context.nodeType : 9;

					results = results || [];

					if (typeof selector !== "string" || !selector || nodeType !== 1 && nodeType !== 9 && nodeType !== 11) {

						return results;
					}

					if (!seed) {

						if ((context ? context.ownerDocument || context : preferredDoc) !== document) {
							setDocument(context);
						}
						context = context || document;

						if (documentIsHTML) {

							if (nodeType !== 11 && (match = rquickExpr.exec(selector))) {

								if (m = match[1]) {

									if (nodeType === 9) {
										if (elem = context.getElementById(m)) {

											if (elem.id === m) {
												results.push(elem);
												return results;
											}
										} else {
											return results;
										}

									} else {

										if (newContext && (elem = newContext.getElementById(m)) && contains(context, elem) && elem.id === m) {

											results.push(elem);
											return results;
										}
									}

								} else if (match[2]) {
									push.apply(results, context.getElementsByTagName(selector));
									return results;

								} else if ((m = match[3]) && support.getElementsByClassName && context.getElementsByClassName) {

									push.apply(results, context.getElementsByClassName(m));
									return results;
								}
							}

							if (support.qsa && !compilerCache[selector + " "] && (!rbuggyQSA || !rbuggyQSA.test(selector))) {

								if (nodeType !== 1) {
									newContext = context;
									newSelector = selector;

								} else if (context.nodeName.toLowerCase() !== "object") {

									if (nid = context.getAttribute("id")) {
										nid = nid.replace(rescape, "\\$&");
									} else {
										context.setAttribute("id", nid = expando);
									}

									groups = tokenize(selector);
									i = groups.length;
									nidselect = ridentifier.test(nid) ? "#" + nid : "[id='" + nid + "']";
									while (i--) {
										groups[i] = nidselect + " " + toSelector(groups[i]);
									}
									newSelector = groups.join(",");

									newContext = rsibling.test(selector) && testContext(context.parentNode) || context;
								}

								if (newSelector) {
									try {
										push.apply(results, newContext.querySelectorAll(newSelector));
										return results;
									} catch (qsaError) {} finally {
										if (nid === expando) {
											context.removeAttribute("id");
										}
									}
								}
							}
						}
					}

					return select(selector.replace(rtrim, "$1"), context, results, seed);
				}

				/**
     * Create key-value caches of limited size
     * @returns {function(string, object)} Returns the Object data after storing it on itself with
     *	property name the (space-suffixed) string and (if the cache is larger than Expr.cacheLength)
     *	deleting the oldest entry
     */
				function createCache() {
					var keys = [];

					function cache(key, value) {
						if (keys.push(key + " ") > Expr.cacheLength) {
							delete cache[keys.shift()];
						}
						return cache[key + " "] = value;
					}
					return cache;
				}

				/**
     * Mark a function for special use by Sizzle
     * @param {Function} fn The function to mark
     */
				function markFunction(fn) {
					fn[expando] = true;
					return fn;
				}

				/**
     * Support testing using an element
     * @param {Function} fn Passed the created div and expects a boolean result
     */
				function assert(fn) {
					var div = document.createElement("div");

					try {
						return !!fn(div);
					} catch (e) {
						return false;
					} finally {
						if (div.parentNode) {
							div.parentNode.removeChild(div);
						}
						div = null;
					}
				}

				/**
     * Adds the same handler for all of the specified attrs
     * @param {String} attrs Pipe-separated list of attributes
     * @param {Function} handler The method that will be applied
     */
				function addHandle(attrs, handler) {
					var arr = attrs.split("|"),
					    i = arr.length;

					while (i--) {
						Expr.attrHandle[arr[i]] = handler;
					}
				}

				/**
     * Checks document order of two siblings
     * @param {Element} a
     * @param {Element} b
     * @returns {Number} Returns less than 0 if a precedes b, greater than 0 if a follows b
     */
				function siblingCheck(a, b) {
					var cur = b && a,
					    diff = cur && a.nodeType === 1 && b.nodeType === 1 && (~b.sourceIndex || MAX_NEGATIVE) - (~a.sourceIndex || MAX_NEGATIVE);

					if (diff) {
						return diff;
					}

					if (cur) {
						while (cur = cur.nextSibling) {
							if (cur === b) {
								return -1;
							}
						}
					}

					return a ? 1 : -1;
				}

				/**
     * Returns a function to use in pseudos for input types
     * @param {String} type
     */
				function createInputPseudo(type) {
					return function (elem) {
						var name = elem.nodeName.toLowerCase();
						return name === "input" && elem.type === type;
					};
				}

				/**
     * Returns a function to use in pseudos for buttons
     * @param {String} type
     */
				function createButtonPseudo(type) {
					return function (elem) {
						var name = elem.nodeName.toLowerCase();
						return (name === "input" || name === "button") && elem.type === type;
					};
				}

				/**
     * Returns a function to use in pseudos for positionals
     * @param {Function} fn
     */
				function createPositionalPseudo(fn) {
					return markFunction(function (argument) {
						argument = +argument;
						return markFunction(function (seed, matches) {
							var j,
							    matchIndexes = fn([], seed.length, argument),
							    i = matchIndexes.length;

							while (i--) {
								if (seed[j = matchIndexes[i]]) {
									seed[j] = !(matches[j] = seed[j]);
								}
							}
						});
					});
				}

				/**
     * Checks a node for validity as a Sizzle context
     * @param {Element|Object=} context
     * @returns {Element|Object|Boolean} The input node if acceptable, otherwise a falsy value
     */
				function testContext(context) {
					return context && typeof context.getElementsByTagName !== "undefined" && context;
				}

				support = Sizzle.support = {};

				/**
     * Detects XML nodes
     * @param {Element|Object} elem An element or a document
     * @returns {Boolean} True iff elem is a non-HTML XML node
     */
				isXML = Sizzle.isXML = function (elem) {
					var documentElement = elem && (elem.ownerDocument || elem).documentElement;
					return documentElement ? documentElement.nodeName !== "HTML" : false;
				};

				/**
     * Sets document-related variables once based on the current document
     * @param {Element|Object} [doc] An element or document object to use to set the document
     * @returns {Object} Returns the current document
     */
				setDocument = Sizzle.setDocument = function (node) {
					var hasCompare,
					    parent,
					    doc = node ? node.ownerDocument || node : preferredDoc;

					if (doc === document || doc.nodeType !== 9 || !doc.documentElement) {
						return document;
					}

					document = doc;
					docElem = document.documentElement;
					documentIsHTML = !isXML(document);

					if ((parent = document.defaultView) && parent.top !== parent) {
						if (parent.addEventListener) {
							parent.addEventListener("unload", unloadHandler, false);

						} else if (parent.attachEvent) {
							parent.attachEvent("onunload", unloadHandler);
						}
					}


					support.attributes = assert(function (div) {
						div.className = "i";
						return !div.getAttribute("className");
					});


					support.getElementsByTagName = assert(function (div) {
						div.appendChild(document.createComment(""));
						return !div.getElementsByTagName("*").length;
					});

					support.getElementsByClassName = rnative.test(document.getElementsByClassName);

					support.getById = assert(function (div) {
						docElem.appendChild(div).id = expando;
						return !document.getElementsByName || !document.getElementsByName(expando).length;
					});

					if (support.getById) {
						Expr.find["ID"] = function (id, context) {
							if (typeof context.getElementById !== "undefined" && documentIsHTML) {
								var m = context.getElementById(id);
								return m ? [m] : [];
							}
						};
						Expr.filter["ID"] = function (id) {
							var attrId = id.replace(runescape, funescape);
							return function (elem) {
								return elem.getAttribute("id") === attrId;
							};
						};
					} else {
						delete Expr.find["ID"];

						Expr.filter["ID"] = function (id) {
							var attrId = id.replace(runescape, funescape);
							return function (elem) {
								var node = typeof elem.getAttributeNode !== "undefined" && elem.getAttributeNode("id");
								return node && node.value === attrId;
							};
						};
					}

					Expr.find["TAG"] = support.getElementsByTagName ? function (tag, context) {
						if (typeof context.getElementsByTagName !== "undefined") {
							return context.getElementsByTagName(tag);

						} else if (support.qsa) {
							return context.querySelectorAll(tag);
						}
					} : function (tag, context) {
						var elem,
						    tmp = [],
						    i = 0,

						results = context.getElementsByTagName(tag);

						if (tag === "*") {
							while (elem = results[i++]) {
								if (elem.nodeType === 1) {
									tmp.push(elem);
								}
							}

							return tmp;
						}
						return results;
					};

					Expr.find["CLASS"] = support.getElementsByClassName && function (className, context) {
						if (typeof context.getElementsByClassName !== "undefined" && documentIsHTML) {
							return context.getElementsByClassName(className);
						}
					};



					rbuggyMatches = [];

					rbuggyQSA = [];

					if (support.qsa = rnative.test(document.querySelectorAll)) {
						assert(function (div) {
							docElem.appendChild(div).innerHTML = "<a id='" + expando + "'></a>" + "<select id='" + expando + "-\r\\' msallowcapture=''>" + "<option selected=''></option></select>";

							if (div.querySelectorAll("[msallowcapture^='']").length) {
								rbuggyQSA.push("[*^$]=" + whitespace + "*(?:''|\"\")");
							}

							if (!div.querySelectorAll("[selected]").length) {
								rbuggyQSA.push("\\[" + whitespace + "*(?:value|" + booleans + ")");
							}

							if (!div.querySelectorAll("[id~=" + expando + "-]").length) {
								rbuggyQSA.push("~=");
							}

							if (!div.querySelectorAll(":checked").length) {
								rbuggyQSA.push(":checked");
							}

							if (!div.querySelectorAll("a#" + expando + "+*").length) {
								rbuggyQSA.push(".#.+[+~]");
							}
						});

						assert(function (div) {
							var input = document.createElement("input");
							input.setAttribute("type", "hidden");
							div.appendChild(input).setAttribute("name", "D");

							if (div.querySelectorAll("[name=d]").length) {
								rbuggyQSA.push("name" + whitespace + "*[*^$|!~]?=");
							}

							if (!div.querySelectorAll(":enabled").length) {
								rbuggyQSA.push(":enabled", ":disabled");
							}

							div.querySelectorAll("*,:x");
							rbuggyQSA.push(",.*:");
						});
					}

					if (support.matchesSelector = rnative.test(matches = docElem.matches || docElem.webkitMatchesSelector || docElem.mozMatchesSelector || docElem.oMatchesSelector || docElem.msMatchesSelector)) {

						assert(function (div) {
							support.disconnectedMatch = matches.call(div, "div");

							matches.call(div, "[s!='']:x");
							rbuggyMatches.push("!=", pseudos);
						});
					}

					rbuggyQSA = rbuggyQSA.length && new RegExp(rbuggyQSA.join("|"));
					rbuggyMatches = rbuggyMatches.length && new RegExp(rbuggyMatches.join("|"));

					hasCompare = rnative.test(docElem.compareDocumentPosition);

					contains = hasCompare || rnative.test(docElem.contains) ? function (a, b) {
						var adown = a.nodeType === 9 ? a.documentElement : a,
						    bup = b && b.parentNode;
						return a === bup || !!(bup && bup.nodeType === 1 && (adown.contains ? adown.contains(bup) : a.compareDocumentPosition && a.compareDocumentPosition(bup) & 16));
					} : function (a, b) {
						if (b) {
							while (b = b.parentNode) {
								if (b === a) {
									return true;
								}
							}
						}
						return false;
					};


					sortOrder = hasCompare ? function (a, b) {

						if (a === b) {
							hasDuplicate = true;
							return 0;
						}

						var compare = !a.compareDocumentPosition - !b.compareDocumentPosition;
						if (compare) {
							return compare;
						}

						compare = (a.ownerDocument || a) === (b.ownerDocument || b) ? a.compareDocumentPosition(b) :

						1;

						if (compare & 1 || !support.sortDetached && b.compareDocumentPosition(a) === compare) {

							if (a === document || a.ownerDocument === preferredDoc && contains(preferredDoc, a)) {
								return -1;
							}
							if (b === document || b.ownerDocument === preferredDoc && contains(preferredDoc, b)) {
								return 1;
							}

							return sortInput ? indexOf(sortInput, a) - indexOf(sortInput, b) : 0;
						}

						return compare & 4 ? -1 : 1;
					} : function (a, b) {
						if (a === b) {
							hasDuplicate = true;
							return 0;
						}

						var cur,
						    i = 0,
						    aup = a.parentNode,
						    bup = b.parentNode,
						    ap = [a],
						    bp = [b];

						if (!aup || !bup) {
							return a === document ? -1 : b === document ? 1 : aup ? -1 : bup ? 1 : sortInput ? indexOf(sortInput, a) - indexOf(sortInput, b) : 0;

						} else if (aup === bup) {
							return siblingCheck(a, b);
						}

						cur = a;
						while (cur = cur.parentNode) {
							ap.unshift(cur);
						}
						cur = b;
						while (cur = cur.parentNode) {
							bp.unshift(cur);
						}

						while (ap[i] === bp[i]) {
							i++;
						}

						return i ?
						siblingCheck(ap[i], bp[i]) :

						ap[i] === preferredDoc ? -1 : bp[i] === preferredDoc ? 1 : 0;
					};

					return document;
				};

				Sizzle.matches = function (expr, elements) {
					return Sizzle(expr, null, null, elements);
				};

				Sizzle.matchesSelector = function (elem, expr) {
					if ((elem.ownerDocument || elem) !== document) {
						setDocument(elem);
					}

					expr = expr.replace(rattributeQuotes, "='$1']");

					if (support.matchesSelector && documentIsHTML && !compilerCache[expr + " "] && (!rbuggyMatches || !rbuggyMatches.test(expr)) && (!rbuggyQSA || !rbuggyQSA.test(expr))) {

						try {
							var ret = matches.call(elem, expr);

							if (ret || support.disconnectedMatch ||
							elem.document && elem.document.nodeType !== 11) {
								return ret;
							}
						} catch (e) {}
					}

					return Sizzle(expr, document, null, [elem]).length > 0;
				};

				Sizzle.contains = function (context, elem) {
					if ((context.ownerDocument || context) !== document) {
						setDocument(context);
					}
					return contains(context, elem);
				};

				Sizzle.attr = function (elem, name) {
					if ((elem.ownerDocument || elem) !== document) {
						setDocument(elem);
					}

					var fn = Expr.attrHandle[name.toLowerCase()],

					val = fn && hasOwn.call(Expr.attrHandle, name.toLowerCase()) ? fn(elem, name, !documentIsHTML) : undefined;

					return val !== undefined ? val : support.attributes || !documentIsHTML ? elem.getAttribute(name) : (val = elem.getAttributeNode(name)) && val.specified ? val.value : null;
				};

				Sizzle.error = function (msg) {
					throw new Error("Syntax error, unrecognized expression: " + msg);
				};

				/**
     * Document sorting and removing duplicates
     * @param {ArrayLike} results
     */
				Sizzle.uniqueSort = function (results) {
					var elem,
					    duplicates = [],
					    j = 0,
					    i = 0;

					hasDuplicate = !support.detectDuplicates;
					sortInput = !support.sortStable && results.slice(0);
					results.sort(sortOrder);

					if (hasDuplicate) {
						while (elem = results[i++]) {
							if (elem === results[i]) {
								j = duplicates.push(i);
							}
						}
						while (j--) {
							results.splice(duplicates[j], 1);
						}
					}

					sortInput = null;

					return results;
				};

				/**
     * Utility function for retrieving the text value of an array of DOM nodes
     * @param {Array|Element} elem
     */
				getText = Sizzle.getText = function (elem) {
					var node,
					    ret = "",
					    i = 0,
					    nodeType = elem.nodeType;

					if (!nodeType) {
						while (node = elem[i++]) {
							ret += getText(node);
						}
					} else if (nodeType === 1 || nodeType === 9 || nodeType === 11) {
						if (typeof elem.textContent === "string") {
							return elem.textContent;
						} else {
							for (elem = elem.firstChild; elem; elem = elem.nextSibling) {
								ret += getText(elem);
							}
						}
					} else if (nodeType === 3 || nodeType === 4) {
						return elem.nodeValue;
					}

					return ret;
				};

				Expr = Sizzle.selectors = {

					cacheLength: 50,

					createPseudo: markFunction,

					match: matchExpr,

					attrHandle: {},

					find: {},

					relative: {
						">": { dir: "parentNode", first: true },
						" ": { dir: "parentNode" },
						"+": { dir: "previousSibling", first: true },
						"~": { dir: "previousSibling" }
					},

					preFilter: {
						"ATTR": function ATTR(match) {
							match[1] = match[1].replace(runescape, funescape);

							match[3] = (match[3] || match[4] || match[5] || "").replace(runescape, funescape);

							if (match[2] === "~=") {
								match[3] = " " + match[3] + " ";
							}

							return match.slice(0, 4);
						},

						"CHILD": function CHILD(match) {
							match[1] = match[1].toLowerCase();

							if (match[1].slice(0, 3) === "nth") {
								if (!match[3]) {
									Sizzle.error(match[0]);
								}

								match[4] = +(match[4] ? match[5] + (match[6] || 1) : 2 * (match[3] === "even" || match[3] === "odd"));
								match[5] = +(match[7] + match[8] || match[3] === "odd");

							} else if (match[3]) {
								Sizzle.error(match[0]);
							}

							return match;
						},

						"PSEUDO": function PSEUDO(match) {
							var excess,
							    unquoted = !match[6] && match[2];

							if (matchExpr["CHILD"].test(match[0])) {
								return null;
							}

							if (match[3]) {
								match[2] = match[4] || match[5] || "";

							} else if (unquoted && rpseudo.test(unquoted) && (
							excess = tokenize(unquoted, true)) && (
							excess = unquoted.indexOf(")", unquoted.length - excess) - unquoted.length)) {

								match[0] = match[0].slice(0, excess);
								match[2] = unquoted.slice(0, excess);
							}

							return match.slice(0, 3);
						}
					},

					filter: {

						"TAG": function TAG(nodeNameSelector) {
							var nodeName = nodeNameSelector.replace(runescape, funescape).toLowerCase();
							return nodeNameSelector === "*" ? function () {
								return true;
							} : function (elem) {
								return elem.nodeName && elem.nodeName.toLowerCase() === nodeName;
							};
						},

						"CLASS": function CLASS(className) {
							var pattern = classCache[className + " "];

							return pattern || (pattern = new RegExp("(^|" + whitespace + ")" + className + "(" + whitespace + "|$)")) && classCache(className, function (elem) {
								return pattern.test(typeof elem.className === "string" && elem.className || typeof elem.getAttribute !== "undefined" && elem.getAttribute("class") || "");
							});
						},

						"ATTR": function ATTR(name, operator, check) {
							return function (elem) {
								var result = Sizzle.attr(elem, name);

								if (result == null) {
									return operator === "!=";
								}
								if (!operator) {
									return true;
								}

								result += "";

								return operator === "=" ? result === check : operator === "!=" ? result !== check : operator === "^=" ? check && result.indexOf(check) === 0 : operator === "*=" ? check && result.indexOf(check) > -1 : operator === "$=" ? check && result.slice(-check.length) === check : operator === "~=" ? (" " + result.replace(rwhitespace, " ") + " ").indexOf(check) > -1 : operator === "|=" ? result === check || result.slice(0, check.length + 1) === check + "-" : false;
							};
						},

						"CHILD": function CHILD(type, what, argument, first, last) {
							var simple = type.slice(0, 3) !== "nth",
							    forward = type.slice(-4) !== "last",
							    ofType = what === "of-type";

							return first === 1 && last === 0 ?

							function (elem) {
								return !!elem.parentNode;
							} : function (elem, context, xml) {
								var cache,
								    uniqueCache,
								    outerCache,
								    node,
								    nodeIndex,
								    start,
								    dir = simple !== forward ? "nextSibling" : "previousSibling",
								    parent = elem.parentNode,
								    name = ofType && elem.nodeName.toLowerCase(),
								    useCache = !xml && !ofType,
								    diff = false;

								if (parent) {

									if (simple) {
										while (dir) {
											node = elem;
											while (node = node[dir]) {
												if (ofType ? node.nodeName.toLowerCase() === name : node.nodeType === 1) {

													return false;
												}
											}
											start = dir = type === "only" && !start && "nextSibling";
										}
										return true;
									}

									start = [forward ? parent.firstChild : parent.lastChild];

									if (forward && useCache) {


										node = parent;
										outerCache = node[expando] || (node[expando] = {});

										uniqueCache = outerCache[node.uniqueID] || (outerCache[node.uniqueID] = {});

										cache = uniqueCache[type] || [];
										nodeIndex = cache[0] === dirruns && cache[1];
										diff = nodeIndex && cache[2];
										node = nodeIndex && parent.childNodes[nodeIndex];

										while (node = ++nodeIndex && node && node[dir] || (

										diff = nodeIndex = 0) || start.pop()) {

											if (node.nodeType === 1 && ++diff && node === elem) {
												uniqueCache[type] = [dirruns, nodeIndex, diff];
												break;
											}
										}
									} else {
										if (useCache) {
											node = elem;
											outerCache = node[expando] || (node[expando] = {});

											uniqueCache = outerCache[node.uniqueID] || (outerCache[node.uniqueID] = {});

											cache = uniqueCache[type] || [];
											nodeIndex = cache[0] === dirruns && cache[1];
											diff = nodeIndex;
										}

										if (diff === false) {
											while (node = ++nodeIndex && node && node[dir] || (diff = nodeIndex = 0) || start.pop()) {

												if ((ofType ? node.nodeName.toLowerCase() === name : node.nodeType === 1) && ++diff) {

													if (useCache) {
														outerCache = node[expando] || (node[expando] = {});

														uniqueCache = outerCache[node.uniqueID] || (outerCache[node.uniqueID] = {});

														uniqueCache[type] = [dirruns, diff];
													}

													if (node === elem) {
														break;
													}
												}
											}
										}
									}

									diff -= last;
									return diff === first || diff % first === 0 && diff / first >= 0;
								}
							};
						},

						"PSEUDO": function PSEUDO(pseudo, argument) {
							var args,
							    fn = Expr.pseudos[pseudo] || Expr.setFilters[pseudo.toLowerCase()] || Sizzle.error("unsupported pseudo: " + pseudo);

							if (fn[expando]) {
								return fn(argument);
							}

							if (fn.length > 1) {
								args = [pseudo, pseudo, "", argument];
								return Expr.setFilters.hasOwnProperty(pseudo.toLowerCase()) ? markFunction(function (seed, matches) {
									var idx,
									    matched = fn(seed, argument),
									    i = matched.length;
									while (i--) {
										idx = indexOf(seed, matched[i]);
										seed[idx] = !(matches[idx] = matched[i]);
									}
								}) : function (elem) {
									return fn(elem, 0, args);
								};
							}

							return fn;
						}
					},

					pseudos: {
						"not": markFunction(function (selector) {
							var input = [],
							    results = [],
							    matcher = compile(selector.replace(rtrim, "$1"));

							return matcher[expando] ? markFunction(function (seed, matches, context, xml) {
								var elem,
								    unmatched = matcher(seed, null, xml, []),
								    i = seed.length;

								while (i--) {
									if (elem = unmatched[i]) {
										seed[i] = !(matches[i] = elem);
									}
								}
							}) : function (elem, context, xml) {
								input[0] = elem;
								matcher(input, null, xml, results);
								input[0] = null;
								return !results.pop();
							};
						}),

						"has": markFunction(function (selector) {
							return function (elem) {
								return Sizzle(selector, elem).length > 0;
							};
						}),

						"contains": markFunction(function (text) {
							text = text.replace(runescape, funescape);
							return function (elem) {
								return (elem.textContent || elem.innerText || getText(elem)).indexOf(text) > -1;
							};
						}),

						"lang": markFunction(function (lang) {
							if (!ridentifier.test(lang || "")) {
								Sizzle.error("unsupported lang: " + lang);
							}
							lang = lang.replace(runescape, funescape).toLowerCase();
							return function (elem) {
								var elemLang;
								do {
									if (elemLang = documentIsHTML ? elem.lang : elem.getAttribute("xml:lang") || elem.getAttribute("lang")) {

										elemLang = elemLang.toLowerCase();
										return elemLang === lang || elemLang.indexOf(lang + "-") === 0;
									}
								} while ((elem = elem.parentNode) && elem.nodeType === 1);
								return false;
							};
						}),

						"target": function target(elem) {
							var hash = window.location && window.location.hash;
							return hash && hash.slice(1) === elem.id;
						},

						"root": function root(elem) {
							return elem === docElem;
						},

						"focus": function focus(elem) {
							return elem === document.activeElement && (!document.hasFocus || document.hasFocus()) && !!(elem.type || elem.href || ~elem.tabIndex);
						},

						"enabled": function enabled(elem) {
							return elem.disabled === false;
						},

						"disabled": function disabled(elem) {
							return elem.disabled === true;
						},

						"checked": function checked(elem) {
							var nodeName = elem.nodeName.toLowerCase();
							return nodeName === "input" && !!elem.checked || nodeName === "option" && !!elem.selected;
						},

						"selected": function selected(elem) {
							if (elem.parentNode) {
								elem.parentNode.selectedIndex;
							}

							return elem.selected === true;
						},

						"empty": function empty(elem) {
							for (elem = elem.firstChild; elem; elem = elem.nextSibling) {
								if (elem.nodeType < 6) {
									return false;
								}
							}
							return true;
						},

						"parent": function parent(elem) {
							return !Expr.pseudos["empty"](elem);
						},

						"header": function header(elem) {
							return rheader.test(elem.nodeName);
						},

						"input": function input(elem) {
							return rinputs.test(elem.nodeName);
						},

						"button": function button(elem) {
							var name = elem.nodeName.toLowerCase();
							return name === "input" && elem.type === "button" || name === "button";
						},

						"text": function text(elem) {
							var attr;
							return elem.nodeName.toLowerCase() === "input" && elem.type === "text" && (

							(attr = elem.getAttribute("type")) == null || attr.toLowerCase() === "text");
						},

						"first": createPositionalPseudo(function () {
							return [0];
						}),

						"last": createPositionalPseudo(function (matchIndexes, length) {
							return [length - 1];
						}),

						"eq": createPositionalPseudo(function (matchIndexes, length, argument) {
							return [argument < 0 ? argument + length : argument];
						}),

						"even": createPositionalPseudo(function (matchIndexes, length) {
							var i = 0;
							for (; i < length; i += 2) {
								matchIndexes.push(i);
							}
							return matchIndexes;
						}),

						"odd": createPositionalPseudo(function (matchIndexes, length) {
							var i = 1;
							for (; i < length; i += 2) {
								matchIndexes.push(i);
							}
							return matchIndexes;
						}),

						"lt": createPositionalPseudo(function (matchIndexes, length, argument) {
							var i = argument < 0 ? argument + length : argument;
							for (; --i >= 0;) {
								matchIndexes.push(i);
							}
							return matchIndexes;
						}),

						"gt": createPositionalPseudo(function (matchIndexes, length, argument) {
							var i = argument < 0 ? argument + length : argument;
							for (; ++i < length;) {
								matchIndexes.push(i);
							}
							return matchIndexes;
						})
					}
				};

				Expr.pseudos["nth"] = Expr.pseudos["eq"];

				for (i in { radio: true, checkbox: true, file: true, password: true, image: true }) {
					Expr.pseudos[i] = createInputPseudo(i);
				}
				for (i in { submit: true, reset: true }) {
					Expr.pseudos[i] = createButtonPseudo(i);
				}

				function setFilters() {}
				setFilters.prototype = Expr.filters = Expr.pseudos;
				Expr.setFilters = new setFilters();

				tokenize = Sizzle.tokenize = function (selector, parseOnly) {
					var matched,
					    match,
					    tokens,
					    type,
					    soFar,
					    groups,
					    preFilters,
					    cached = tokenCache[selector + " "];

					if (cached) {
						return parseOnly ? 0 : cached.slice(0);
					}

					soFar = selector;
					groups = [];
					preFilters = Expr.preFilter;

					while (soFar) {

						if (!matched || (match = rcomma.exec(soFar))) {
							if (match) {
								soFar = soFar.slice(match[0].length) || soFar;
							}
							groups.push(tokens = []);
						}

						matched = false;

						if (match = rcombinators.exec(soFar)) {
							matched = match.shift();
							tokens.push({
								value: matched,
								type: match[0].replace(rtrim, " ")
							});
							soFar = soFar.slice(matched.length);
						}

						for (type in Expr.filter) {
							if ((match = matchExpr[type].exec(soFar)) && (!preFilters[type] || (match = preFilters[type](match)))) {
								matched = match.shift();
								tokens.push({
									value: matched,
									type: type,
									matches: match
								});
								soFar = soFar.slice(matched.length);
							}
						}

						if (!matched) {
							break;
						}
					}

					return parseOnly ? soFar.length : soFar ? Sizzle.error(selector) :
					tokenCache(selector, groups).slice(0);
				};

				function toSelector(tokens) {
					var i = 0,
					    len = tokens.length,
					    selector = "";
					for (; i < len; i++) {
						selector += tokens[i].value;
					}
					return selector;
				}

				function addCombinator(matcher, combinator, base) {
					var dir = combinator.dir,
					    checkNonElements = base && dir === "parentNode",
					    doneName = done++;

					return combinator.first ?
					function (elem, context, xml) {
						while (elem = elem[dir]) {
							if (elem.nodeType === 1 || checkNonElements) {
								return matcher(elem, context, xml);
							}
						}
					} :

					function (elem, context, xml) {
						var oldCache,
						    uniqueCache,
						    outerCache,
						    newCache = [dirruns, doneName];

						if (xml) {
							while (elem = elem[dir]) {
								if (elem.nodeType === 1 || checkNonElements) {
									if (matcher(elem, context, xml)) {
										return true;
									}
								}
							}
						} else {
							while (elem = elem[dir]) {
								if (elem.nodeType === 1 || checkNonElements) {
									outerCache = elem[expando] || (elem[expando] = {});

									uniqueCache = outerCache[elem.uniqueID] || (outerCache[elem.uniqueID] = {});

									if ((oldCache = uniqueCache[dir]) && oldCache[0] === dirruns && oldCache[1] === doneName) {

										return newCache[2] = oldCache[2];
									} else {
										uniqueCache[dir] = newCache;

										if (newCache[2] = matcher(elem, context, xml)) {
											return true;
										}
									}
								}
							}
						}
					};
				}

				function elementMatcher(matchers) {
					return matchers.length > 1 ? function (elem, context, xml) {
						var i = matchers.length;
						while (i--) {
							if (!matchers[i](elem, context, xml)) {
								return false;
							}
						}
						return true;
					} : matchers[0];
				}

				function multipleContexts(selector, contexts, results) {
					var i = 0,
					    len = contexts.length;
					for (; i < len; i++) {
						Sizzle(selector, contexts[i], results);
					}
					return results;
				}

				function condense(unmatched, map, filter, context, xml) {
					var elem,
					    newUnmatched = [],
					    i = 0,
					    len = unmatched.length,
					    mapped = map != null;

					for (; i < len; i++) {
						if (elem = unmatched[i]) {
							if (!filter || filter(elem, context, xml)) {
								newUnmatched.push(elem);
								if (mapped) {
									map.push(i);
								}
							}
						}
					}

					return newUnmatched;
				}

				function setMatcher(preFilter, selector, matcher, postFilter, postFinder, postSelector) {
					if (postFilter && !postFilter[expando]) {
						postFilter = setMatcher(postFilter);
					}
					if (postFinder && !postFinder[expando]) {
						postFinder = setMatcher(postFinder, postSelector);
					}
					return markFunction(function (seed, results, context, xml) {
						var temp,
						    i,
						    elem,
						    preMap = [],
						    postMap = [],
						    preexisting = results.length,


						elems = seed || multipleContexts(selector || "*", context.nodeType ? [context] : context, []),


						matcherIn = preFilter && (seed || !selector) ? condense(elems, preMap, preFilter, context, xml) : elems,
						    matcherOut = matcher ?
						postFinder || (seed ? preFilter : preexisting || postFilter) ?

						[] :

						results : matcherIn;

						if (matcher) {
							matcher(matcherIn, matcherOut, context, xml);
						}

						if (postFilter) {
							temp = condense(matcherOut, postMap);
							postFilter(temp, [], context, xml);

							i = temp.length;
							while (i--) {
								if (elem = temp[i]) {
									matcherOut[postMap[i]] = !(matcherIn[postMap[i]] = elem);
								}
							}
						}

						if (seed) {
							if (postFinder || preFilter) {
								if (postFinder) {
									temp = [];
									i = matcherOut.length;
									while (i--) {
										if (elem = matcherOut[i]) {
											temp.push(matcherIn[i] = elem);
										}
									}
									postFinder(null, matcherOut = [], temp, xml);
								}

								i = matcherOut.length;
								while (i--) {
									if ((elem = matcherOut[i]) && (temp = postFinder ? indexOf(seed, elem) : preMap[i]) > -1) {

										seed[temp] = !(results[temp] = elem);
									}
								}
							}

						} else {
							matcherOut = condense(matcherOut === results ? matcherOut.splice(preexisting, matcherOut.length) : matcherOut);
							if (postFinder) {
								postFinder(null, results, matcherOut, xml);
							} else {
								push.apply(results, matcherOut);
							}
						}
					});
				}

				function matcherFromTokens(tokens) {
					var checkContext,
					    matcher,
					    j,
					    len = tokens.length,
					    leadingRelative = Expr.relative[tokens[0].type],
					    implicitRelative = leadingRelative || Expr.relative[" "],
					    i = leadingRelative ? 1 : 0,


					matchContext = addCombinator(function (elem) {
						return elem === checkContext;
					}, implicitRelative, true),
					    matchAnyContext = addCombinator(function (elem) {
						return indexOf(checkContext, elem) > -1;
					}, implicitRelative, true),
					    matchers = [function (elem, context, xml) {
						var ret = !leadingRelative && (xml || context !== outermostContext) || ((checkContext = context).nodeType ? matchContext(elem, context, xml) : matchAnyContext(elem, context, xml));
						checkContext = null;
						return ret;
					}];

					for (; i < len; i++) {
						if (matcher = Expr.relative[tokens[i].type]) {
							matchers = [addCombinator(elementMatcher(matchers), matcher)];
						} else {
							matcher = Expr.filter[tokens[i].type].apply(null, tokens[i].matches);

							if (matcher[expando]) {
								j = ++i;
								for (; j < len; j++) {
									if (Expr.relative[tokens[j].type]) {
										break;
									}
								}
								return setMatcher(i > 1 && elementMatcher(matchers), i > 1 && toSelector(
								tokens.slice(0, i - 1).concat({ value: tokens[i - 2].type === " " ? "*" : "" })).replace(rtrim, "$1"), matcher, i < j && matcherFromTokens(tokens.slice(i, j)), j < len && matcherFromTokens(tokens = tokens.slice(j)), j < len && toSelector(tokens));
							}
							matchers.push(matcher);
						}
					}

					return elementMatcher(matchers);
				}

				function matcherFromGroupMatchers(elementMatchers, setMatchers) {
					var bySet = setMatchers.length > 0,
					    byElement = elementMatchers.length > 0,
					    superMatcher = function superMatcher(seed, context, xml, results, outermost) {
						var elem,
						    j,
						    matcher,
						    matchedCount = 0,
						    i = "0",
						    unmatched = seed && [],
						    setMatched = [],
						    contextBackup = outermostContext,

						elems = seed || byElement && Expr.find["TAG"]("*", outermost),

						dirrunsUnique = dirruns += contextBackup == null ? 1 : Math.random() || 0.1,
						    len = elems.length;

						if (outermost) {
							outermostContext = context === document || context || outermost;
						}

						for (; i !== len && (elem = elems[i]) != null; i++) {
							if (byElement && elem) {
								j = 0;
								if (!context && elem.ownerDocument !== document) {
									setDocument(elem);
									xml = !documentIsHTML;
								}
								while (matcher = elementMatchers[j++]) {
									if (matcher(elem, context || document, xml)) {
										results.push(elem);
										break;
									}
								}
								if (outermost) {
									dirruns = dirrunsUnique;
								}
							}

							if (bySet) {
								if (elem = !matcher && elem) {
									matchedCount--;
								}

								if (seed) {
									unmatched.push(elem);
								}
							}
						}

						matchedCount += i;

						if (bySet && i !== matchedCount) {
							j = 0;
							while (matcher = setMatchers[j++]) {
								matcher(unmatched, setMatched, context, xml);
							}

							if (seed) {
								if (matchedCount > 0) {
									while (i--) {
										if (!(unmatched[i] || setMatched[i])) {
											setMatched[i] = pop.call(results);
										}
									}
								}

								setMatched = condense(setMatched);
							}

							push.apply(results, setMatched);

							if (outermost && !seed && setMatched.length > 0 && matchedCount + setMatchers.length > 1) {

								Sizzle.uniqueSort(results);
							}
						}

						if (outermost) {
							dirruns = dirrunsUnique;
							outermostContext = contextBackup;
						}

						return unmatched;
					};

					return bySet ? markFunction(superMatcher) : superMatcher;
				}

				compile = Sizzle.compile = function (selector, match ) {
					var i,
					    setMatchers = [],
					    elementMatchers = [],
					    cached = compilerCache[selector + " "];

					if (!cached) {
						if (!match) {
							match = tokenize(selector);
						}
						i = match.length;
						while (i--) {
							cached = matcherFromTokens(match[i]);
							if (cached[expando]) {
								setMatchers.push(cached);
							} else {
								elementMatchers.push(cached);
							}
						}

						cached = compilerCache(selector, matcherFromGroupMatchers(elementMatchers, setMatchers));

						cached.selector = selector;
					}
					return cached;
				};

				/**
     * A low-level selection function that works with Sizzle's compiled
     *  selector functions
     * @param {String|Function} selector A selector or a pre-compiled
     *  selector function built with Sizzle.compile
     * @param {Element} context
     * @param {Array} [results]
     * @param {Array} [seed] A set of elements to match against
     */
				select = Sizzle.select = function (selector, context, results, seed) {
					var i,
					    tokens,
					    token,
					    type,
					    find,
					    compiled = typeof selector === "function" && selector,
					    match = !seed && tokenize(selector = compiled.selector || selector);

					results = results || [];

					if (match.length === 1) {

						tokens = match[0] = match[0].slice(0);
						if (tokens.length > 2 && (token = tokens[0]).type === "ID" && support.getById && context.nodeType === 9 && documentIsHTML && Expr.relative[tokens[1].type]) {

							context = (Expr.find["ID"](token.matches[0].replace(runescape, funescape), context) || [])[0];
							if (!context) {
								return results;

							} else if (compiled) {
								context = context.parentNode;
							}

							selector = selector.slice(tokens.shift().value.length);
						}

						i = matchExpr["needsContext"].test(selector) ? 0 : tokens.length;
						while (i--) {
							token = tokens[i];

							if (Expr.relative[type = token.type]) {
								break;
							}
							if (find = Expr.find[type]) {
								if (seed = find(token.matches[0].replace(runescape, funescape), rsibling.test(tokens[0].type) && testContext(context.parentNode) || context)) {

									tokens.splice(i, 1);
									selector = seed.length && toSelector(tokens);
									if (!selector) {
										push.apply(results, seed);
										return results;
									}

									break;
								}
							}
						}
					}

					(compiled || compile(selector, match))(seed, context, !documentIsHTML, results, !context || rsibling.test(selector) && testContext(context.parentNode) || context);
					return results;
				};


				support.sortStable = expando.split("").sort(sortOrder).join("") === expando;

				support.detectDuplicates = !!hasDuplicate;

				setDocument();

				support.sortDetached = assert(function (div1) {
					return div1.compareDocumentPosition(document.createElement("div")) & 1;
				});

				if (!assert(function (div) {
					div.innerHTML = "<a href='#'></a>";
					return div.firstChild.getAttribute("href") === "#";
				})) {
					addHandle("type|href|height|width", function (elem, name, isXML) {
						if (!isXML) {
							return elem.getAttribute(name, name.toLowerCase() === "type" ? 1 : 2);
						}
					});
				}

				if (!support.attributes || !assert(function (div) {
					div.innerHTML = "<input/>";
					div.firstChild.setAttribute("value", "");
					return div.firstChild.getAttribute("value") === "";
				})) {
					addHandle("value", function (elem, name, isXML) {
						if (!isXML && elem.nodeName.toLowerCase() === "input") {
							return elem.defaultValue;
						}
					});
				}

				if (!assert(function (div) {
					return div.getAttribute("disabled") == null;
				})) {
					addHandle(booleans, function (elem, name, isXML) {
						var val;
						if (!isXML) {
							return elem[name] === true ? name.toLowerCase() : (val = elem.getAttributeNode(name)) && val.specified ? val.value : null;
						}
					});
				}

				return Sizzle;
			}(window);

			jQuery.find = Sizzle;
			jQuery.expr = Sizzle.selectors;
			jQuery.expr[":"] = jQuery.expr.pseudos;
			jQuery.uniqueSort = jQuery.unique = Sizzle.uniqueSort;
			jQuery.text = Sizzle.getText;
			jQuery.isXMLDoc = Sizzle.isXML;
			jQuery.contains = Sizzle.contains;

			var dir = function dir(elem, _dir, until) {
				var matched = [],
				    truncate = until !== undefined;

				while ((elem = elem[_dir]) && elem.nodeType !== 9) {
					if (elem.nodeType === 1) {
						if (truncate && jQuery(elem).is(until)) {
							break;
						}
						matched.push(elem);
					}
				}
				return matched;
			};

			var _siblings = function _siblings(n, elem) {
				var matched = [];

				for (; n; n = n.nextSibling) {
					if (n.nodeType === 1 && n !== elem) {
						matched.push(n);
					}
				}

				return matched;
			};

			var rneedsContext = jQuery.expr.match.needsContext;

			var rsingleTag = /^<([\w-]+)\s*\/?>(?:<\/\1>|)$/;

			var risSimple = /^.[^:#\[\.,]*$/;

			function winnow(elements, qualifier, not) {
				if (jQuery.isFunction(qualifier)) {
					return jQuery.grep(elements, function (elem, i) {
						return !!qualifier.call(elem, i, elem) !== not;
					});
				}

				if (qualifier.nodeType) {
					return jQuery.grep(elements, function (elem) {
						return elem === qualifier !== not;
					});
				}

				if (typeof qualifier === "string") {
					if (risSimple.test(qualifier)) {
						return jQuery.filter(qualifier, elements, not);
					}

					qualifier = jQuery.filter(qualifier, elements);
				}

				return jQuery.grep(elements, function (elem) {
					return jQuery.inArray(elem, qualifier) > -1 !== not;
				});
			}

			jQuery.filter = function (expr, elems, not) {
				var elem = elems[0];

				if (not) {
					expr = ":not(" + expr + ")";
				}

				return elems.length === 1 && elem.nodeType === 1 ? jQuery.find.matchesSelector(elem, expr) ? [elem] : [] : jQuery.find.matches(expr, jQuery.grep(elems, function (elem) {
					return elem.nodeType === 1;
				}));
			};

			jQuery.fn.extend({
				find: function find(selector) {
					var i,
					    ret = [],
					    self = this,
					    len = self.length;

					if (typeof selector !== "string") {
						return this.pushStack(jQuery(selector).filter(function () {
							for (i = 0; i < len; i++) {
								if (jQuery.contains(self[i], this)) {
									return true;
								}
							}
						}));
					}

					for (i = 0; i < len; i++) {
						jQuery.find(selector, self[i], ret);
					}

					ret = this.pushStack(len > 1 ? jQuery.unique(ret) : ret);
					ret.selector = this.selector ? this.selector + " " + selector : selector;
					return ret;
				},
				filter: function filter(selector) {
					return this.pushStack(winnow(this, selector || [], false));
				},
				not: function not(selector) {
					return this.pushStack(winnow(this, selector || [], true));
				},
				is: function is(selector) {
					return !!winnow(this,

					typeof selector === "string" && rneedsContext.test(selector) ? jQuery(selector) : selector || [], false).length;
				}
			});



			var rootjQuery,


			rquickExpr = /^(?:\s*(<[\w\W]+>)[^>]*|#([\w-]*))$/,
			    init = jQuery.fn.init = function (selector, context, root) {
				var match, elem;

				if (!selector) {
					return this;
				}

				root = root || rootjQuery;

				if (typeof selector === "string") {
					if (selector.charAt(0) === "<" && selector.charAt(selector.length - 1) === ">" && selector.length >= 3) {

						match = [null, selector, null];
					} else {
						match = rquickExpr.exec(selector);
					}

					if (match && (match[1] || !context)) {

						if (match[1]) {
							context = context instanceof jQuery ? context[0] : context;

							jQuery.merge(this, jQuery.parseHTML(match[1], context && context.nodeType ? context.ownerDocument || context : document, true));

							if (rsingleTag.test(match[1]) && jQuery.isPlainObject(context)) {
								for (match in context) {

									if (jQuery.isFunction(this[match])) {
										this[match](context[match]);

									} else {
										this.attr(match, context[match]);
									}
								}
							}

							return this;

						} else {
							elem = document.getElementById(match[2]);

							if (elem && elem.parentNode) {

								if (elem.id !== match[2]) {
									return rootjQuery.find(selector);
								}

								this.length = 1;
								this[0] = elem;
							}

							this.context = document;
							this.selector = selector;
							return this;
						}

					} else if (!context || context.jquery) {
						return (context || root).find(selector);

					} else {
						return this.constructor(context).find(selector);
					}

				} else if (selector.nodeType) {
					this.context = this[0] = selector;
					this.length = 1;
					return this;

				} else if (jQuery.isFunction(selector)) {
					return typeof root.ready !== "undefined" ? root.ready(selector) :

					selector(jQuery);
				}

				if (selector.selector !== undefined) {
					this.selector = selector.selector;
					this.context = selector.context;
				}

				return jQuery.makeArray(selector, this);
			};

			init.prototype = jQuery.fn;

			rootjQuery = jQuery(document);

			var rparentsprev = /^(?:parents|prev(?:Until|All))/,


			guaranteedUnique = {
				children: true,
				contents: true,
				next: true,
				prev: true
			};

			jQuery.fn.extend({
				has: function has(target) {
					var i,
					    targets = jQuery(target, this),
					    len = targets.length;

					return this.filter(function () {
						for (i = 0; i < len; i++) {
							if (jQuery.contains(this, targets[i])) {
								return true;
							}
						}
					});
				},

				closest: function closest(selectors, context) {
					var cur,
					    i = 0,
					    l = this.length,
					    matched = [],
					    pos = rneedsContext.test(selectors) || typeof selectors !== "string" ? jQuery(selectors, context || this.context) : 0;

					for (; i < l; i++) {
						for (cur = this[i]; cur && cur !== context; cur = cur.parentNode) {

							if (cur.nodeType < 11 && (pos ? pos.index(cur) > -1 :

							cur.nodeType === 1 && jQuery.find.matchesSelector(cur, selectors))) {

								matched.push(cur);
								break;
							}
						}
					}

					return this.pushStack(matched.length > 1 ? jQuery.uniqueSort(matched) : matched);
				},

				index: function index(elem) {

					if (!elem) {
						return this[0] && this[0].parentNode ? this.first().prevAll().length : -1;
					}

					if (typeof elem === "string") {
						return jQuery.inArray(this[0], jQuery(elem));
					}

					return jQuery.inArray(

					elem.jquery ? elem[0] : elem, this);
				},

				add: function add(selector, context) {
					return this.pushStack(jQuery.uniqueSort(jQuery.merge(this.get(), jQuery(selector, context))));
				},

				addBack: function addBack(selector) {
					return this.add(selector == null ? this.prevObject : this.prevObject.filter(selector));
				}
			});

			function sibling(cur, dir) {
				do {
					cur = cur[dir];
				} while (cur && cur.nodeType !== 1);

				return cur;
			}

			jQuery.each({
				parent: function parent(elem) {
					var parent = elem.parentNode;
					return parent && parent.nodeType !== 11 ? parent : null;
				},
				parents: function parents(elem) {
					return dir(elem, "parentNode");
				},
				parentsUntil: function parentsUntil(elem, i, until) {
					return dir(elem, "parentNode", until);
				},
				next: function next(elem) {
					return sibling(elem, "nextSibling");
				},
				prev: function prev(elem) {
					return sibling(elem, "previousSibling");
				},
				nextAll: function nextAll(elem) {
					return dir(elem, "nextSibling");
				},
				prevAll: function prevAll(elem) {
					return dir(elem, "previousSibling");
				},
				nextUntil: function nextUntil(elem, i, until) {
					return dir(elem, "nextSibling", until);
				},
				prevUntil: function prevUntil(elem, i, until) {
					return dir(elem, "previousSibling", until);
				},
				siblings: function siblings(elem) {
					return _siblings((elem.parentNode || {}).firstChild, elem);
				},
				children: function children(elem) {
					return _siblings(elem.firstChild);
				},
				contents: function contents(elem) {
					return jQuery.nodeName(elem, "iframe") ? elem.contentDocument || elem.contentWindow.document : jQuery.merge([], elem.childNodes);
				}
			}, function (name, fn) {
				jQuery.fn[name] = function (until, selector) {
					var ret = jQuery.map(this, fn, until);

					if (name.slice(-5) !== "Until") {
						selector = until;
					}

					if (selector && typeof selector === "string") {
						ret = jQuery.filter(selector, ret);
					}

					if (this.length > 1) {

						if (!guaranteedUnique[name]) {
							ret = jQuery.uniqueSort(ret);
						}

						if (rparentsprev.test(name)) {
							ret = ret.reverse();
						}
					}

					return this.pushStack(ret);
				};
			});
			var rnotwhite = /\S+/g;

			function createOptions(options) {
				var object = {};
				jQuery.each(options.match(rnotwhite) || [], function (_, flag) {
					object[flag] = true;
				});
				return object;
			}

			jQuery.Callbacks = function (options) {

				options = typeof options === "string" ? createOptions(options) : jQuery.extend({}, options);

				var 
				firing,


				memory,


				_fired,


				_locked,


				list = [],


				queue = [],


				firingIndex = -1,


				fire = function fire() {

					_locked = options.once;

					_fired = firing = true;
					for (; queue.length; firingIndex = -1) {
						memory = queue.shift();
						while (++firingIndex < list.length) {

							if (list[firingIndex].apply(memory[0], memory[1]) === false && options.stopOnFalse) {

								firingIndex = list.length;
								memory = false;
							}
						}
					}

					if (!options.memory) {
						memory = false;
					}

					firing = false;

					if (_locked) {

						if (memory) {
							list = [];

						} else {
							list = "";
						}
					}
				},


				self = {

					add: function add() {
						if (list) {

							if (memory && !firing) {
								firingIndex = list.length - 1;
								queue.push(memory);
							}

							(function add(args) {
								jQuery.each(args, function (_, arg) {
									if (jQuery.isFunction(arg)) {
										if (!options.unique || !self.has(arg)) {
											list.push(arg);
										}
									} else if (arg && arg.length && jQuery.type(arg) !== "string") {

										add(arg);
									}
								});
							})(arguments);

							if (memory && !firing) {
								fire();
							}
						}
						return this;
					},

					remove: function remove() {
						jQuery.each(arguments, function (_, arg) {
							var index;
							while ((index = jQuery.inArray(arg, list, index)) > -1) {
								list.splice(index, 1);

								if (index <= firingIndex) {
									firingIndex--;
								}
							}
						});
						return this;
					},

					has: function has(fn) {
						return fn ? jQuery.inArray(fn, list) > -1 : list.length > 0;
					},

					empty: function empty() {
						if (list) {
							list = [];
						}
						return this;
					},

					disable: function disable() {
						_locked = queue = [];
						list = memory = "";
						return this;
					},
					disabled: function disabled() {
						return !list;
					},

					lock: function lock() {
						_locked = true;
						if (!memory) {
							self.disable();
						}
						return this;
					},
					locked: function locked() {
						return !!_locked;
					},

					fireWith: function fireWith(context, args) {
						if (!_locked) {
							args = args || [];
							args = [context, args.slice ? args.slice() : args];
							queue.push(args);
							if (!firing) {
								fire();
							}
						}
						return this;
					},

					fire: function fire() {
						self.fireWith(this, arguments);
						return this;
					},

					fired: function fired() {
						return !!_fired;
					}
				};

				return self;
			};

			jQuery.extend({

				Deferred: function Deferred(func) {
					var tuples = [

					["resolve", "done", jQuery.Callbacks("once memory"), "resolved"], ["reject", "fail", jQuery.Callbacks("once memory"), "rejected"], ["notify", "progress", jQuery.Callbacks("memory")]],
					    _state = "pending",
					    _promise = {
						state: function state() {
							return _state;
						},
						always: function always() {
							deferred.done(arguments).fail(arguments);
							return this;
						},
						then: function then() {
							var fns = arguments;
							return jQuery.Deferred(function (newDefer) {
								jQuery.each(tuples, function (i, tuple) {
									var fn = jQuery.isFunction(fns[i]) && fns[i];

									deferred[tuple[1]](function () {
										var returned = fn && fn.apply(this, arguments);
										if (returned && jQuery.isFunction(returned.promise)) {
											returned.promise().progress(newDefer.notify).done(newDefer.resolve).fail(newDefer.reject);
										} else {
											newDefer[tuple[0] + "With"](this === _promise ? newDefer.promise() : this, fn ? [returned] : arguments);
										}
									});
								});
								fns = null;
							}).promise();
						},

						promise: function promise(obj) {
							return obj != null ? jQuery.extend(obj, _promise) : _promise;
						}
					},
					    deferred = {};

					_promise.pipe = _promise.then;

					jQuery.each(tuples, function (i, tuple) {
						var list = tuple[2],
						    stateString = tuple[3];

						_promise[tuple[1]] = list.add;

						if (stateString) {
							list.add(function () {

								_state = stateString;

							}, tuples[i ^ 1][2].disable, tuples[2][2].lock);
						}

						deferred[tuple[0]] = function () {
							deferred[tuple[0] + "With"](this === deferred ? _promise : this, arguments);
							return this;
						};
						deferred[tuple[0] + "With"] = list.fireWith;
					});

					_promise.promise(deferred);

					if (func) {
						func.call(deferred, deferred);
					}

					return deferred;
				},

				when: function when(subordinate ) {
					var i = 0,
					    resolveValues = _slice.call(arguments),
					    length = resolveValues.length,


					remaining = length !== 1 || subordinate && jQuery.isFunction(subordinate.promise) ? length : 0,


					deferred = remaining === 1 ? subordinate : jQuery.Deferred(),


					updateFunc = function updateFunc(i, contexts, values) {
						return function (value) {
							contexts[i] = this;
							values[i] = arguments.length > 1 ? _slice.call(arguments) : value;
							if (values === progressValues) {
								deferred.notifyWith(contexts, values);
							} else if (! --remaining) {
								deferred.resolveWith(contexts, values);
							}
						};
					},
					    progressValues,
					    progressContexts,
					    resolveContexts;

					if (length > 1) {
						progressValues = new Array(length);
						progressContexts = new Array(length);
						resolveContexts = new Array(length);
						for (; i < length; i++) {
							if (resolveValues[i] && jQuery.isFunction(resolveValues[i].promise)) {
								resolveValues[i].promise().progress(updateFunc(i, progressContexts, progressValues)).done(updateFunc(i, resolveContexts, resolveValues)).fail(deferred.reject);
							} else {
								--remaining;
							}
						}
					}

					if (!remaining) {
						deferred.resolveWith(resolveContexts, resolveValues);
					}

					return deferred.promise();
				}
			});

			var readyList;

			jQuery.fn.ready = function (fn) {

				jQuery.ready.promise().done(fn);

				return this;
			};

			jQuery.extend({

				isReady: false,

				readyWait: 1,

				holdReady: function holdReady(hold) {
					if (hold) {
						jQuery.readyWait++;
					} else {
						jQuery.ready(true);
					}
				},

				ready: function ready(wait) {

					if (wait === true ? --jQuery.readyWait : jQuery.isReady) {
						return;
					}

					jQuery.isReady = true;

					if (wait !== true && --jQuery.readyWait > 0) {
						return;
					}

					readyList.resolveWith(document, [jQuery]);

					if (jQuery.fn.triggerHandler) {
						jQuery(document).triggerHandler("ready");
						jQuery(document).off("ready");
					}
				}
			});

			/**
    * Clean-up method for dom ready events
    */
			function detach() {
				if (document.addEventListener) {
					document.removeEventListener("DOMContentLoaded", completed);
					window.removeEventListener("load", completed);
				} else {
					document.detachEvent("onreadystatechange", completed);
					window.detachEvent("onload", completed);
				}
			}

			/**
    * The ready event handler and self cleanup method
    */
			function completed() {

				if (document.addEventListener || window.event.type === "load" || document.readyState === "complete") {

					detach();
					jQuery.ready();
				}
			}

			jQuery.ready.promise = function (obj) {
				if (!readyList) {

					readyList = jQuery.Deferred();

					if (document.readyState === "complete" || document.readyState !== "loading" && !document.documentElement.doScroll) {

						window.setTimeout(jQuery.ready);

					} else if (document.addEventListener) {

						document.addEventListener("DOMContentLoaded", completed);

						window.addEventListener("load", completed);

					} else {

						document.attachEvent("onreadystatechange", completed);

						window.attachEvent("onload", completed);

						var top = false;

						try {
							top = window.frameElement == null && document.documentElement;
						} catch (e) {}

						if (top && top.doScroll) {
							(function doScrollCheck() {
								if (!jQuery.isReady) {

									try {

										top.doScroll("left");
									} catch (e) {
										return window.setTimeout(doScrollCheck, 50);
									}

									detach();

									jQuery.ready();
								}
							})();
						}
					}
				}
				return readyList.promise(obj);
			};

			jQuery.ready.promise();

			var i;
			for (i in jQuery(support)) {
				break;
			}
			support.ownFirst = i === "0";

			support.inlineBlockNeedsLayout = false;

			jQuery(function () {

				var val, div, body, container;

				body = document.getElementsByTagName("body")[0];
				if (!body || !body.style) {

					return;
				}

				div = document.createElement("div");
				container = document.createElement("div");
				container.style.cssText = "position:absolute;border:0;width:0;height:0;top:0;left:-9999px";
				body.appendChild(container).appendChild(div);

				if (typeof div.style.zoom !== "undefined") {

					div.style.cssText = "display:inline;margin:0;border:0;padding:1px;width:1px;zoom:1";

					support.inlineBlockNeedsLayout = val = div.offsetWidth === 3;
					if (val) {

						body.style.zoom = 1;
					}
				}

				body.removeChild(container);
			});

			(function () {
				var div = document.createElement("div");

				support.deleteExpando = true;
				try {
					delete div.test;
				} catch (e) {
					support.deleteExpando = false;
				}

				div = null;
			})();
			var acceptData = function acceptData(elem) {
				var noData = jQuery.noData[(elem.nodeName + " ").toLowerCase()],
				    nodeType = +elem.nodeType || 1;

				return nodeType !== 1 && nodeType !== 9 ? false :

				!noData || noData !== true && elem.getAttribute("classid") === noData;
			};

			var rbrace = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/,
			    rmultiDash = /([A-Z])/g;

			function dataAttr(elem, key, data) {

				if (data === undefined && elem.nodeType === 1) {

					var name = "data-" + key.replace(rmultiDash, "-$1").toLowerCase();

					data = elem.getAttribute(name);

					if (typeof data === "string") {
						try {
							data = data === "true" ? true : data === "false" ? false : data === "null" ? null :

							+data + "" === data ? +data : rbrace.test(data) ? jQuery.parseJSON(data) : data;
						} catch (e) {}

						jQuery.data(elem, key, data);
					} else {
						data = undefined;
					}
				}

				return data;
			}

			function isEmptyDataObject(obj) {
				var name;
				for (name in obj) {

					if (name === "data" && jQuery.isEmptyObject(obj[name])) {
						continue;
					}
					if (name !== "toJSON") {
						return false;
					}
				}

				return true;
			}

			function internalData(elem, name, data, pvt ) {
				if (!acceptData(elem)) {
					return;
				}

				var ret,
				    thisCache,
				    internalKey = jQuery.expando,


				isNode = elem.nodeType,


				cache = isNode ? jQuery.cache : elem,


				id = isNode ? elem[internalKey] : elem[internalKey] && internalKey;

				if ((!id || !cache[id] || !pvt && !cache[id].data) && data === undefined && typeof name === "string") {
					return;
				}

				if (!id) {

					if (isNode) {
						id = elem[internalKey] = deletedIds.pop() || jQuery.guid++;
					} else {
						id = internalKey;
					}
				}

				if (!cache[id]) {

					cache[id] = isNode ? {} : { toJSON: jQuery.noop };
				}

				if ((typeof name === "undefined" ? "undefined" : _typeof(name)) === "object" || typeof name === "function") {
					if (pvt) {
						cache[id] = jQuery.extend(cache[id], name);
					} else {
						cache[id].data = jQuery.extend(cache[id].data, name);
					}
				}

				thisCache = cache[id];

				if (!pvt) {
					if (!thisCache.data) {
						thisCache.data = {};
					}

					thisCache = thisCache.data;
				}

				if (data !== undefined) {
					thisCache[jQuery.camelCase(name)] = data;
				}

				if (typeof name === "string") {

					ret = thisCache[name];

					if (ret == null) {

						ret = thisCache[jQuery.camelCase(name)];
					}
				} else {
					ret = thisCache;
				}

				return ret;
			}

			function internalRemoveData(elem, name, pvt) {
				if (!acceptData(elem)) {
					return;
				}

				var thisCache,
				    i,
				    isNode = elem.nodeType,


				cache = isNode ? jQuery.cache : elem,
				    id = isNode ? elem[jQuery.expando] : jQuery.expando;

				if (!cache[id]) {
					return;
				}

				if (name) {

					thisCache = pvt ? cache[id] : cache[id].data;

					if (thisCache) {

						if (!jQuery.isArray(name)) {

							if (name in thisCache) {
								name = [name];
							} else {

								name = jQuery.camelCase(name);
								if (name in thisCache) {
									name = [name];
								} else {
									name = name.split(" ");
								}
							}
						} else {

							name = name.concat(jQuery.map(name, jQuery.camelCase));
						}

						i = name.length;
						while (i--) {
							delete thisCache[name[i]];
						}

						if (pvt ? !isEmptyDataObject(thisCache) : !jQuery.isEmptyObject(thisCache)) {
							return;
						}
					}
				}

				if (!pvt) {
					delete cache[id].data;

					if (!isEmptyDataObject(cache[id])) {
						return;
					}
				}

				if (isNode) {
					jQuery.cleanData([elem], true);

				} else if (support.deleteExpando || cache != cache.window) {
					delete cache[id];

				} else {
					cache[id] = undefined;
				}
			}

			jQuery.extend({
				cache: {},

				noData: {
					"applet ": true,
					"embed ": true,

					"object ": "clsid:D27CDB6E-AE6D-11cf-96B8-444553540000"
				},

				hasData: function hasData(elem) {
					elem = elem.nodeType ? jQuery.cache[elem[jQuery.expando]] : elem[jQuery.expando];
					return !!elem && !isEmptyDataObject(elem);
				},

				data: function data(elem, name, _data) {
					return internalData(elem, name, _data);
				},

				removeData: function removeData(elem, name) {
					return internalRemoveData(elem, name);
				},

				_data: function _data(elem, name, data) {
					return internalData(elem, name, data, true);
				},

				_removeData: function _removeData(elem, name) {
					return internalRemoveData(elem, name, true);
				}
			});

			jQuery.fn.extend({
				data: function data(key, value) {
					var i,
					    name,
					    data,
					    elem = this[0],
					    attrs = elem && elem.attributes;


					if (key === undefined) {
						if (this.length) {
							data = jQuery.data(elem);

							if (elem.nodeType === 1 && !jQuery._data(elem, "parsedAttrs")) {
								i = attrs.length;
								while (i--) {

									if (attrs[i]) {
										name = attrs[i].name;
										if (name.indexOf("data-") === 0) {
											name = jQuery.camelCase(name.slice(5));
											dataAttr(elem, name, data[name]);
										}
									}
								}
								jQuery._data(elem, "parsedAttrs", true);
							}
						}

						return data;
					}

					if ((typeof key === "undefined" ? "undefined" : _typeof(key)) === "object") {
						return this.each(function () {
							jQuery.data(this, key);
						});
					}

					return arguments.length > 1 ?

					this.each(function () {
						jQuery.data(this, key, value);
					}) :

					elem ? dataAttr(elem, key, jQuery.data(elem, key)) : undefined;
				},

				removeData: function removeData(key) {
					return this.each(function () {
						jQuery.removeData(this, key);
					});
				}
			});

			jQuery.extend({
				queue: function queue(elem, type, data) {
					var queue;

					if (elem) {
						type = (type || "fx") + "queue";
						queue = jQuery._data(elem, type);

						if (data) {
							if (!queue || jQuery.isArray(data)) {
								queue = jQuery._data(elem, type, jQuery.makeArray(data));
							} else {
								queue.push(data);
							}
						}
						return queue || [];
					}
				},

				dequeue: function dequeue(elem, type) {
					type = type || "fx";

					var queue = jQuery.queue(elem, type),
					    startLength = queue.length,
					    fn = queue.shift(),
					    hooks = jQuery._queueHooks(elem, type),
					    next = function next() {
						jQuery.dequeue(elem, type);
					};

					if (fn === "inprogress") {
						fn = queue.shift();
						startLength--;
					}

					if (fn) {

						if (type === "fx") {
							queue.unshift("inprogress");
						}

						delete hooks.stop;
						fn.call(elem, next, hooks);
					}

					if (!startLength && hooks) {
						hooks.empty.fire();
					}
				},

				_queueHooks: function _queueHooks(elem, type) {
					var key = type + "queueHooks";
					return jQuery._data(elem, key) || jQuery._data(elem, key, {
						empty: jQuery.Callbacks("once memory").add(function () {
							jQuery._removeData(elem, type + "queue");
							jQuery._removeData(elem, key);
						})
					});
				}
			});

			jQuery.fn.extend({
				queue: function queue(type, data) {
					var setter = 2;

					if (typeof type !== "string") {
						data = type;
						type = "fx";
						setter--;
					}

					if (arguments.length < setter) {
						return jQuery.queue(this[0], type);
					}

					return data === undefined ? this : this.each(function () {
						var queue = jQuery.queue(this, type, data);

						jQuery._queueHooks(this, type);

						if (type === "fx" && queue[0] !== "inprogress") {
							jQuery.dequeue(this, type);
						}
					});
				},
				dequeue: function dequeue(type) {
					return this.each(function () {
						jQuery.dequeue(this, type);
					});
				},
				clearQueue: function clearQueue(type) {
					return this.queue(type || "fx", []);
				},

				promise: function promise(type, obj) {
					var tmp,
					    count = 1,
					    defer = jQuery.Deferred(),
					    elements = this,
					    i = this.length,
					    resolve = function resolve() {
						if (! --count) {
							defer.resolveWith(elements, [elements]);
						}
					};

					if (typeof type !== "string") {
						obj = type;
						type = undefined;
					}
					type = type || "fx";

					while (i--) {
						tmp = jQuery._data(elements[i], type + "queueHooks");
						if (tmp && tmp.empty) {
							count++;
							tmp.empty.add(resolve);
						}
					}
					resolve();
					return defer.promise(obj);
				}
			});

			(function () {
				var shrinkWrapBlocksVal;

				support.shrinkWrapBlocks = function () {
					if (shrinkWrapBlocksVal != null) {
						return shrinkWrapBlocksVal;
					}

					shrinkWrapBlocksVal = false;

					var div, body, container;

					body = document.getElementsByTagName("body")[0];
					if (!body || !body.style) {

						return;
					}

					div = document.createElement("div");
					container = document.createElement("div");
					container.style.cssText = "position:absolute;border:0;width:0;height:0;top:0;left:-9999px";
					body.appendChild(container).appendChild(div);

					if (typeof div.style.zoom !== "undefined") {

						div.style.cssText =

						"-webkit-box-sizing:content-box;-moz-box-sizing:content-box;" + "box-sizing:content-box;display:block;margin:0;border:0;" + "padding:1px;width:1px;zoom:1";
						div.appendChild(document.createElement("div")).style.width = "5px";
						shrinkWrapBlocksVal = div.offsetWidth !== 3;
					}

					body.removeChild(container);

					return shrinkWrapBlocksVal;
				};
			})();
			var pnum = /[+-]?(?:\d*\.|)\d+(?:[eE][+-]?\d+|)/.source;

			var rcssNum = new RegExp("^(?:([+-])=|)(" + pnum + ")([a-z%]*)$", "i");

			var cssExpand = ["Top", "Right", "Bottom", "Left"];

			var isHidden = function isHidden(elem, el) {

				elem = el || elem;
				return jQuery.css(elem, "display") === "none" || !jQuery.contains(elem.ownerDocument, elem);
			};

			function adjustCSS(elem, prop, valueParts, tween) {
				var adjusted,
				    scale = 1,
				    maxIterations = 20,
				    currentValue = tween ? function () {
					return tween.cur();
				} : function () {
					return jQuery.css(elem, prop, "");
				},
				    initial = currentValue(),
				    unit = valueParts && valueParts[3] || (jQuery.cssNumber[prop] ? "" : "px"),


				initialInUnit = (jQuery.cssNumber[prop] || unit !== "px" && +initial) && rcssNum.exec(jQuery.css(elem, prop));

				if (initialInUnit && initialInUnit[3] !== unit) {

					unit = unit || initialInUnit[3];

					valueParts = valueParts || [];

					initialInUnit = +initial || 1;

					do {

						scale = scale || ".5";

						initialInUnit = initialInUnit / scale;
						jQuery.style(elem, prop, initialInUnit + unit);

					} while (scale !== (scale = currentValue() / initial) && scale !== 1 && --maxIterations);
				}

				if (valueParts) {
					initialInUnit = +initialInUnit || +initial || 0;

					adjusted = valueParts[1] ? initialInUnit + (valueParts[1] + 1) * valueParts[2] : +valueParts[2];
					if (tween) {
						tween.unit = unit;
						tween.start = initialInUnit;
						tween.end = adjusted;
					}
				}
				return adjusted;
			}

			var access = function access(elems, fn, key, value, chainable, emptyGet, raw) {
				var i = 0,
				    length = elems.length,
				    bulk = key == null;

				if (jQuery.type(key) === "object") {
					chainable = true;
					for (i in key) {
						access(elems, fn, i, key[i], true, emptyGet, raw);
					}

				} else if (value !== undefined) {
					chainable = true;

					if (!jQuery.isFunction(value)) {
						raw = true;
					}

					if (bulk) {

						if (raw) {
							fn.call(elems, value);
							fn = null;

						} else {
							bulk = fn;
							fn = function fn(elem, key, value) {
								return bulk.call(jQuery(elem), value);
							};
						}
					}

					if (fn) {
						for (; i < length; i++) {
							fn(elems[i], key, raw ? value : value.call(elems[i], i, fn(elems[i], key)));
						}
					}
				}

				return chainable ? elems :

				bulk ? fn.call(elems) : length ? fn(elems[0], key) : emptyGet;
			};
			var rcheckableType = /^(?:checkbox|radio)$/i;

			var rtagName = /<([\w:-]+)/;

			var rscriptType = /^$|\/(?:java|ecma)script/i;

			var rleadingWhitespace = /^\s+/;

			var nodeNames = "abbr|article|aside|audio|bdi|canvas|data|datalist|" + "details|dialog|figcaption|figure|footer|header|hgroup|main|" + "mark|meter|nav|output|picture|progress|section|summary|template|time|video";

			function createSafeFragment(document) {
				var list = nodeNames.split("|"),
				    safeFrag = document.createDocumentFragment();

				if (safeFrag.createElement) {
					while (list.length) {
						safeFrag.createElement(list.pop());
					}
				}
				return safeFrag;
			}

			(function () {
				var div = document.createElement("div"),
				    fragment = document.createDocumentFragment(),
				    input = document.createElement("input");

				div.innerHTML = "  <link/><table></table><a href='/a'>a</a><input type='checkbox'/>";

				support.leadingWhitespace = div.firstChild.nodeType === 3;

				support.tbody = !div.getElementsByTagName("tbody").length;

				support.htmlSerialize = !!div.getElementsByTagName("link").length;

				support.html5Clone = document.createElement("nav").cloneNode(true).outerHTML !== "<:nav></:nav>";

				input.type = "checkbox";
				input.checked = true;
				fragment.appendChild(input);
				support.appendChecked = input.checked;

				div.innerHTML = "<textarea>x</textarea>";
				support.noCloneChecked = !!div.cloneNode(true).lastChild.defaultValue;

				fragment.appendChild(div);

				input = document.createElement("input");
				input.setAttribute("type", "radio");
				input.setAttribute("checked", "checked");
				input.setAttribute("name", "t");

				div.appendChild(input);

				support.checkClone = div.cloneNode(true).cloneNode(true).lastChild.checked;

				support.noCloneEvent = !!div.addEventListener;

				div[jQuery.expando] = 1;
				support.attributes = !div.getAttribute(jQuery.expando);
			})();

			var wrapMap = {
				option: [1, "<select multiple='multiple'>", "</select>"],
				legend: [1, "<fieldset>", "</fieldset>"],
				area: [1, "<map>", "</map>"],

				param: [1, "<object>", "</object>"],
				thead: [1, "<table>", "</table>"],
				tr: [2, "<table><tbody>", "</tbody></table>"],
				col: [2, "<table><tbody></tbody><colgroup>", "</colgroup></table>"],
				td: [3, "<table><tbody><tr>", "</tr></tbody></table>"],

				_default: support.htmlSerialize ? [0, "", ""] : [1, "X<div>", "</div>"]
			};

			wrapMap.optgroup = wrapMap.option;

			wrapMap.tbody = wrapMap.tfoot = wrapMap.colgroup = wrapMap.caption = wrapMap.thead;
			wrapMap.th = wrapMap.td;

			function getAll(context, tag) {
				var elems,
				    elem,
				    i = 0,
				    found = typeof context.getElementsByTagName !== "undefined" ? context.getElementsByTagName(tag || "*") : typeof context.querySelectorAll !== "undefined" ? context.querySelectorAll(tag || "*") : undefined;

				if (!found) {
					for (found = [], elems = context.childNodes || context; (elem = elems[i]) != null; i++) {
						if (!tag || jQuery.nodeName(elem, tag)) {
							found.push(elem);
						} else {
							jQuery.merge(found, getAll(elem, tag));
						}
					}
				}

				return tag === undefined || tag && jQuery.nodeName(context, tag) ? jQuery.merge([context], found) : found;
			}

			function setGlobalEval(elems, refElements) {
				var elem,
				    i = 0;
				for (; (elem = elems[i]) != null; i++) {
					jQuery._data(elem, "globalEval", !refElements || jQuery._data(refElements[i], "globalEval"));
				}
			}

			var rhtml = /<|&#?\w+;/,
			    rtbody = /<tbody/i;

			function fixDefaultChecked(elem) {
				if (rcheckableType.test(elem.type)) {
					elem.defaultChecked = elem.checked;
				}
			}

			function buildFragment(elems, context, scripts, selection, ignored) {
				var j,
				    elem,
				    contains,
				    tmp,
				    tag,
				    tbody,
				    wrap,
				    l = elems.length,


				safe = createSafeFragment(context),
				    nodes = [],
				    i = 0;

				for (; i < l; i++) {
					elem = elems[i];

					if (elem || elem === 0) {

						if (jQuery.type(elem) === "object") {
							jQuery.merge(nodes, elem.nodeType ? [elem] : elem);

						} else if (!rhtml.test(elem)) {
							nodes.push(context.createTextNode(elem));

						} else {
							tmp = tmp || safe.appendChild(context.createElement("div"));

							tag = (rtagName.exec(elem) || ["", ""])[1].toLowerCase();
							wrap = wrapMap[tag] || wrapMap._default;

							tmp.innerHTML = wrap[1] + jQuery.htmlPrefilter(elem) + wrap[2];

							j = wrap[0];
							while (j--) {
								tmp = tmp.lastChild;
							}

							if (!support.leadingWhitespace && rleadingWhitespace.test(elem)) {
								nodes.push(context.createTextNode(rleadingWhitespace.exec(elem)[0]));
							}

							if (!support.tbody) {

								elem = tag === "table" && !rtbody.test(elem) ? tmp.firstChild :

								wrap[1] === "<table>" && !rtbody.test(elem) ? tmp : 0;

								j = elem && elem.childNodes.length;
								while (j--) {
									if (jQuery.nodeName(tbody = elem.childNodes[j], "tbody") && !tbody.childNodes.length) {

										elem.removeChild(tbody);
									}
								}
							}

							jQuery.merge(nodes, tmp.childNodes);

							tmp.textContent = "";

							while (tmp.firstChild) {
								tmp.removeChild(tmp.firstChild);
							}

							tmp = safe.lastChild;
						}
					}
				}

				if (tmp) {
					safe.removeChild(tmp);
				}

				if (!support.appendChecked) {
					jQuery.grep(getAll(nodes, "input"), fixDefaultChecked);
				}

				i = 0;
				while (elem = nodes[i++]) {

					if (selection && jQuery.inArray(elem, selection) > -1) {
						if (ignored) {
							ignored.push(elem);
						}

						continue;
					}

					contains = jQuery.contains(elem.ownerDocument, elem);

					tmp = getAll(safe.appendChild(elem), "script");

					if (contains) {
						setGlobalEval(tmp);
					}

					if (scripts) {
						j = 0;
						while (elem = tmp[j++]) {
							if (rscriptType.test(elem.type || "")) {
								scripts.push(elem);
							}
						}
					}
				}

				tmp = null;

				return safe;
			}

			(function () {
				var i,
				    eventName,
				    div = document.createElement("div");

				for (i in { submit: true, change: true, focusin: true }) {
					eventName = "on" + i;

					if (!(support[i] = eventName in window)) {

						div.setAttribute(eventName, "t");
						support[i] = div.attributes[eventName].expando === false;
					}
				}

				div = null;
			})();

			var rformElems = /^(?:input|select|textarea)$/i,
			    rkeyEvent = /^key/,
			    rmouseEvent = /^(?:mouse|pointer|contextmenu|drag|drop)|click/,
			    rfocusMorph = /^(?:focusinfocus|focusoutblur)$/,
			    rtypenamespace = /^([^.]*)(?:\.(.+)|)/;

			function returnTrue() {
				return true;
			}

			function returnFalse() {
				return false;
			}

			function safeActiveElement() {
				try {
					return document.activeElement;
				} catch (err) {}
			}

			function _on(elem, types, selector, data, fn, one) {
				var origFn, type;

				if ((typeof types === "undefined" ? "undefined" : _typeof(types)) === "object") {

					if (typeof selector !== "string") {

						data = data || selector;
						selector = undefined;
					}
					for (type in types) {
						_on(elem, type, selector, data, types[type], one);
					}
					return elem;
				}

				if (data == null && fn == null) {

					fn = selector;
					data = selector = undefined;
				} else if (fn == null) {
					if (typeof selector === "string") {

						fn = data;
						data = undefined;
					} else {

						fn = data;
						data = selector;
						selector = undefined;
					}
				}
				if (fn === false) {
					fn = returnFalse;
				} else if (!fn) {
					return elem;
				}

				if (one === 1) {
					origFn = fn;
					fn = function fn(event) {

						jQuery().off(event);
						return origFn.apply(this, arguments);
					};

					fn.guid = origFn.guid || (origFn.guid = jQuery.guid++);
				}
				return elem.each(function () {
					jQuery.event.add(this, types, fn, data, selector);
				});
			}

			jQuery.event = {

				global: {},

				add: function add(elem, types, handler, data, selector) {
					var tmp,
					    events,
					    t,
					    handleObjIn,
					    special,
					    eventHandle,
					    handleObj,
					    handlers,
					    type,
					    namespaces,
					    origType,
					    elemData = jQuery._data(elem);

					if (!elemData) {
						return;
					}

					if (handler.handler) {
						handleObjIn = handler;
						handler = handleObjIn.handler;
						selector = handleObjIn.selector;
					}

					if (!handler.guid) {
						handler.guid = jQuery.guid++;
					}

					if (!(events = elemData.events)) {
						events = elemData.events = {};
					}
					if (!(eventHandle = elemData.handle)) {
						eventHandle = elemData.handle = function (e) {

							return typeof jQuery !== "undefined" && (!e || jQuery.event.triggered !== e.type) ? jQuery.event.dispatch.apply(eventHandle.elem, arguments) : undefined;
						};

						eventHandle.elem = elem;
					}

					types = (types || "").match(rnotwhite) || [""];
					t = types.length;
					while (t--) {
						tmp = rtypenamespace.exec(types[t]) || [];
						type = origType = tmp[1];
						namespaces = (tmp[2] || "").split(".").sort();

						if (!type) {
							continue;
						}

						special = jQuery.event.special[type] || {};

						type = (selector ? special.delegateType : special.bindType) || type;

						special = jQuery.event.special[type] || {};

						handleObj = jQuery.extend({
							type: type,
							origType: origType,
							data: data,
							handler: handler,
							guid: handler.guid,
							selector: selector,
							needsContext: selector && jQuery.expr.match.needsContext.test(selector),
							namespace: namespaces.join(".")
						}, handleObjIn);

						if (!(handlers = events[type])) {
							handlers = events[type] = [];
							handlers.delegateCount = 0;

							if (!special.setup || special.setup.call(elem, data, namespaces, eventHandle) === false) {

								if (elem.addEventListener) {
									elem.addEventListener(type, eventHandle, false);
								} else if (elem.attachEvent) {
									elem.attachEvent("on" + type, eventHandle);
								}
							}
						}

						if (special.add) {
							special.add.call(elem, handleObj);

							if (!handleObj.handler.guid) {
								handleObj.handler.guid = handler.guid;
							}
						}

						if (selector) {
							handlers.splice(handlers.delegateCount++, 0, handleObj);
						} else {
							handlers.push(handleObj);
						}

						jQuery.event.global[type] = true;
					}

					elem = null;
				},

				remove: function remove(elem, types, handler, selector, mappedTypes) {
					var j,
					    handleObj,
					    tmp,
					    origCount,
					    t,
					    events,
					    special,
					    handlers,
					    type,
					    namespaces,
					    origType,
					    elemData = jQuery.hasData(elem) && jQuery._data(elem);

					if (!elemData || !(events = elemData.events)) {
						return;
					}

					types = (types || "").match(rnotwhite) || [""];
					t = types.length;
					while (t--) {
						tmp = rtypenamespace.exec(types[t]) || [];
						type = origType = tmp[1];
						namespaces = (tmp[2] || "").split(".").sort();

						if (!type) {
							for (type in events) {
								jQuery.event.remove(elem, type + types[t], handler, selector, true);
							}
							continue;
						}

						special = jQuery.event.special[type] || {};
						type = (selector ? special.delegateType : special.bindType) || type;
						handlers = events[type] || [];
						tmp = tmp[2] && new RegExp("(^|\\.)" + namespaces.join("\\.(?:.*\\.|)") + "(\\.|$)");

						origCount = j = handlers.length;
						while (j--) {
							handleObj = handlers[j];

							if ((mappedTypes || origType === handleObj.origType) && (!handler || handler.guid === handleObj.guid) && (!tmp || tmp.test(handleObj.namespace)) && (!selector || selector === handleObj.selector || selector === "**" && handleObj.selector)) {
								handlers.splice(j, 1);

								if (handleObj.selector) {
									handlers.delegateCount--;
								}
								if (special.remove) {
									special.remove.call(elem, handleObj);
								}
							}
						}

						if (origCount && !handlers.length) {
							if (!special.teardown || special.teardown.call(elem, namespaces, elemData.handle) === false) {

								jQuery.removeEvent(elem, type, elemData.handle);
							}

							delete events[type];
						}
					}

					if (jQuery.isEmptyObject(events)) {
						delete elemData.handle;

						jQuery._removeData(elem, "events");
					}
				},

				trigger: function trigger(event, data, elem, onlyHandlers) {
					var handle,
					    ontype,
					    cur,
					    bubbleType,
					    special,
					    tmp,
					    i,
					    eventPath = [elem || document],
					    type = hasOwn.call(event, "type") ? event.type : event,
					    namespaces = hasOwn.call(event, "namespace") ? event.namespace.split(".") : [];

					cur = tmp = elem = elem || document;

					if (elem.nodeType === 3 || elem.nodeType === 8) {
						return;
					}

					if (rfocusMorph.test(type + jQuery.event.triggered)) {
						return;
					}

					if (type.indexOf(".") > -1) {

						namespaces = type.split(".");
						type = namespaces.shift();
						namespaces.sort();
					}
					ontype = type.indexOf(":") < 0 && "on" + type;

					event = event[jQuery.expando] ? event : new jQuery.Event(type, (typeof event === "undefined" ? "undefined" : _typeof(event)) === "object" && event);

					event.isTrigger = onlyHandlers ? 2 : 3;
					event.namespace = namespaces.join(".");
					event.rnamespace = event.namespace ? new RegExp("(^|\\.)" + namespaces.join("\\.(?:.*\\.|)") + "(\\.|$)") : null;

					event.result = undefined;
					if (!event.target) {
						event.target = elem;
					}

					data = data == null ? [event] : jQuery.makeArray(data, [event]);

					special = jQuery.event.special[type] || {};
					if (!onlyHandlers && special.trigger && special.trigger.apply(elem, data) === false) {
						return;
					}

					if (!onlyHandlers && !special.noBubble && !jQuery.isWindow(elem)) {

						bubbleType = special.delegateType || type;
						if (!rfocusMorph.test(bubbleType + type)) {
							cur = cur.parentNode;
						}
						for (; cur; cur = cur.parentNode) {
							eventPath.push(cur);
							tmp = cur;
						}

						if (tmp === (elem.ownerDocument || document)) {
							eventPath.push(tmp.defaultView || tmp.parentWindow || window);
						}
					}

					i = 0;
					while ((cur = eventPath[i++]) && !event.isPropagationStopped()) {

						event.type = i > 1 ? bubbleType : special.bindType || type;

						handle = (jQuery._data(cur, "events") || {})[event.type] && jQuery._data(cur, "handle");

						if (handle) {
							handle.apply(cur, data);
						}

						handle = ontype && cur[ontype];
						if (handle && handle.apply && acceptData(cur)) {
							event.result = handle.apply(cur, data);
							if (event.result === false) {
								event.preventDefault();
							}
						}
					}
					event.type = type;

					if (!onlyHandlers && !event.isDefaultPrevented()) {

						if ((!special._default || special._default.apply(eventPath.pop(), data) === false) && acceptData(elem)) {

							if (ontype && elem[type] && !jQuery.isWindow(elem)) {

								tmp = elem[ontype];

								if (tmp) {
									elem[ontype] = null;
								}

								jQuery.event.triggered = type;
								try {
									elem[type]();
								} catch (e) {

								}
								jQuery.event.triggered = undefined;

								if (tmp) {
									elem[ontype] = tmp;
								}
							}
						}
					}

					return event.result;
				},

				dispatch: function dispatch(event) {

					event = jQuery.event.fix(event);

					var i,
					    j,
					    ret,
					    matched,
					    handleObj,
					    handlerQueue = [],
					    args = _slice.call(arguments),
					    handlers = (jQuery._data(this, "events") || {})[event.type] || [],
					    special = jQuery.event.special[event.type] || {};

					args[0] = event;
					event.delegateTarget = this;

					if (special.preDispatch && special.preDispatch.call(this, event) === false) {
						return;
					}

					handlerQueue = jQuery.event.handlers.call(this, event, handlers);

					i = 0;
					while ((matched = handlerQueue[i++]) && !event.isPropagationStopped()) {
						event.currentTarget = matched.elem;

						j = 0;
						while ((handleObj = matched.handlers[j++]) && !event.isImmediatePropagationStopped()) {

							if (!event.rnamespace || event.rnamespace.test(handleObj.namespace)) {

								event.handleObj = handleObj;
								event.data = handleObj.data;

								ret = ((jQuery.event.special[handleObj.origType] || {}).handle || handleObj.handler).apply(matched.elem, args);

								if (ret !== undefined) {
									if ((event.result = ret) === false) {
										event.preventDefault();
										event.stopPropagation();
									}
								}
							}
						}
					}

					if (special.postDispatch) {
						special.postDispatch.call(this, event);
					}

					return event.result;
				},

				handlers: function handlers(event, _handlers) {
					var i,
					    matches,
					    sel,
					    handleObj,
					    handlerQueue = [],
					    delegateCount = _handlers.delegateCount,
					    cur = event.target;

					if (delegateCount && cur.nodeType && (event.type !== "click" || isNaN(event.button) || event.button < 1)) {

						for (; cur != this; cur = cur.parentNode || this) {

							if (cur.nodeType === 1 && (cur.disabled !== true || event.type !== "click")) {
								matches = [];
								for (i = 0; i < delegateCount; i++) {
									handleObj = _handlers[i];

									sel = handleObj.selector + " ";

									if (matches[sel] === undefined) {
										matches[sel] = handleObj.needsContext ? jQuery(sel, this).index(cur) > -1 : jQuery.find(sel, this, null, [cur]).length;
									}
									if (matches[sel]) {
										matches.push(handleObj);
									}
								}
								if (matches.length) {
									handlerQueue.push({ elem: cur, handlers: matches });
								}
							}
						}
					}

					if (delegateCount < _handlers.length) {
						handlerQueue.push({ elem: this, handlers: _handlers.slice(delegateCount) });
					}

					return handlerQueue;
				},

				fix: function fix(event) {
					if (event[jQuery.expando]) {
						return event;
					}

					var i,
					    prop,
					    copy,
					    type = event.type,
					    originalEvent = event,
					    fixHook = this.fixHooks[type];

					if (!fixHook) {
						this.fixHooks[type] = fixHook = rmouseEvent.test(type) ? this.mouseHooks : rkeyEvent.test(type) ? this.keyHooks : {};
					}
					copy = fixHook.props ? this.props.concat(fixHook.props) : this.props;

					event = new jQuery.Event(originalEvent);

					i = copy.length;
					while (i--) {
						prop = copy[i];
						event[prop] = originalEvent[prop];
					}

					if (!event.target) {
						event.target = originalEvent.srcElement || document;
					}

					if (event.target.nodeType === 3) {
						event.target = event.target.parentNode;
					}

					event.metaKey = !!event.metaKey;

					return fixHook.filter ? fixHook.filter(event, originalEvent) : event;
				},

				props: ("altKey bubbles cancelable ctrlKey currentTarget detail eventPhase " + "metaKey relatedTarget shiftKey target timeStamp view which").split(" "),

				fixHooks: {},

				keyHooks: {
					props: "char charCode key keyCode".split(" "),
					filter: function filter(event, original) {

						if (event.which == null) {
							event.which = original.charCode != null ? original.charCode : original.keyCode;
						}

						return event;
					}
				},

				mouseHooks: {
					props: ("button buttons clientX clientY fromElement offsetX offsetY " + "pageX pageY screenX screenY toElement").split(" "),
					filter: function filter(event, original) {
						var body,
						    eventDoc,
						    doc,
						    button = original.button,
						    fromElement = original.fromElement;

						if (event.pageX == null && original.clientX != null) {
							eventDoc = event.target.ownerDocument || document;
							doc = eventDoc.documentElement;
							body = eventDoc.body;

							event.pageX = original.clientX + (doc && doc.scrollLeft || body && body.scrollLeft || 0) - (doc && doc.clientLeft || body && body.clientLeft || 0);
							event.pageY = original.clientY + (doc && doc.scrollTop || body && body.scrollTop || 0) - (doc && doc.clientTop || body && body.clientTop || 0);
						}

						if (!event.relatedTarget && fromElement) {
							event.relatedTarget = fromElement === event.target ? original.toElement : fromElement;
						}

						if (!event.which && button !== undefined) {
							event.which = button & 1 ? 1 : button & 2 ? 3 : button & 4 ? 2 : 0;
						}

						return event;
					}
				},

				special: {
					load: {

						noBubble: true
					},
					focus: {

						trigger: function trigger() {
							if (this !== safeActiveElement() && this.focus) {
								try {
									this.focus();
									return false;
								} catch (e) {

								}
							}
						},
						delegateType: "focusin"
					},
					blur: {
						trigger: function trigger() {
							if (this === safeActiveElement() && this.blur) {
								this.blur();
								return false;
							}
						},
						delegateType: "focusout"
					},
					click: {

						trigger: function trigger() {
							if (jQuery.nodeName(this, "input") && this.type === "checkbox" && this.click) {
								this.click();
								return false;
							}
						},

						_default: function _default(event) {
							return jQuery.nodeName(event.target, "a");
						}
					},

					beforeunload: {
						postDispatch: function postDispatch(event) {

							if (event.result !== undefined && event.originalEvent) {
								event.originalEvent.returnValue = event.result;
							}
						}
					}
				},

				simulate: function simulate(type, elem, event) {
					var e = jQuery.extend(new jQuery.Event(), event, {
						type: type,
						isSimulated: true

					});

					jQuery.event.trigger(e, null, elem);

					if (e.isDefaultPrevented()) {
						event.preventDefault();
					}
				}
			};

			jQuery.removeEvent = document.removeEventListener ? function (elem, type, handle) {

				if (elem.removeEventListener) {
					elem.removeEventListener(type, handle);
				}
			} : function (elem, type, handle) {
				var name = "on" + type;

				if (elem.detachEvent) {

					if (typeof elem[name] === "undefined") {
						elem[name] = null;
					}

					elem.detachEvent(name, handle);
				}
			};

			jQuery.Event = function (src, props) {

				if (!(this instanceof jQuery.Event)) {
					return new jQuery.Event(src, props);
				}

				if (src && src.type) {
					this.originalEvent = src;
					this.type = src.type;

					this.isDefaultPrevented = src.defaultPrevented || src.defaultPrevented === undefined &&

					src.returnValue === false ? returnTrue : returnFalse;

				} else {
					this.type = src;
				}

				if (props) {
					jQuery.extend(this, props);
				}

				this.timeStamp = src && src.timeStamp || jQuery.now();

				this[jQuery.expando] = true;
			};

			jQuery.Event.prototype = {
				constructor: jQuery.Event,
				isDefaultPrevented: returnFalse,
				isPropagationStopped: returnFalse,
				isImmediatePropagationStopped: returnFalse,

				preventDefault: function preventDefault() {
					var e = this.originalEvent;

					this.isDefaultPrevented = returnTrue;
					if (!e) {
						return;
					}

					if (e.preventDefault) {
						e.preventDefault();

					} else {
						e.returnValue = false;
					}
				},
				stopPropagation: function stopPropagation() {
					var e = this.originalEvent;

					this.isPropagationStopped = returnTrue;

					if (!e || this.isSimulated) {
						return;
					}

					if (e.stopPropagation) {
						e.stopPropagation();
					}

					e.cancelBubble = true;
				},
				stopImmediatePropagation: function stopImmediatePropagation() {
					var e = this.originalEvent;

					this.isImmediatePropagationStopped = returnTrue;

					if (e && e.stopImmediatePropagation) {
						e.stopImmediatePropagation();
					}

					this.stopPropagation();
				}
			};

			jQuery.each({
				mouseenter: "mouseover",
				mouseleave: "mouseout",
				pointerenter: "pointerover",
				pointerleave: "pointerout"
			}, function (orig, fix) {
				jQuery.event.special[orig] = {
					delegateType: fix,
					bindType: fix,

					handle: function handle(event) {
						var ret,
						    target = this,
						    related = event.relatedTarget,
						    handleObj = event.handleObj;

						if (!related || related !== target && !jQuery.contains(target, related)) {
							event.type = handleObj.origType;
							ret = handleObj.handler.apply(this, arguments);
							event.type = fix;
						}
						return ret;
					}
				};
			});

			if (!support.submit) {

				jQuery.event.special.submit = {
					setup: function setup() {

						if (jQuery.nodeName(this, "form")) {
							return false;
						}

						jQuery.event.add(this, "click._submit keypress._submit", function (e) {

							var elem = e.target,
							    form = jQuery.nodeName(elem, "input") || jQuery.nodeName(elem, "button") ?

							jQuery.prop(elem, "form") : undefined;

							if (form && !jQuery._data(form, "submit")) {
								jQuery.event.add(form, "submit._submit", function (event) {
									event._submitBubble = true;
								});
								jQuery._data(form, "submit", true);
							}
						});

					},

					postDispatch: function postDispatch(event) {

						if (event._submitBubble) {
							delete event._submitBubble;
							if (this.parentNode && !event.isTrigger) {
								jQuery.event.simulate("submit", this.parentNode, event);
							}
						}
					},

					teardown: function teardown() {

						if (jQuery.nodeName(this, "form")) {
							return false;
						}

						jQuery.event.remove(this, "._submit");
					}
				};
			}

			if (!support.change) {

				jQuery.event.special.change = {

					setup: function setup() {

						if (rformElems.test(this.nodeName)) {

							if (this.type === "checkbox" || this.type === "radio") {
								jQuery.event.add(this, "propertychange._change", function (event) {
									if (event.originalEvent.propertyName === "checked") {
										this._justChanged = true;
									}
								});
								jQuery.event.add(this, "click._change", function (event) {
									if (this._justChanged && !event.isTrigger) {
										this._justChanged = false;
									}

									jQuery.event.simulate("change", this, event);
								});
							}
							return false;
						}

						jQuery.event.add(this, "beforeactivate._change", function (e) {
							var elem = e.target;

							if (rformElems.test(elem.nodeName) && !jQuery._data(elem, "change")) {
								jQuery.event.add(elem, "change._change", function (event) {
									if (this.parentNode && !event.isSimulated && !event.isTrigger) {
										jQuery.event.simulate("change", this.parentNode, event);
									}
								});
								jQuery._data(elem, "change", true);
							}
						});
					},

					handle: function handle(event) {
						var elem = event.target;

						if (this !== elem || event.isSimulated || event.isTrigger || elem.type !== "radio" && elem.type !== "checkbox") {

							return event.handleObj.handler.apply(this, arguments);
						}
					},

					teardown: function teardown() {
						jQuery.event.remove(this, "._change");

						return !rformElems.test(this.nodeName);
					}
				};
			}

			if (!support.focusin) {
				jQuery.each({ focus: "focusin", blur: "focusout" }, function (orig, fix) {

					var handler = function handler(event) {
						jQuery.event.simulate(fix, event.target, jQuery.event.fix(event));
					};

					jQuery.event.special[fix] = {
						setup: function setup() {
							var doc = this.ownerDocument || this,
							    attaches = jQuery._data(doc, fix);

							if (!attaches) {
								doc.addEventListener(orig, handler, true);
							}
							jQuery._data(doc, fix, (attaches || 0) + 1);
						},
						teardown: function teardown() {
							var doc = this.ownerDocument || this,
							    attaches = jQuery._data(doc, fix) - 1;

							if (!attaches) {
								doc.removeEventListener(orig, handler, true);
								jQuery._removeData(doc, fix);
							} else {
								jQuery._data(doc, fix, attaches);
							}
						}
					};
				});
			}

			jQuery.fn.extend({

				on: function on(types, selector, data, fn) {
					return _on(this, types, selector, data, fn);
				},
				one: function one(types, selector, data, fn) {
					return _on(this, types, selector, data, fn, 1);
				},
				off: function off(types, selector, fn) {
					var handleObj, type;
					if (types && types.preventDefault && types.handleObj) {

						handleObj = types.handleObj;
						jQuery(types.delegateTarget).off(handleObj.namespace ? handleObj.origType + "." + handleObj.namespace : handleObj.origType, handleObj.selector, handleObj.handler);
						return this;
					}
					if ((typeof types === "undefined" ? "undefined" : _typeof(types)) === "object") {

						for (type in types) {
							this.off(type, selector, types[type]);
						}
						return this;
					}
					if (selector === false || typeof selector === "function") {

						fn = selector;
						selector = undefined;
					}
					if (fn === false) {
						fn = returnFalse;
					}
					return this.each(function () {
						jQuery.event.remove(this, types, fn, selector);
					});
				},

				trigger: function trigger(type, data) {
					return this.each(function () {
						jQuery.event.trigger(type, data, this);
					});
				},
				triggerHandler: function triggerHandler(type, data) {
					var elem = this[0];
					if (elem) {
						return jQuery.event.trigger(type, data, elem, true);
					}
				}
			});

			var rinlinejQuery = / jQuery\d+="(?:null|\d+)"/g,
			    rnoshimcache = new RegExp("<(?:" + nodeNames + ")[\\s/>]", "i"),
			    rxhtmlTag = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([\w:-]+)[^>]*)\/>/gi,


			rnoInnerhtml = /<script|<style|<link/i,


			rchecked = /checked\s*(?:[^=]|=\s*.checked.)/i,
			    rscriptTypeMasked = /^true\/(.*)/,
			    rcleanScript = /^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g,
			    safeFragment = createSafeFragment(document),
			    fragmentDiv = safeFragment.appendChild(document.createElement("div"));

			function manipulationTarget(elem, content) {
				return jQuery.nodeName(elem, "table") && jQuery.nodeName(content.nodeType !== 11 ? content : content.firstChild, "tr") ? elem.getElementsByTagName("tbody")[0] || elem.appendChild(elem.ownerDocument.createElement("tbody")) : elem;
			}

			function disableScript(elem) {
				elem.type = (jQuery.find.attr(elem, "type") !== null) + "/" + elem.type;
				return elem;
			}
			function restoreScript(elem) {
				var match = rscriptTypeMasked.exec(elem.type);
				if (match) {
					elem.type = match[1];
				} else {
					elem.removeAttribute("type");
				}
				return elem;
			}

			function cloneCopyEvent(src, dest) {
				if (dest.nodeType !== 1 || !jQuery.hasData(src)) {
					return;
				}

				var type,
				    i,
				    l,
				    oldData = jQuery._data(src),
				    curData = jQuery._data(dest, oldData),
				    events = oldData.events;

				if (events) {
					delete curData.handle;
					curData.events = {};

					for (type in events) {
						for (i = 0, l = events[type].length; i < l; i++) {
							jQuery.event.add(dest, type, events[type][i]);
						}
					}
				}

				if (curData.data) {
					curData.data = jQuery.extend({}, curData.data);
				}
			}

			function fixCloneNodeIssues(src, dest) {
				var nodeName, e, data;

				if (dest.nodeType !== 1) {
					return;
				}

				nodeName = dest.nodeName.toLowerCase();

				if (!support.noCloneEvent && dest[jQuery.expando]) {
					data = jQuery._data(dest);

					for (e in data.events) {
						jQuery.removeEvent(dest, e, data.handle);
					}

					dest.removeAttribute(jQuery.expando);
				}

				if (nodeName === "script" && dest.text !== src.text) {
					disableScript(dest).text = src.text;
					restoreScript(dest);

				} else if (nodeName === "object") {
					if (dest.parentNode) {
						dest.outerHTML = src.outerHTML;
					}

					if (support.html5Clone && src.innerHTML && !jQuery.trim(dest.innerHTML)) {
						dest.innerHTML = src.innerHTML;
					}
				} else if (nodeName === "input" && rcheckableType.test(src.type)) {


					dest.defaultChecked = dest.checked = src.checked;

					if (dest.value !== src.value) {
						dest.value = src.value;
					}

				} else if (nodeName === "option") {
					dest.defaultSelected = dest.selected = src.defaultSelected;

				} else if (nodeName === "input" || nodeName === "textarea") {
					dest.defaultValue = src.defaultValue;
				}
			}

			function domManip(collection, args, callback, ignored) {

				args = concat.apply([], args);

				var first,
				    node,
				    hasScripts,
				    scripts,
				    doc,
				    fragment,
				    i = 0,
				    l = collection.length,
				    iNoClone = l - 1,
				    value = args[0],
				    isFunction = jQuery.isFunction(value);

				if (isFunction || l > 1 && typeof value === "string" && !support.checkClone && rchecked.test(value)) {
					return collection.each(function (index) {
						var self = collection.eq(index);
						if (isFunction) {
							args[0] = value.call(this, index, self.html());
						}
						domManip(self, args, callback, ignored);
					});
				}

				if (l) {
					fragment = buildFragment(args, collection[0].ownerDocument, false, collection, ignored);
					first = fragment.firstChild;

					if (fragment.childNodes.length === 1) {
						fragment = first;
					}

					if (first || ignored) {
						scripts = jQuery.map(getAll(fragment, "script"), disableScript);
						hasScripts = scripts.length;

						for (; i < l; i++) {
							node = fragment;

							if (i !== iNoClone) {
								node = jQuery.clone(node, true, true);

								if (hasScripts) {

									jQuery.merge(scripts, getAll(node, "script"));
								}
							}

							callback.call(collection[i], node, i);
						}

						if (hasScripts) {
							doc = scripts[scripts.length - 1].ownerDocument;

							jQuery.map(scripts, restoreScript);

							for (i = 0; i < hasScripts; i++) {
								node = scripts[i];
								if (rscriptType.test(node.type || "") && !jQuery._data(node, "globalEval") && jQuery.contains(doc, node)) {

									if (node.src) {

										if (jQuery._evalUrl) {
											jQuery._evalUrl(node.src);
										}
									} else {
										jQuery.globalEval((node.text || node.textContent || node.innerHTML || "").replace(rcleanScript, ""));
									}
								}
							}
						}

						fragment = first = null;
					}
				}

				return collection;
			}

			function _remove(elem, selector, keepData) {
				var node,
				    elems = selector ? jQuery.filter(selector, elem) : elem,
				    i = 0;

				for (; (node = elems[i]) != null; i++) {

					if (!keepData && node.nodeType === 1) {
						jQuery.cleanData(getAll(node));
					}

					if (node.parentNode) {
						if (keepData && jQuery.contains(node.ownerDocument, node)) {
							setGlobalEval(getAll(node, "script"));
						}
						node.parentNode.removeChild(node);
					}
				}

				return elem;
			}

			jQuery.extend({
				htmlPrefilter: function htmlPrefilter(html) {
					return html.replace(rxhtmlTag, "<$1></$2>");
				},

				clone: function clone(elem, dataAndEvents, deepDataAndEvents) {
					var destElements,
					    node,
					    clone,
					    i,
					    srcElements,
					    inPage = jQuery.contains(elem.ownerDocument, elem);

					if (support.html5Clone || jQuery.isXMLDoc(elem) || !rnoshimcache.test("<" + elem.nodeName + ">")) {

						clone = elem.cloneNode(true);

					} else {
						fragmentDiv.innerHTML = elem.outerHTML;
						fragmentDiv.removeChild(clone = fragmentDiv.firstChild);
					}

					if ((!support.noCloneEvent || !support.noCloneChecked) && (elem.nodeType === 1 || elem.nodeType === 11) && !jQuery.isXMLDoc(elem)) {

						destElements = getAll(clone);
						srcElements = getAll(elem);

						for (i = 0; (node = srcElements[i]) != null; ++i) {

							if (destElements[i]) {
								fixCloneNodeIssues(node, destElements[i]);
							}
						}
					}

					if (dataAndEvents) {
						if (deepDataAndEvents) {
							srcElements = srcElements || getAll(elem);
							destElements = destElements || getAll(clone);

							for (i = 0; (node = srcElements[i]) != null; i++) {
								cloneCopyEvent(node, destElements[i]);
							}
						} else {
							cloneCopyEvent(elem, clone);
						}
					}

					destElements = getAll(clone, "script");
					if (destElements.length > 0) {
						setGlobalEval(destElements, !inPage && getAll(elem, "script"));
					}

					destElements = srcElements = node = null;

					return clone;
				},

				cleanData: function cleanData(elems, forceAcceptData) {
					var elem,
					    type,
					    id,
					    data,
					    i = 0,
					    internalKey = jQuery.expando,
					    cache = jQuery.cache,
					    attributes = support.attributes,
					    special = jQuery.event.special;

					for (; (elem = elems[i]) != null; i++) {
						if (forceAcceptData || acceptData(elem)) {

							id = elem[internalKey];
							data = id && cache[id];

							if (data) {
								if (data.events) {
									for (type in data.events) {
										if (special[type]) {
											jQuery.event.remove(elem, type);

										} else {
											jQuery.removeEvent(elem, type, data.handle);
										}
									}
								}

								if (cache[id]) {

									delete cache[id];

									if (!attributes && typeof elem.removeAttribute !== "undefined") {
										elem.removeAttribute(internalKey);

									} else {
										elem[internalKey] = undefined;
									}

									deletedIds.push(id);
								}
							}
						}
					}
				}
			});

			jQuery.fn.extend({

				domManip: domManip,

				detach: function detach(selector) {
					return _remove(this, selector, true);
				},

				remove: function remove(selector) {
					return _remove(this, selector);
				},

				text: function text(value) {
					return access(this, function (value) {
						return value === undefined ? jQuery.text(this) : this.empty().append((this[0] && this[0].ownerDocument || document).createTextNode(value));
					}, null, value, arguments.length);
				},

				append: function append() {
					return domManip(this, arguments, function (elem) {
						if (this.nodeType === 1 || this.nodeType === 11 || this.nodeType === 9) {
							var target = manipulationTarget(this, elem);
							target.appendChild(elem);
						}
					});
				},

				prepend: function prepend() {
					return domManip(this, arguments, function (elem) {
						if (this.nodeType === 1 || this.nodeType === 11 || this.nodeType === 9) {
							var target = manipulationTarget(this, elem);
							target.insertBefore(elem, target.firstChild);
						}
					});
				},

				before: function before() {
					return domManip(this, arguments, function (elem) {
						if (this.parentNode) {
							this.parentNode.insertBefore(elem, this);
						}
					});
				},

				after: function after() {
					return domManip(this, arguments, function (elem) {
						if (this.parentNode) {
							this.parentNode.insertBefore(elem, this.nextSibling);
						}
					});
				},

				empty: function empty() {
					var elem,
					    i = 0;

					for (; (elem = this[i]) != null; i++) {

						if (elem.nodeType === 1) {
							jQuery.cleanData(getAll(elem, false));
						}

						while (elem.firstChild) {
							elem.removeChild(elem.firstChild);
						}

						if (elem.options && jQuery.nodeName(elem, "select")) {
							elem.options.length = 0;
						}
					}

					return this;
				},

				clone: function clone(dataAndEvents, deepDataAndEvents) {
					dataAndEvents = dataAndEvents == null ? false : dataAndEvents;
					deepDataAndEvents = deepDataAndEvents == null ? dataAndEvents : deepDataAndEvents;

					return this.map(function () {
						return jQuery.clone(this, dataAndEvents, deepDataAndEvents);
					});
				},

				html: function html(value) {
					return access(this, function (value) {
						var elem = this[0] || {},
						    i = 0,
						    l = this.length;

						if (value === undefined) {
							return elem.nodeType === 1 ? elem.innerHTML.replace(rinlinejQuery, "") : undefined;
						}

						if (typeof value === "string" && !rnoInnerhtml.test(value) && (support.htmlSerialize || !rnoshimcache.test(value)) && (support.leadingWhitespace || !rleadingWhitespace.test(value)) && !wrapMap[(rtagName.exec(value) || ["", ""])[1].toLowerCase()]) {

							value = jQuery.htmlPrefilter(value);

							try {
								for (; i < l; i++) {

									elem = this[i] || {};
									if (elem.nodeType === 1) {
										jQuery.cleanData(getAll(elem, false));
										elem.innerHTML = value;
									}
								}

								elem = 0;

							} catch (e) {}
						}

						if (elem) {
							this.empty().append(value);
						}
					}, null, value, arguments.length);
				},

				replaceWith: function replaceWith() {
					var ignored = [];

					return domManip(this, arguments, function (elem) {
						var parent = this.parentNode;

						if (jQuery.inArray(this, ignored) < 0) {
							jQuery.cleanData(getAll(this));
							if (parent) {
								parent.replaceChild(elem, this);
							}
						}

					}, ignored);
				}
			});

			jQuery.each({
				appendTo: "append",
				prependTo: "prepend",
				insertBefore: "before",
				insertAfter: "after",
				replaceAll: "replaceWith"
			}, function (name, original) {
				jQuery.fn[name] = function (selector) {
					var elems,
					    i = 0,
					    ret = [],
					    insert = jQuery(selector),
					    last = insert.length - 1;

					for (; i <= last; i++) {
						elems = i === last ? this : this.clone(true);
						jQuery(insert[i])[original](elems);

						push.apply(ret, elems.get());
					}

					return this.pushStack(ret);
				};
			});

			var iframe,
			    elemdisplay = {

				HTML: "block",
				BODY: "block"
			};

			/**
    * Retrieve the actual display of a element
    * @param {String} name nodeName of the element
    * @param {Object} doc Document object
    */

			function actualDisplay(name, doc) {
				var elem = jQuery(doc.createElement(name)).appendTo(doc.body),
				    display = jQuery.css(elem[0], "display");

				elem.detach();

				return display;
			}

			/**
    * Try to determine the default display value of an element
    * @param {String} nodeName
    */
			function defaultDisplay(nodeName) {
				var doc = document,
				    display = elemdisplay[nodeName];

				if (!display) {
					display = actualDisplay(nodeName, doc);

					if (display === "none" || !display) {

						iframe = (iframe || jQuery("<iframe frameborder='0' width='0' height='0'/>")).appendTo(doc.documentElement);

						doc = (iframe[0].contentWindow || iframe[0].contentDocument).document;

						doc.write();
						doc.close();

						display = actualDisplay(nodeName, doc);
						iframe.detach();
					}

					elemdisplay[nodeName] = display;
				}

				return display;
			}
			var rmargin = /^margin/;

			var rnumnonpx = new RegExp("^(" + pnum + ")(?!px)[a-z%]+$", "i");

			var swap = function swap(elem, options, callback, args) {
				var ret,
				    name,
				    old = {};

				for (name in options) {
					old[name] = elem.style[name];
					elem.style[name] = options[name];
				}

				ret = callback.apply(elem, args || []);

				for (name in options) {
					elem.style[name] = old[name];
				}

				return ret;
			};

			var documentElement = document.documentElement;

			(function () {
				var pixelPositionVal,
				    pixelMarginRightVal,
				    boxSizingReliableVal,
				    reliableHiddenOffsetsVal,
				    reliableMarginRightVal,
				    reliableMarginLeftVal,
				    container = document.createElement("div"),
				    div = document.createElement("div");

				if (!div.style) {
					return;
				}

				div.style.cssText = "float:left;opacity:.5";

				support.opacity = div.style.opacity === "0.5";

				support.cssFloat = !!div.style.cssFloat;

				div.style.backgroundClip = "content-box";
				div.cloneNode(true).style.backgroundClip = "";
				support.clearCloneStyle = div.style.backgroundClip === "content-box";

				container = document.createElement("div");
				container.style.cssText = "border:0;width:8px;height:0;top:0;left:-9999px;" + "padding:0;margin-top:1px;position:absolute";
				div.innerHTML = "";
				container.appendChild(div);

				support.boxSizing = div.style.boxSizing === "" || div.style.MozBoxSizing === "" || div.style.WebkitBoxSizing === "";

				jQuery.extend(support, {
					reliableHiddenOffsets: function reliableHiddenOffsets() {
						if (pixelPositionVal == null) {
							computeStyleTests();
						}
						return reliableHiddenOffsetsVal;
					},

					boxSizingReliable: function boxSizingReliable() {

						if (pixelPositionVal == null) {
							computeStyleTests();
						}
						return boxSizingReliableVal;
					},

					pixelMarginRight: function pixelMarginRight() {

						if (pixelPositionVal == null) {
							computeStyleTests();
						}
						return pixelMarginRightVal;
					},

					pixelPosition: function pixelPosition() {
						if (pixelPositionVal == null) {
							computeStyleTests();
						}
						return pixelPositionVal;
					},

					reliableMarginRight: function reliableMarginRight() {

						if (pixelPositionVal == null) {
							computeStyleTests();
						}
						return reliableMarginRightVal;
					},

					reliableMarginLeft: function reliableMarginLeft() {

						if (pixelPositionVal == null) {
							computeStyleTests();
						}
						return reliableMarginLeftVal;
					}
				});

				function computeStyleTests() {
					var contents,
					    divStyle,
					    documentElement = document.documentElement;

					documentElement.appendChild(container);

					div.style.cssText =

					"-webkit-box-sizing:border-box;box-sizing:border-box;" + "position:relative;display:block;" + "margin:auto;border:1px;padding:1px;" + "top:1%;width:50%";

					pixelPositionVal = boxSizingReliableVal = reliableMarginLeftVal = false;
					pixelMarginRightVal = reliableMarginRightVal = true;

					if (window.getComputedStyle) {
						divStyle = window.getComputedStyle(div);
						pixelPositionVal = (divStyle || {}).top !== "1%";
						reliableMarginLeftVal = (divStyle || {}).marginLeft === "2px";
						boxSizingReliableVal = (divStyle || { width: "4px" }).width === "4px";

						div.style.marginRight = "50%";
						pixelMarginRightVal = (divStyle || { marginRight: "4px" }).marginRight === "4px";

						contents = div.appendChild(document.createElement("div"));

						contents.style.cssText = div.style.cssText =

						"-webkit-box-sizing:content-box;-moz-box-sizing:content-box;" + "box-sizing:content-box;display:block;margin:0;border:0;padding:0";
						contents.style.marginRight = contents.style.width = "0";
						div.style.width = "1px";

						reliableMarginRightVal = !parseFloat((window.getComputedStyle(contents) || {}).marginRight);

						div.removeChild(contents);
					}

					div.style.display = "none";
					reliableHiddenOffsetsVal = div.getClientRects().length === 0;
					if (reliableHiddenOffsetsVal) {
						div.style.display = "";
						div.innerHTML = "<table><tr><td></td><td>t</td></tr></table>";
						div.childNodes[0].style.borderCollapse = "separate";
						contents = div.getElementsByTagName("td");
						contents[0].style.cssText = "margin:0;border:0;padding:0;display:none";
						reliableHiddenOffsetsVal = contents[0].offsetHeight === 0;
						if (reliableHiddenOffsetsVal) {
							contents[0].style.display = "";
							contents[1].style.display = "none";
							reliableHiddenOffsetsVal = contents[0].offsetHeight === 0;
						}
					}

					documentElement.removeChild(container);
				}
			})();

			var getStyles,
			    curCSS,
			    rposition = /^(top|right|bottom|left)$/;

			if (window.getComputedStyle) {
				getStyles = function getStyles(elem) {

					var view = elem.ownerDocument.defaultView;

					if (!view || !view.opener) {
						view = window;
					}

					return view.getComputedStyle(elem);
				};

				curCSS = function curCSS(elem, name, computed) {
					var width,
					    minWidth,
					    maxWidth,
					    ret,
					    style = elem.style;

					computed = computed || getStyles(elem);

					ret = computed ? computed.getPropertyValue(name) || computed[name] : undefined;

					if ((ret === "" || ret === undefined) && !jQuery.contains(elem.ownerDocument, elem)) {
						ret = jQuery.style(elem, name);
					}

					if (computed) {

						if (!support.pixelMarginRight() && rnumnonpx.test(ret) && rmargin.test(name)) {

							width = style.width;
							minWidth = style.minWidth;
							maxWidth = style.maxWidth;

							style.minWidth = style.maxWidth = style.width = ret;
							ret = computed.width;

							style.width = width;
							style.minWidth = minWidth;
							style.maxWidth = maxWidth;
						}
					}

					return ret === undefined ? ret : ret + "";
				};
			} else if (documentElement.currentStyle) {
				getStyles = function getStyles(elem) {
					return elem.currentStyle;
				};

				curCSS = function curCSS(elem, name, computed) {
					var left,
					    rs,
					    rsLeft,
					    ret,
					    style = elem.style;

					computed = computed || getStyles(elem);
					ret = computed ? computed[name] : undefined;

					if (ret == null && style && style[name]) {
						ret = style[name];
					}


					if (rnumnonpx.test(ret) && !rposition.test(name)) {

						left = style.left;
						rs = elem.runtimeStyle;
						rsLeft = rs && rs.left;

						if (rsLeft) {
							rs.left = elem.currentStyle.left;
						}
						style.left = name === "fontSize" ? "1em" : ret;
						ret = style.pixelLeft + "px";

						style.left = left;
						if (rsLeft) {
							rs.left = rsLeft;
						}
					}

					return ret === undefined ? ret : ret + "" || "auto";
				};
			}

			function addGetHookIf(conditionFn, hookFn) {

				return {
					get: function get() {
						if (conditionFn()) {

							delete this.get;
							return;
						}

						return (this.get = hookFn).apply(this, arguments);
					}
				};
			}

			var ralpha = /alpha\([^)]*\)/i,
			    ropacity = /opacity\s*=\s*([^)]*)/i,


			rdisplayswap = /^(none|table(?!-c[ea]).+)/,
			    rnumsplit = new RegExp("^(" + pnum + ")(.*)$", "i"),
			    cssShow = { position: "absolute", visibility: "hidden", display: "block" },
			    cssNormalTransform = {
				letterSpacing: "0",
				fontWeight: "400"
			},
			    cssPrefixes = ["Webkit", "O", "Moz", "ms"],
			    emptyStyle = document.createElement("div").style;

			function vendorPropName(name) {

				if (name in emptyStyle) {
					return name;
				}

				var capName = name.charAt(0).toUpperCase() + name.slice(1),
				    i = cssPrefixes.length;

				while (i--) {
					name = cssPrefixes[i] + capName;
					if (name in emptyStyle) {
						return name;
					}
				}
			}

			function showHide(elements, show) {
				var display,
				    elem,
				    hidden,
				    values = [],
				    index = 0,
				    length = elements.length;

				for (; index < length; index++) {
					elem = elements[index];
					if (!elem.style) {
						continue;
					}

					values[index] = jQuery._data(elem, "olddisplay");
					display = elem.style.display;
					if (show) {

						if (!values[index] && display === "none") {
							elem.style.display = "";
						}

						if (elem.style.display === "" && isHidden(elem)) {
							values[index] = jQuery._data(elem, "olddisplay", defaultDisplay(elem.nodeName));
						}
					} else {
						hidden = isHidden(elem);

						if (display && display !== "none" || !hidden) {
							jQuery._data(elem, "olddisplay", hidden ? display : jQuery.css(elem, "display"));
						}
					}
				}

				for (index = 0; index < length; index++) {
					elem = elements[index];
					if (!elem.style) {
						continue;
					}
					if (!show || elem.style.display === "none" || elem.style.display === "") {
						elem.style.display = show ? values[index] || "" : "none";
					}
				}

				return elements;
			}

			function setPositiveNumber(elem, value, subtract) {
				var matches = rnumsplit.exec(value);
				return matches ?

				Math.max(0, matches[1] - (subtract || 0)) + (matches[2] || "px") : value;
			}

			function augmentWidthOrHeight(elem, name, extra, isBorderBox, styles) {
				var i = extra === (isBorderBox ? "border" : "content") ?

				4 :

				name === "width" ? 1 : 0,
				    val = 0;

				for (; i < 4; i += 2) {

					if (extra === "margin") {
						val += jQuery.css(elem, extra + cssExpand[i], true, styles);
					}

					if (isBorderBox) {

						if (extra === "content") {
							val -= jQuery.css(elem, "padding" + cssExpand[i], true, styles);
						}

						if (extra !== "margin") {
							val -= jQuery.css(elem, "border" + cssExpand[i] + "Width", true, styles);
						}
					} else {

						val += jQuery.css(elem, "padding" + cssExpand[i], true, styles);

						if (extra !== "padding") {
							val += jQuery.css(elem, "border" + cssExpand[i] + "Width", true, styles);
						}
					}
				}

				return val;
			}

			function getWidthOrHeight(elem, name, extra) {

				var valueIsBorderBox = true,
				    val = name === "width" ? elem.offsetWidth : elem.offsetHeight,
				    styles = getStyles(elem),
				    isBorderBox = support.boxSizing && jQuery.css(elem, "boxSizing", false, styles) === "border-box";

				if (val <= 0 || val == null) {

					val = curCSS(elem, name, styles);
					if (val < 0 || val == null) {
						val = elem.style[name];
					}

					if (rnumnonpx.test(val)) {
						return val;
					}

					valueIsBorderBox = isBorderBox && (support.boxSizingReliable() || val === elem.style[name]);

					val = parseFloat(val) || 0;
				}

				return val + augmentWidthOrHeight(elem, name, extra || (isBorderBox ? "border" : "content"), valueIsBorderBox, styles) + "px";
			}

			jQuery.extend({

				cssHooks: {
					opacity: {
						get: function get(elem, computed) {
							if (computed) {

								var ret = curCSS(elem, "opacity");
								return ret === "" ? "1" : ret;
							}
						}
					}
				},

				cssNumber: {
					"animationIterationCount": true,
					"columnCount": true,
					"fillOpacity": true,
					"flexGrow": true,
					"flexShrink": true,
					"fontWeight": true,
					"lineHeight": true,
					"opacity": true,
					"order": true,
					"orphans": true,
					"widows": true,
					"zIndex": true,
					"zoom": true
				},

				cssProps: {

					"float": support.cssFloat ? "cssFloat" : "styleFloat"
				},

				style: function style(elem, name, value, extra) {

					if (!elem || elem.nodeType === 3 || elem.nodeType === 8 || !elem.style) {
						return;
					}

					var ret,
					    type,
					    hooks,
					    origName = jQuery.camelCase(name),
					    style = elem.style;

					name = jQuery.cssProps[origName] || (jQuery.cssProps[origName] = vendorPropName(origName) || origName);

					hooks = jQuery.cssHooks[name] || jQuery.cssHooks[origName];

					if (value !== undefined) {
						type = typeof value === "undefined" ? "undefined" : _typeof(value);

						if (type === "string" && (ret = rcssNum.exec(value)) && ret[1]) {
							value = adjustCSS(elem, name, ret);

							type = "number";
						}

						if (value == null || value !== value) {
							return;
						}

						if (type === "number") {
							value += ret && ret[3] || (jQuery.cssNumber[origName] ? "" : "px");
						}

						if (!support.clearCloneStyle && value === "" && name.indexOf("background") === 0) {
							style[name] = "inherit";
						}

						if (!hooks || !("set" in hooks) || (value = hooks.set(elem, value, extra)) !== undefined) {

							try {
								style[name] = value;
							} catch (e) {}
						}
					} else {

						if (hooks && "get" in hooks && (ret = hooks.get(elem, false, extra)) !== undefined) {

							return ret;
						}

						return style[name];
					}
				},

				css: function css(elem, name, extra, styles) {
					var num,
					    val,
					    hooks,
					    origName = jQuery.camelCase(name);

					name = jQuery.cssProps[origName] || (jQuery.cssProps[origName] = vendorPropName(origName) || origName);

					hooks = jQuery.cssHooks[name] || jQuery.cssHooks[origName];

					if (hooks && "get" in hooks) {
						val = hooks.get(elem, true, extra);
					}

					if (val === undefined) {
						val = curCSS(elem, name, styles);
					}

					if (val === "normal" && name in cssNormalTransform) {
						val = cssNormalTransform[name];
					}

					if (extra === "" || extra) {
						num = parseFloat(val);
						return extra === true || isFinite(num) ? num || 0 : val;
					}
					return val;
				}
			});

			jQuery.each(["height", "width"], function (i, name) {
				jQuery.cssHooks[name] = {
					get: function get(elem, computed, extra) {
						if (computed) {

							return rdisplayswap.test(jQuery.css(elem, "display")) && elem.offsetWidth === 0 ? swap(elem, cssShow, function () {
								return getWidthOrHeight(elem, name, extra);
							}) : getWidthOrHeight(elem, name, extra);
						}
					},

					set: function set(elem, value, extra) {
						var styles = extra && getStyles(elem);
						return setPositiveNumber(elem, value, extra ? augmentWidthOrHeight(elem, name, extra, support.boxSizing && jQuery.css(elem, "boxSizing", false, styles) === "border-box", styles) : 0);
					}
				};
			});

			if (!support.opacity) {
				jQuery.cssHooks.opacity = {
					get: function get(elem, computed) {

						return ropacity.test((computed && elem.currentStyle ? elem.currentStyle.filter : elem.style.filter) || "") ? 0.01 * parseFloat(RegExp.$1) + "" : computed ? "1" : "";
					},

					set: function set(elem, value) {
						var style = elem.style,
						    currentStyle = elem.currentStyle,
						    opacity = jQuery.isNumeric(value) ? "alpha(opacity=" + value * 100 + ")" : "",
						    filter = currentStyle && currentStyle.filter || style.filter || "";

						style.zoom = 1;

						if ((value >= 1 || value === "") && jQuery.trim(filter.replace(ralpha, "")) === "" && style.removeAttribute) {

							style.removeAttribute("filter");

							if (value === "" || currentStyle && !currentStyle.filter) {
								return;
							}
						}

						style.filter = ralpha.test(filter) ? filter.replace(ralpha, opacity) : filter + " " + opacity;
					}
				};
			}

			jQuery.cssHooks.marginRight = addGetHookIf(support.reliableMarginRight, function (elem, computed) {
				if (computed) {
					return swap(elem, { "display": "inline-block" }, curCSS, [elem, "marginRight"]);
				}
			});

			jQuery.cssHooks.marginLeft = addGetHookIf(support.reliableMarginLeft, function (elem, computed) {
				if (computed) {
					return (parseFloat(curCSS(elem, "marginLeft")) || (

					jQuery.contains(elem.ownerDocument, elem) ? elem.getBoundingClientRect().left - swap(elem, { marginLeft: 0 }, function () {
						return elem.getBoundingClientRect().left;
					}) : 0)) + "px";
				}
			});

			jQuery.each({
				margin: "",
				padding: "",
				border: "Width"
			}, function (prefix, suffix) {
				jQuery.cssHooks[prefix + suffix] = {
					expand: function expand(value) {
						var i = 0,
						    expanded = {},


						parts = typeof value === "string" ? value.split(" ") : [value];

						for (; i < 4; i++) {
							expanded[prefix + cssExpand[i] + suffix] = parts[i] || parts[i - 2] || parts[0];
						}

						return expanded;
					}
				};

				if (!rmargin.test(prefix)) {
					jQuery.cssHooks[prefix + suffix].set = setPositiveNumber;
				}
			});

			jQuery.fn.extend({
				css: function css(name, value) {
					return access(this, function (elem, name, value) {
						var styles,
						    len,
						    map = {},
						    i = 0;

						if (jQuery.isArray(name)) {
							styles = getStyles(elem);
							len = name.length;

							for (; i < len; i++) {
								map[name[i]] = jQuery.css(elem, name[i], false, styles);
							}

							return map;
						}

						return value !== undefined ? jQuery.style(elem, name, value) : jQuery.css(elem, name);
					}, name, value, arguments.length > 1);
				},
				show: function show() {
					return showHide(this, true);
				},
				hide: function hide() {
					return showHide(this);
				},
				toggle: function toggle(state) {
					if (typeof state === "boolean") {
						return state ? this.show() : this.hide();
					}

					return this.each(function () {
						if (isHidden(this)) {
							jQuery(this).show();
						} else {
							jQuery(this).hide();
						}
					});
				}
			});

			function Tween(elem, options, prop, end, easing) {
				return new Tween.prototype.init(elem, options, prop, end, easing);
			}
			jQuery.Tween = Tween;

			Tween.prototype = {
				constructor: Tween,
				init: function init(elem, options, prop, end, easing, unit) {
					this.elem = elem;
					this.prop = prop;
					this.easing = easing || jQuery.easing._default;
					this.options = options;
					this.start = this.now = this.cur();
					this.end = end;
					this.unit = unit || (jQuery.cssNumber[prop] ? "" : "px");
				},
				cur: function cur() {
					var hooks = Tween.propHooks[this.prop];

					return hooks && hooks.get ? hooks.get(this) : Tween.propHooks._default.get(this);
				},
				run: function run(percent) {
					var eased,
					    hooks = Tween.propHooks[this.prop];

					if (this.options.duration) {
						this.pos = eased = jQuery.easing[this.easing](percent, this.options.duration * percent, 0, 1, this.options.duration);
					} else {
						this.pos = eased = percent;
					}
					this.now = (this.end - this.start) * eased + this.start;

					if (this.options.step) {
						this.options.step.call(this.elem, this.now, this);
					}

					if (hooks && hooks.set) {
						hooks.set(this);
					} else {
						Tween.propHooks._default.set(this);
					}
					return this;
				}
			};

			Tween.prototype.init.prototype = Tween.prototype;

			Tween.propHooks = {
				_default: {
					get: function get(tween) {
						var result;

						if (tween.elem.nodeType !== 1 || tween.elem[tween.prop] != null && tween.elem.style[tween.prop] == null) {
							return tween.elem[tween.prop];
						}

						result = jQuery.css(tween.elem, tween.prop, "");

						return !result || result === "auto" ? 0 : result;
					},
					set: function set(tween) {

						if (jQuery.fx.step[tween.prop]) {
							jQuery.fx.step[tween.prop](tween);
						} else if (tween.elem.nodeType === 1 && (tween.elem.style[jQuery.cssProps[tween.prop]] != null || jQuery.cssHooks[tween.prop])) {
							jQuery.style(tween.elem, tween.prop, tween.now + tween.unit);
						} else {
							tween.elem[tween.prop] = tween.now;
						}
					}
				}
			};


			Tween.propHooks.scrollTop = Tween.propHooks.scrollLeft = {
				set: function set(tween) {
					if (tween.elem.nodeType && tween.elem.parentNode) {
						tween.elem[tween.prop] = tween.now;
					}
				}
			};

			jQuery.easing = {
				linear: function linear(p) {
					return p;
				},
				swing: function swing(p) {
					return 0.5 - Math.cos(p * Math.PI) / 2;
				},
				_default: "swing"
			};

			jQuery.fx = Tween.prototype.init;

			jQuery.fx.step = {};

			var fxNow,
			    timerId,
			    rfxtypes = /^(?:toggle|show|hide)$/,
			    rrun = /queueHooks$/;

			function createFxNow() {
				window.setTimeout(function () {
					fxNow = undefined;
				});
				return fxNow = jQuery.now();
			}

			function genFx(type, includeWidth) {
				var which,
				    attrs = { height: type },
				    i = 0;

				includeWidth = includeWidth ? 1 : 0;
				for (; i < 4; i += 2 - includeWidth) {
					which = cssExpand[i];
					attrs["margin" + which] = attrs["padding" + which] = type;
				}

				if (includeWidth) {
					attrs.opacity = attrs.width = type;
				}

				return attrs;
			}

			function createTween(value, prop, animation) {
				var tween,
				    collection = (Animation.tweeners[prop] || []).concat(Animation.tweeners["*"]),
				    index = 0,
				    length = collection.length;
				for (; index < length; index++) {
					if (tween = collection[index].call(animation, prop, value)) {

						return tween;
					}
				}
			}

			function defaultPrefilter(elem, props, opts) {
				var prop,
				    value,
				    toggle,
				    tween,
				    hooks,
				    oldfire,
				    display,
				    checkDisplay,
				    anim = this,
				    orig = {},
				    style = elem.style,
				    hidden = elem.nodeType && isHidden(elem),
				    dataShow = jQuery._data(elem, "fxshow");

				if (!opts.queue) {
					hooks = jQuery._queueHooks(elem, "fx");
					if (hooks.unqueued == null) {
						hooks.unqueued = 0;
						oldfire = hooks.empty.fire;
						hooks.empty.fire = function () {
							if (!hooks.unqueued) {
								oldfire();
							}
						};
					}
					hooks.unqueued++;

					anim.always(function () {

						anim.always(function () {
							hooks.unqueued--;
							if (!jQuery.queue(elem, "fx").length) {
								hooks.empty.fire();
							}
						});
					});
				}

				if (elem.nodeType === 1 && ("height" in props || "width" in props)) {

					opts.overflow = [style.overflow, style.overflowX, style.overflowY];

					display = jQuery.css(elem, "display");

					checkDisplay = display === "none" ? jQuery._data(elem, "olddisplay") || defaultDisplay(elem.nodeName) : display;

					if (checkDisplay === "inline" && jQuery.css(elem, "float") === "none") {

						if (!support.inlineBlockNeedsLayout || defaultDisplay(elem.nodeName) === "inline") {
							style.display = "inline-block";
						} else {
							style.zoom = 1;
						}
					}
				}

				if (opts.overflow) {
					style.overflow = "hidden";
					if (!support.shrinkWrapBlocks()) {
						anim.always(function () {
							style.overflow = opts.overflow[0];
							style.overflowX = opts.overflow[1];
							style.overflowY = opts.overflow[2];
						});
					}
				}

				for (prop in props) {
					value = props[prop];
					if (rfxtypes.exec(value)) {
						delete props[prop];
						toggle = toggle || value === "toggle";
						if (value === (hidden ? "hide" : "show")) {

							if (value === "show" && dataShow && dataShow[prop] !== undefined) {
								hidden = true;
							} else {
								continue;
							}
						}
						orig[prop] = dataShow && dataShow[prop] || jQuery.style(elem, prop);

					} else {
						display = undefined;
					}
				}

				if (!jQuery.isEmptyObject(orig)) {
					if (dataShow) {
						if ("hidden" in dataShow) {
							hidden = dataShow.hidden;
						}
					} else {
						dataShow = jQuery._data(elem, "fxshow", {});
					}

					if (toggle) {
						dataShow.hidden = !hidden;
					}
					if (hidden) {
						jQuery(elem).show();
					} else {
						anim.done(function () {
							jQuery(elem).hide();
						});
					}
					anim.done(function () {
						var prop;
						jQuery._removeData(elem, "fxshow");
						for (prop in orig) {
							jQuery.style(elem, prop, orig[prop]);
						}
					});
					for (prop in orig) {
						tween = createTween(hidden ? dataShow[prop] : 0, prop, anim);

						if (!(prop in dataShow)) {
							dataShow[prop] = tween.start;
							if (hidden) {
								tween.end = tween.start;
								tween.start = prop === "width" || prop === "height" ? 1 : 0;
							}
						}
					}

				} else if ((display === "none" ? defaultDisplay(elem.nodeName) : display) === "inline") {
					style.display = display;
				}
			}

			function propFilter(props, specialEasing) {
				var index, name, easing, value, hooks;

				for (index in props) {
					name = jQuery.camelCase(index);
					easing = specialEasing[name];
					value = props[index];
					if (jQuery.isArray(value)) {
						easing = value[1];
						value = props[index] = value[0];
					}

					if (index !== name) {
						props[name] = value;
						delete props[index];
					}

					hooks = jQuery.cssHooks[name];
					if (hooks && "expand" in hooks) {
						value = hooks.expand(value);
						delete props[name];

						for (index in value) {
							if (!(index in props)) {
								props[index] = value[index];
								specialEasing[index] = easing;
							}
						}
					} else {
						specialEasing[name] = easing;
					}
				}
			}

			function Animation(elem, properties, options) {
				var result,
				    stopped,
				    index = 0,
				    length = Animation.prefilters.length,
				    deferred = jQuery.Deferred().always(function () {

					delete tick.elem;
				}),
				    tick = function tick() {
					if (stopped) {
						return false;
					}
					var currentTime = fxNow || createFxNow(),
					    remaining = Math.max(0, animation.startTime + animation.duration - currentTime),


					temp = remaining / animation.duration || 0,
					    percent = 1 - temp,
					    index = 0,
					    length = animation.tweens.length;

					for (; index < length; index++) {
						animation.tweens[index].run(percent);
					}

					deferred.notifyWith(elem, [animation, percent, remaining]);

					if (percent < 1 && length) {
						return remaining;
					} else {
						deferred.resolveWith(elem, [animation]);
						return false;
					}
				},
				    animation = deferred.promise({
					elem: elem,
					props: jQuery.extend({}, properties),
					opts: jQuery.extend(true, {
						specialEasing: {},
						easing: jQuery.easing._default
					}, options),
					originalProperties: properties,
					originalOptions: options,
					startTime: fxNow || createFxNow(),
					duration: options.duration,
					tweens: [],
					createTween: function createTween(prop, end) {
						var tween = jQuery.Tween(elem, animation.opts, prop, end, animation.opts.specialEasing[prop] || animation.opts.easing);
						animation.tweens.push(tween);
						return tween;
					},
					stop: function stop(gotoEnd) {
						var index = 0,


						length = gotoEnd ? animation.tweens.length : 0;
						if (stopped) {
							return this;
						}
						stopped = true;
						for (; index < length; index++) {
							animation.tweens[index].run(1);
						}

						if (gotoEnd) {
							deferred.notifyWith(elem, [animation, 1, 0]);
							deferred.resolveWith(elem, [animation, gotoEnd]);
						} else {
							deferred.rejectWith(elem, [animation, gotoEnd]);
						}
						return this;
					}
				}),
				    props = animation.props;

				propFilter(props, animation.opts.specialEasing);

				for (; index < length; index++) {
					result = Animation.prefilters[index].call(animation, elem, props, animation.opts);
					if (result) {
						if (jQuery.isFunction(result.stop)) {
							jQuery._queueHooks(animation.elem, animation.opts.queue).stop = jQuery.proxy(result.stop, result);
						}
						return result;
					}
				}

				jQuery.map(props, createTween, animation);

				if (jQuery.isFunction(animation.opts.start)) {
					animation.opts.start.call(elem, animation);
				}

				jQuery.fx.timer(jQuery.extend(tick, {
					elem: elem,
					anim: animation,
					queue: animation.opts.queue
				}));

				return animation.progress(animation.opts.progress).done(animation.opts.done, animation.opts.complete).fail(animation.opts.fail).always(animation.opts.always);
			}

			jQuery.Animation = jQuery.extend(Animation, {

				tweeners: {
					"*": [function (prop, value) {
						var tween = this.createTween(prop, value);
						adjustCSS(tween.elem, prop, rcssNum.exec(value), tween);
						return tween;
					}]
				},

				tweener: function tweener(props, callback) {
					if (jQuery.isFunction(props)) {
						callback = props;
						props = ["*"];
					} else {
						props = props.match(rnotwhite);
					}

					var prop,
					    index = 0,
					    length = props.length;

					for (; index < length; index++) {
						prop = props[index];
						Animation.tweeners[prop] = Animation.tweeners[prop] || [];
						Animation.tweeners[prop].unshift(callback);
					}
				},

				prefilters: [defaultPrefilter],

				prefilter: function prefilter(callback, prepend) {
					if (prepend) {
						Animation.prefilters.unshift(callback);
					} else {
						Animation.prefilters.push(callback);
					}
				}
			});

			jQuery.speed = function (speed, easing, fn) {
				var opt = speed && (typeof speed === "undefined" ? "undefined" : _typeof(speed)) === "object" ? jQuery.extend({}, speed) : {
					complete: fn || !fn && easing || jQuery.isFunction(speed) && speed,
					duration: speed,
					easing: fn && easing || easing && !jQuery.isFunction(easing) && easing
				};

				opt.duration = jQuery.fx.off ? 0 : typeof opt.duration === "number" ? opt.duration : opt.duration in jQuery.fx.speeds ? jQuery.fx.speeds[opt.duration] : jQuery.fx.speeds._default;

				if (opt.queue == null || opt.queue === true) {
					opt.queue = "fx";
				}

				opt.old = opt.complete;

				opt.complete = function () {
					if (jQuery.isFunction(opt.old)) {
						opt.old.call(this);
					}

					if (opt.queue) {
						jQuery.dequeue(this, opt.queue);
					}
				};

				return opt;
			};

			jQuery.fn.extend({
				fadeTo: function fadeTo(speed, to, easing, callback) {

					return this.filter(isHidden).css("opacity", 0).show()

					.end().animate({ opacity: to }, speed, easing, callback);
				},
				animate: function animate(prop, speed, easing, callback) {
					var empty = jQuery.isEmptyObject(prop),
					    optall = jQuery.speed(speed, easing, callback),
					    doAnimation = function doAnimation() {

						var anim = Animation(this, jQuery.extend({}, prop), optall);

						if (empty || jQuery._data(this, "finish")) {
							anim.stop(true);
						}
					};
					doAnimation.finish = doAnimation;

					return empty || optall.queue === false ? this.each(doAnimation) : this.queue(optall.queue, doAnimation);
				},
				stop: function stop(type, clearQueue, gotoEnd) {
					var stopQueue = function stopQueue(hooks) {
						var stop = hooks.stop;
						delete hooks.stop;
						stop(gotoEnd);
					};

					if (typeof type !== "string") {
						gotoEnd = clearQueue;
						clearQueue = type;
						type = undefined;
					}
					if (clearQueue && type !== false) {
						this.queue(type || "fx", []);
					}

					return this.each(function () {
						var dequeue = true,
						    index = type != null && type + "queueHooks",
						    timers = jQuery.timers,
						    data = jQuery._data(this);

						if (index) {
							if (data[index] && data[index].stop) {
								stopQueue(data[index]);
							}
						} else {
							for (index in data) {
								if (data[index] && data[index].stop && rrun.test(index)) {
									stopQueue(data[index]);
								}
							}
						}

						for (index = timers.length; index--;) {
							if (timers[index].elem === this && (type == null || timers[index].queue === type)) {

								timers[index].anim.stop(gotoEnd);
								dequeue = false;
								timers.splice(index, 1);
							}
						}

						if (dequeue || !gotoEnd) {
							jQuery.dequeue(this, type);
						}
					});
				},
				finish: function finish(type) {
					if (type !== false) {
						type = type || "fx";
					}
					return this.each(function () {
						var index,
						    data = jQuery._data(this),
						    queue = data[type + "queue"],
						    hooks = data[type + "queueHooks"],
						    timers = jQuery.timers,
						    length = queue ? queue.length : 0;

						data.finish = true;

						jQuery.queue(this, type, []);

						if (hooks && hooks.stop) {
							hooks.stop.call(this, true);
						}

						for (index = timers.length; index--;) {
							if (timers[index].elem === this && timers[index].queue === type) {
								timers[index].anim.stop(true);
								timers.splice(index, 1);
							}
						}

						for (index = 0; index < length; index++) {
							if (queue[index] && queue[index].finish) {
								queue[index].finish.call(this);
							}
						}

						delete data.finish;
					});
				}
			});

			jQuery.each(["toggle", "show", "hide"], function (i, name) {
				var cssFn = jQuery.fn[name];
				jQuery.fn[name] = function (speed, easing, callback) {
					return speed == null || typeof speed === "boolean" ? cssFn.apply(this, arguments) : this.animate(genFx(name, true), speed, easing, callback);
				};
			});

			jQuery.each({
				slideDown: genFx("show"),
				slideUp: genFx("hide"),
				slideToggle: genFx("toggle"),
				fadeIn: { opacity: "show" },
				fadeOut: { opacity: "hide" },
				fadeToggle: { opacity: "toggle" }
			}, function (name, props) {
				jQuery.fn[name] = function (speed, easing, callback) {
					return this.animate(props, speed, easing, callback);
				};
			});

			jQuery.timers = [];
			jQuery.fx.tick = function () {
				var timer,
				    timers = jQuery.timers,
				    i = 0;

				fxNow = jQuery.now();

				for (; i < timers.length; i++) {
					timer = timers[i];

					if (!timer() && timers[i] === timer) {
						timers.splice(i--, 1);
					}
				}

				if (!timers.length) {
					jQuery.fx.stop();
				}
				fxNow = undefined;
			};

			jQuery.fx.timer = function (timer) {
				jQuery.timers.push(timer);
				if (timer()) {
					jQuery.fx.start();
				} else {
					jQuery.timers.pop();
				}
			};

			jQuery.fx.interval = 13;

			jQuery.fx.start = function () {
				if (!timerId) {
					timerId = window.setInterval(jQuery.fx.tick, jQuery.fx.interval);
				}
			};

			jQuery.fx.stop = function () {
				window.clearInterval(timerId);
				timerId = null;
			};

			jQuery.fx.speeds = {
				slow: 600,
				fast: 200,

				_default: 400
			};

			jQuery.fn.delay = function (time, type) {
				time = jQuery.fx ? jQuery.fx.speeds[time] || time : time;
				type = type || "fx";

				return this.queue(type, function (next, hooks) {
					var timeout = window.setTimeout(next, time);
					hooks.stop = function () {
						window.clearTimeout(timeout);
					};
				});
			};

			(function () {
				var a,
				    input = document.createElement("input"),
				    div = document.createElement("div"),
				    select = document.createElement("select"),
				    opt = select.appendChild(document.createElement("option"));

				div = document.createElement("div");
				div.setAttribute("className", "t");
				div.innerHTML = "  <link/><table></table><a href='/a'>a</a><input type='checkbox'/>";
				a = div.getElementsByTagName("a")[0];

				input.setAttribute("type", "checkbox");
				div.appendChild(input);

				a = div.getElementsByTagName("a")[0];

				a.style.cssText = "top:1px";

				support.getSetAttribute = div.className !== "t";

				support.style = /top/.test(a.getAttribute("style"));

				support.hrefNormalized = a.getAttribute("href") === "/a";

				support.checkOn = !!input.value;

				support.optSelected = opt.selected;

				support.enctype = !!document.createElement("form").enctype;

				select.disabled = true;
				support.optDisabled = !opt.disabled;

				input = document.createElement("input");
				input.setAttribute("value", "");
				support.input = input.getAttribute("value") === "";

				input.value = "t";
				input.setAttribute("type", "radio");
				support.radioValue = input.value === "t";
			})();

			var rreturn = /\r/g,
			    rspaces = /[\x20\t\r\n\f]+/g;

			jQuery.fn.extend({
				val: function val(value) {
					var hooks,
					    ret,
					    isFunction,
					    elem = this[0];

					if (!arguments.length) {
						if (elem) {
							hooks = jQuery.valHooks[elem.type] || jQuery.valHooks[elem.nodeName.toLowerCase()];

							if (hooks && "get" in hooks && (ret = hooks.get(elem, "value")) !== undefined) {
								return ret;
							}

							ret = elem.value;

							return typeof ret === "string" ?

							ret.replace(rreturn, "") :

							ret == null ? "" : ret;
						}

						return;
					}

					isFunction = jQuery.isFunction(value);

					return this.each(function (i) {
						var val;

						if (this.nodeType !== 1) {
							return;
						}

						if (isFunction) {
							val = value.call(this, i, jQuery(this).val());
						} else {
							val = value;
						}

						if (val == null) {
							val = "";
						} else if (typeof val === "number") {
							val += "";
						} else if (jQuery.isArray(val)) {
							val = jQuery.map(val, function (value) {
								return value == null ? "" : value + "";
							});
						}

						hooks = jQuery.valHooks[this.type] || jQuery.valHooks[this.nodeName.toLowerCase()];

						if (!hooks || !("set" in hooks) || hooks.set(this, val, "value") === undefined) {
							this.value = val;
						}
					});
				}
			});

			jQuery.extend({
				valHooks: {
					option: {
						get: function get(elem) {
							var val = jQuery.find.attr(elem, "value");
							return val != null ? val :

							jQuery.trim(jQuery.text(elem)).replace(rspaces, " ");
						}
					},
					select: {
						get: function get(elem) {
							var value,
							    option,
							    options = elem.options,
							    index = elem.selectedIndex,
							    one = elem.type === "select-one" || index < 0,
							    values = one ? null : [],
							    max = one ? index + 1 : options.length,
							    i = index < 0 ? max : one ? index : 0;

							for (; i < max; i++) {
								option = options[i];

								if ((option.selected || i === index) && (

								support.optDisabled ? !option.disabled : option.getAttribute("disabled") === null) && (!option.parentNode.disabled || !jQuery.nodeName(option.parentNode, "optgroup"))) {

									value = jQuery(option).val();

									if (one) {
										return value;
									}

									values.push(value);
								}
							}

							return values;
						},

						set: function set(elem, value) {
							var optionSet,
							    option,
							    options = elem.options,
							    values = jQuery.makeArray(value),
							    i = options.length;

							while (i--) {
								option = options[i];

								if (jQuery.inArray(jQuery.valHooks.option.get(option), values) > -1) {

									try {
										option.selected = optionSet = true;
									} catch (_) {

										option.scrollHeight;
									}
								} else {
									option.selected = false;
								}
							}

							if (!optionSet) {
								elem.selectedIndex = -1;
							}

							return options;
						}
					}
				}
			});

			jQuery.each(["radio", "checkbox"], function () {
				jQuery.valHooks[this] = {
					set: function set(elem, value) {
						if (jQuery.isArray(value)) {
							return elem.checked = jQuery.inArray(jQuery(elem).val(), value) > -1;
						}
					}
				};
				if (!support.checkOn) {
					jQuery.valHooks[this].get = function (elem) {
						return elem.getAttribute("value") === null ? "on" : elem.value;
					};
				}
			});

			var nodeHook,
			    boolHook,
			    attrHandle = jQuery.expr.attrHandle,
			    ruseDefault = /^(?:checked|selected)$/i,
			    getSetAttribute = support.getSetAttribute,
			    getSetInput = support.input;

			jQuery.fn.extend({
				attr: function attr(name, value) {
					return access(this, jQuery.attr, name, value, arguments.length > 1);
				},

				removeAttr: function removeAttr(name) {
					return this.each(function () {
						jQuery.removeAttr(this, name);
					});
				}
			});

			jQuery.extend({
				attr: function attr(elem, name, value) {
					var ret,
					    hooks,
					    nType = elem.nodeType;

					if (nType === 3 || nType === 8 || nType === 2) {
						return;
					}

					if (typeof elem.getAttribute === "undefined") {
						return jQuery.prop(elem, name, value);
					}

					if (nType !== 1 || !jQuery.isXMLDoc(elem)) {
						name = name.toLowerCase();
						hooks = jQuery.attrHooks[name] || (jQuery.expr.match.bool.test(name) ? boolHook : nodeHook);
					}

					if (value !== undefined) {
						if (value === null) {
							jQuery.removeAttr(elem, name);
							return;
						}

						if (hooks && "set" in hooks && (ret = hooks.set(elem, value, name)) !== undefined) {
							return ret;
						}

						elem.setAttribute(name, value + "");
						return value;
					}

					if (hooks && "get" in hooks && (ret = hooks.get(elem, name)) !== null) {
						return ret;
					}

					ret = jQuery.find.attr(elem, name);

					return ret == null ? undefined : ret;
				},

				attrHooks: {
					type: {
						set: function set(elem, value) {
							if (!support.radioValue && value === "radio" && jQuery.nodeName(elem, "input")) {

								var val = elem.value;
								elem.setAttribute("type", value);
								if (val) {
									elem.value = val;
								}
								return value;
							}
						}
					}
				},

				removeAttr: function removeAttr(elem, value) {
					var name,
					    propName,
					    i = 0,
					    attrNames = value && value.match(rnotwhite);

					if (attrNames && elem.nodeType === 1) {
						while (name = attrNames[i++]) {
							propName = jQuery.propFix[name] || name;

							if (jQuery.expr.match.bool.test(name)) {

								if (getSetInput && getSetAttribute || !ruseDefault.test(name)) {
									elem[propName] = false;

								} else {
									elem[jQuery.camelCase("default-" + name)] = elem[propName] = false;
								}

							} else {
								jQuery.attr(elem, name, "");
							}

							elem.removeAttribute(getSetAttribute ? name : propName);
						}
					}
				}
			});

			boolHook = {
				set: function set(elem, value, name) {
					if (value === false) {

						jQuery.removeAttr(elem, name);
					} else if (getSetInput && getSetAttribute || !ruseDefault.test(name)) {

						elem.setAttribute(!getSetAttribute && jQuery.propFix[name] || name, name);
					} else {

						elem[jQuery.camelCase("default-" + name)] = elem[name] = true;
					}
					return name;
				}
			};

			jQuery.each(jQuery.expr.match.bool.source.match(/\w+/g), function (i, name) {
				var getter = attrHandle[name] || jQuery.find.attr;

				if (getSetInput && getSetAttribute || !ruseDefault.test(name)) {
					attrHandle[name] = function (elem, name, isXML) {
						var ret, handle;
						if (!isXML) {

							handle = attrHandle[name];
							attrHandle[name] = ret;
							ret = getter(elem, name, isXML) != null ? name.toLowerCase() : null;
							attrHandle[name] = handle;
						}
						return ret;
					};
				} else {
					attrHandle[name] = function (elem, name, isXML) {
						if (!isXML) {
							return elem[jQuery.camelCase("default-" + name)] ? name.toLowerCase() : null;
						}
					};
				}
			});

			if (!getSetInput || !getSetAttribute) {
				jQuery.attrHooks.value = {
					set: function set(elem, value, name) {
						if (jQuery.nodeName(elem, "input")) {

							elem.defaultValue = value;
						} else {

							return nodeHook && nodeHook.set(elem, value, name);
						}
					}
				};
			}

			if (!getSetAttribute) {

				nodeHook = {
					set: function set(elem, value, name) {

						var ret = elem.getAttributeNode(name);
						if (!ret) {
							elem.setAttributeNode(ret = elem.ownerDocument.createAttribute(name));
						}

						ret.value = value += "";

						if (name === "value" || value === elem.getAttribute(name)) {
							return value;
						}
					}
				};

				attrHandle.id = attrHandle.name = attrHandle.coords = function (elem, name, isXML) {
					var ret;
					if (!isXML) {
						return (ret = elem.getAttributeNode(name)) && ret.value !== "" ? ret.value : null;
					}
				};

				jQuery.valHooks.button = {
					get: function get(elem, name) {
						var ret = elem.getAttributeNode(name);
						if (ret && ret.specified) {
							return ret.value;
						}
					},
					set: nodeHook.set
				};

				jQuery.attrHooks.contenteditable = {
					set: function set(elem, value, name) {
						nodeHook.set(elem, value === "" ? false : value, name);
					}
				};

				jQuery.each(["width", "height"], function (i, name) {
					jQuery.attrHooks[name] = {
						set: function set(elem, value) {
							if (value === "") {
								elem.setAttribute(name, "auto");
								return value;
							}
						}
					};
				});
			}

			if (!support.style) {
				jQuery.attrHooks.style = {
					get: function get(elem) {

						return elem.style.cssText || undefined;
					},
					set: function set(elem, value) {
						return elem.style.cssText = value + "";
					}
				};
			}

			var rfocusable = /^(?:input|select|textarea|button|object)$/i,
			    rclickable = /^(?:a|area)$/i;

			jQuery.fn.extend({
				prop: function prop(name, value) {
					return access(this, jQuery.prop, name, value, arguments.length > 1);
				},

				removeProp: function removeProp(name) {
					name = jQuery.propFix[name] || name;
					return this.each(function () {

						try {
							this[name] = undefined;
							delete this[name];
						} catch (e) {}
					});
				}
			});

			jQuery.extend({
				prop: function prop(elem, name, value) {
					var ret,
					    hooks,
					    nType = elem.nodeType;

					if (nType === 3 || nType === 8 || nType === 2) {
						return;
					}

					if (nType !== 1 || !jQuery.isXMLDoc(elem)) {

						name = jQuery.propFix[name] || name;
						hooks = jQuery.propHooks[name];
					}

					if (value !== undefined) {
						if (hooks && "set" in hooks && (ret = hooks.set(elem, value, name)) !== undefined) {
							return ret;
						}

						return elem[name] = value;
					}

					if (hooks && "get" in hooks && (ret = hooks.get(elem, name)) !== null) {
						return ret;
					}

					return elem[name];
				},

				propHooks: {
					tabIndex: {
						get: function get(elem) {

							var tabindex = jQuery.find.attr(elem, "tabindex");

							return tabindex ? parseInt(tabindex, 10) : rfocusable.test(elem.nodeName) || rclickable.test(elem.nodeName) && elem.href ? 0 : -1;
						}
					}
				},

				propFix: {
					"for": "htmlFor",
					"class": "className"
				}
			});

			if (!support.hrefNormalized) {

				jQuery.each(["href", "src"], function (i, name) {
					jQuery.propHooks[name] = {
						get: function get(elem) {
							return elem.getAttribute(name, 4);
						}
					};
				});
			}

			if (!support.optSelected) {
				jQuery.propHooks.selected = {
					get: function get(elem) {
						var parent = elem.parentNode;

						if (parent) {
							parent.selectedIndex;

							if (parent.parentNode) {
								parent.parentNode.selectedIndex;
							}
						}
						return null;
					},
					set: function set(elem) {
						var parent = elem.parentNode;
						if (parent) {
							parent.selectedIndex;

							if (parent.parentNode) {
								parent.parentNode.selectedIndex;
							}
						}
					}
				};
			}

			jQuery.each(["tabIndex", "readOnly", "maxLength", "cellSpacing", "cellPadding", "rowSpan", "colSpan", "useMap", "frameBorder", "contentEditable"], function () {
				jQuery.propFix[this.toLowerCase()] = this;
			});

			if (!support.enctype) {
				jQuery.propFix.enctype = "encoding";
			}

			var rclass = /[\t\r\n\f]/g;

			function getClass(elem) {
				return jQuery.attr(elem, "class") || "";
			}

			jQuery.fn.extend({
				addClass: function addClass(value) {
					var classes,
					    elem,
					    cur,
					    curValue,
					    clazz,
					    j,
					    finalValue,
					    i = 0;

					if (jQuery.isFunction(value)) {
						return this.each(function (j) {
							jQuery(this).addClass(value.call(this, j, getClass(this)));
						});
					}

					if (typeof value === "string" && value) {
						classes = value.match(rnotwhite) || [];

						while (elem = this[i++]) {
							curValue = getClass(elem);
							cur = elem.nodeType === 1 && (" " + curValue + " ").replace(rclass, " ");

							if (cur) {
								j = 0;
								while (clazz = classes[j++]) {
									if (cur.indexOf(" " + clazz + " ") < 0) {
										cur += clazz + " ";
									}
								}

								finalValue = jQuery.trim(cur);
								if (curValue !== finalValue) {
									jQuery.attr(elem, "class", finalValue);
								}
							}
						}
					}

					return this;
				},

				removeClass: function removeClass(value) {
					var classes,
					    elem,
					    cur,
					    curValue,
					    clazz,
					    j,
					    finalValue,
					    i = 0;

					if (jQuery.isFunction(value)) {
						return this.each(function (j) {
							jQuery(this).removeClass(value.call(this, j, getClass(this)));
						});
					}

					if (!arguments.length) {
						return this.attr("class", "");
					}

					if (typeof value === "string" && value) {
						classes = value.match(rnotwhite) || [];

						while (elem = this[i++]) {
							curValue = getClass(elem);

							cur = elem.nodeType === 1 && (" " + curValue + " ").replace(rclass, " ");

							if (cur) {
								j = 0;
								while (clazz = classes[j++]) {

									while (cur.indexOf(" " + clazz + " ") > -1) {
										cur = cur.replace(" " + clazz + " ", " ");
									}
								}

								finalValue = jQuery.trim(cur);
								if (curValue !== finalValue) {
									jQuery.attr(elem, "class", finalValue);
								}
							}
						}
					}

					return this;
				},

				toggleClass: function toggleClass(value, stateVal) {
					var type = typeof value === "undefined" ? "undefined" : _typeof(value);

					if (typeof stateVal === "boolean" && type === "string") {
						return stateVal ? this.addClass(value) : this.removeClass(value);
					}

					if (jQuery.isFunction(value)) {
						return this.each(function (i) {
							jQuery(this).toggleClass(value.call(this, i, getClass(this), stateVal), stateVal);
						});
					}

					return this.each(function () {
						var className, i, self, classNames;

						if (type === "string") {

							i = 0;
							self = jQuery(this);
							classNames = value.match(rnotwhite) || [];

							while (className = classNames[i++]) {

								if (self.hasClass(className)) {
									self.removeClass(className);
								} else {
									self.addClass(className);
								}
							}

						} else if (value === undefined || type === "boolean") {
							className = getClass(this);
							if (className) {

								jQuery._data(this, "__className__", className);
							}

							jQuery.attr(this, "class", className || value === false ? "" : jQuery._data(this, "__className__") || "");
						}
					});
				},

				hasClass: function hasClass(selector) {
					var className,
					    elem,
					    i = 0;

					className = " " + selector + " ";
					while (elem = this[i++]) {
						if (elem.nodeType === 1 && (" " + getClass(elem) + " ").replace(rclass, " ").indexOf(className) > -1) {
							return true;
						}
					}

					return false;
				}
			});



			jQuery.each(("blur focus focusin focusout load resize scroll unload click dblclick " + "mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave " + "change select submit keydown keypress keyup error contextmenu").split(" "), function (i, name) {

				jQuery.fn[name] = function (data, fn) {
					return arguments.length > 0 ? this.on(name, null, data, fn) : this.trigger(name);
				};
			});

			jQuery.fn.extend({
				hover: function hover(fnOver, fnOut) {
					return this.mouseenter(fnOver).mouseleave(fnOut || fnOver);
				}
			});

			var location = window.location;

			var nonce = jQuery.now();

			var rquery = /\?/;

			var rvalidtokens = /(,)|(\[|{)|(}|])|"(?:[^"\\\r\n]|\\["\\\/bfnrt]|\\u[\da-fA-F]{4})*"\s*:?|true|false|null|-?(?!0\d)\d+(?:\.\d+|)(?:[eE][+-]?\d+|)/g;

			jQuery.parseJSON = function (data) {

				if (window.JSON && window.JSON.parse) {

					return window.JSON.parse(data + "");
				}

				var requireNonComma,
				    depth = null,
				    str = jQuery.trim(data + "");

				return str && !jQuery.trim(str.replace(rvalidtokens, function (token, comma, open, close) {

					if (requireNonComma && comma) {
						depth = 0;
					}

					if (depth === 0) {
						return token;
					}

					requireNonComma = open || comma;

					depth += !close - !open;

					return "";
				})) ? Function("return " + str)() : jQuery.error("Invalid JSON: " + data);
			};

			jQuery.parseXML = function (data) {
				var xml, tmp;
				if (!data || typeof data !== "string") {
					return null;
				}
				try {
					if (window.DOMParser) {
						tmp = new window.DOMParser();
						xml = tmp.parseFromString(data, "text/xml");
					} else {
						xml = new window.ActiveXObject("Microsoft.XMLDOM");
						xml.async = "false";
						xml.loadXML(data);
					}
				} catch (e) {
					xml = undefined;
				}
				if (!xml || !xml.documentElement || xml.getElementsByTagName("parsererror").length) {
					jQuery.error("Invalid XML: " + data);
				}
				return xml;
			};

			var rhash = /#.*$/,
			    rts = /([?&])_=[^&]*/,


			rheaders = /^(.*?):[ \t]*([^\r\n]*)\r?$/mg,


			rlocalProtocol = /^(?:about|app|app-storage|.+-extension|file|res|widget):$/,
			    rnoContent = /^(?:GET|HEAD)$/,
			    rprotocol = /^\/\//,
			    rurl = /^([\w.+-]+:)(?:\/\/(?:[^\/?#]*@|)([^\/?#:]*)(?::(\d+)|)|)/,


			prefilters = {},


			transports = {},


			allTypes = "*/".concat("*"),


			ajaxLocation = location.href,


			ajaxLocParts = rurl.exec(ajaxLocation.toLowerCase()) || [];

			function addToPrefiltersOrTransports(structure) {

				return function (dataTypeExpression, func) {

					if (typeof dataTypeExpression !== "string") {
						func = dataTypeExpression;
						dataTypeExpression = "*";
					}

					var dataType,
					    i = 0,
					    dataTypes = dataTypeExpression.toLowerCase().match(rnotwhite) || [];

					if (jQuery.isFunction(func)) {

						while (dataType = dataTypes[i++]) {

							if (dataType.charAt(0) === "+") {
								dataType = dataType.slice(1) || "*";
								(structure[dataType] = structure[dataType] || []).unshift(func);

							} else {
								(structure[dataType] = structure[dataType] || []).push(func);
							}
						}
					}
				};
			}

			function inspectPrefiltersOrTransports(structure, options, originalOptions, jqXHR) {

				var inspected = {},
				    seekingTransport = structure === transports;

				function inspect(dataType) {
					var selected;
					inspected[dataType] = true;
					jQuery.each(structure[dataType] || [], function (_, prefilterOrFactory) {
						var dataTypeOrTransport = prefilterOrFactory(options, originalOptions, jqXHR);
						if (typeof dataTypeOrTransport === "string" && !seekingTransport && !inspected[dataTypeOrTransport]) {

							options.dataTypes.unshift(dataTypeOrTransport);
							inspect(dataTypeOrTransport);
							return false;
						} else if (seekingTransport) {
							return !(selected = dataTypeOrTransport);
						}
					});
					return selected;
				}

				return inspect(options.dataTypes[0]) || !inspected["*"] && inspect("*");
			}

			function ajaxExtend(target, src) {
				var deep,
				    key,
				    flatOptions = jQuery.ajaxSettings.flatOptions || {};

				for (key in src) {
					if (src[key] !== undefined) {
						(flatOptions[key] ? target : deep || (deep = {}))[key] = src[key];
					}
				}
				if (deep) {
					jQuery.extend(true, target, deep);
				}

				return target;
			}

			function ajaxHandleResponses(s, jqXHR, responses) {
				var firstDataType,
				    ct,
				    finalDataType,
				    type,
				    contents = s.contents,
				    dataTypes = s.dataTypes;

				while (dataTypes[0] === "*") {
					dataTypes.shift();
					if (ct === undefined) {
						ct = s.mimeType || jqXHR.getResponseHeader("Content-Type");
					}
				}

				if (ct) {
					for (type in contents) {
						if (contents[type] && contents[type].test(ct)) {
							dataTypes.unshift(type);
							break;
						}
					}
				}

				if (dataTypes[0] in responses) {
					finalDataType = dataTypes[0];
				} else {

					for (type in responses) {
						if (!dataTypes[0] || s.converters[type + " " + dataTypes[0]]) {
							finalDataType = type;
							break;
						}
						if (!firstDataType) {
							firstDataType = type;
						}
					}

					finalDataType = finalDataType || firstDataType;
				}

				if (finalDataType) {
					if (finalDataType !== dataTypes[0]) {
						dataTypes.unshift(finalDataType);
					}
					return responses[finalDataType];
				}
			}

			function ajaxConvert(s, response, jqXHR, isSuccess) {
				var conv2,
				    current,
				    conv,
				    tmp,
				    prev,
				    converters = {},


				dataTypes = s.dataTypes.slice();

				if (dataTypes[1]) {
					for (conv in s.converters) {
						converters[conv.toLowerCase()] = s.converters[conv];
					}
				}

				current = dataTypes.shift();

				while (current) {

					if (s.responseFields[current]) {
						jqXHR[s.responseFields[current]] = response;
					}

					if (!prev && isSuccess && s.dataFilter) {
						response = s.dataFilter(response, s.dataType);
					}

					prev = current;
					current = dataTypes.shift();

					if (current) {

						if (current === "*") {

							current = prev;

						} else if (prev !== "*" && prev !== current) {

							conv = converters[prev + " " + current] || converters["* " + current];

							if (!conv) {
								for (conv2 in converters) {

									tmp = conv2.split(" ");
									if (tmp[1] === current) {

										conv = converters[prev + " " + tmp[0]] || converters["* " + tmp[0]];
										if (conv) {

											if (conv === true) {
												conv = converters[conv2];

											} else if (converters[conv2] !== true) {
												current = tmp[0];
												dataTypes.unshift(tmp[1]);
											}
											break;
										}
									}
								}
							}

							if (conv !== true) {

								if (conv && s["throws"]) {
									response = conv(response);
								} else {
									try {
										response = conv(response);
									} catch (e) {
										return {
											state: "parsererror",
											error: conv ? e : "No conversion from " + prev + " to " + current
										};
									}
								}
							}
						}
					}
				}

				return { state: "success", data: response };
			}

			jQuery.extend({

				active: 0,

				lastModified: {},
				etag: {},

				ajaxSettings: {
					url: ajaxLocation,
					type: "GET",
					isLocal: rlocalProtocol.test(ajaxLocParts[1]),
					global: true,
					processData: true,
					async: true,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",

					accepts: {
						"*": allTypes,
						text: "text/plain",
						html: "text/html",
						xml: "application/xml, text/xml",
						json: "application/json, text/javascript"
					},

					contents: {
						xml: /\bxml\b/,
						html: /\bhtml/,
						json: /\bjson\b/
					},

					responseFields: {
						xml: "responseXML",
						text: "responseText",
						json: "responseJSON"
					},

					converters: {

						"* text": String,

						"text html": true,

						"text json": jQuery.parseJSON,

						"text xml": jQuery.parseXML
					},

					flatOptions: {
						url: true,
						context: true
					}
				},

				ajaxSetup: function ajaxSetup(target, settings) {
					return settings ?

					ajaxExtend(ajaxExtend(target, jQuery.ajaxSettings), settings) :

					ajaxExtend(jQuery.ajaxSettings, target);
				},

				ajaxPrefilter: addToPrefiltersOrTransports(prefilters),
				ajaxTransport: addToPrefiltersOrTransports(transports),

				ajax: function ajax(url, options) {

					if ((typeof url === "undefined" ? "undefined" : _typeof(url)) === "object") {
						options = url;
						url = undefined;
					}

					options = options || {};

					var

					parts,


					i,


					cacheURL,


					responseHeadersString,


					timeoutTimer,


					fireGlobals,
					    transport,


					responseHeaders,


					s = jQuery.ajaxSetup({}, options),


					callbackContext = s.context || s,


					globalEventContext = s.context && (callbackContext.nodeType || callbackContext.jquery) ? jQuery(callbackContext) : jQuery.event,


					deferred = jQuery.Deferred(),
					    completeDeferred = jQuery.Callbacks("once memory"),


					_statusCode = s.statusCode || {},


					requestHeaders = {},
					    requestHeadersNames = {},


					state = 0,


					strAbort = "canceled",


					jqXHR = {
						readyState: 0,

						getResponseHeader: function getResponseHeader(key) {
							var match;
							if (state === 2) {
								if (!responseHeaders) {
									responseHeaders = {};
									while (match = rheaders.exec(responseHeadersString)) {
										responseHeaders[match[1].toLowerCase()] = match[2];
									}
								}
								match = responseHeaders[key.toLowerCase()];
							}
							return match == null ? null : match;
						},

						getAllResponseHeaders: function getAllResponseHeaders() {
							return state === 2 ? responseHeadersString : null;
						},

						setRequestHeader: function setRequestHeader(name, value) {
							var lname = name.toLowerCase();
							if (!state) {
								name = requestHeadersNames[lname] = requestHeadersNames[lname] || name;
								requestHeaders[name] = value;
							}
							return this;
						},

						overrideMimeType: function overrideMimeType(type) {
							if (!state) {
								s.mimeType = type;
							}
							return this;
						},

						statusCode: function statusCode(map) {
							var code;
							if (map) {
								if (state < 2) {
									for (code in map) {

										_statusCode[code] = [_statusCode[code], map[code]];
									}
								} else {

									jqXHR.always(map[jqXHR.status]);
								}
							}
							return this;
						},

						abort: function abort(statusText) {
							var finalText = statusText || strAbort;
							if (transport) {
								transport.abort(finalText);
							}
							done(0, finalText);
							return this;
						}
					};

					deferred.promise(jqXHR).complete = completeDeferred.add;
					jqXHR.success = jqXHR.done;
					jqXHR.error = jqXHR.fail;

					s.url = ((url || s.url || ajaxLocation) + "").replace(rhash, "").replace(rprotocol, ajaxLocParts[1] + "//");

					s.type = options.method || options.type || s.method || s.type;

					s.dataTypes = jQuery.trim(s.dataType || "*").toLowerCase().match(rnotwhite) || [""];

					if (s.crossDomain == null) {
						parts = rurl.exec(s.url.toLowerCase());
						s.crossDomain = !!(parts && (parts[1] !== ajaxLocParts[1] || parts[2] !== ajaxLocParts[2] || (parts[3] || (parts[1] === "http:" ? "80" : "443")) !== (ajaxLocParts[3] || (ajaxLocParts[1] === "http:" ? "80" : "443"))));
					}

					if (s.data && s.processData && typeof s.data !== "string") {
						s.data = jQuery.param(s.data, s.traditional);
					}

					inspectPrefiltersOrTransports(prefilters, s, options, jqXHR);

					if (state === 2) {
						return jqXHR;
					}

					fireGlobals = jQuery.event && s.global;

					if (fireGlobals && jQuery.active++ === 0) {
						jQuery.event.trigger("ajaxStart");
					}

					s.type = s.type.toUpperCase();

					s.hasContent = !rnoContent.test(s.type);

					cacheURL = s.url;

					if (!s.hasContent) {

						if (s.data) {
							cacheURL = s.url += (rquery.test(cacheURL) ? "&" : "?") + s.data;

							delete s.data;
						}

						if (s.cache === false) {
							s.url = rts.test(cacheURL) ?

							cacheURL.replace(rts, "$1_=" + nonce++) :

							cacheURL + (rquery.test(cacheURL) ? "&" : "?") + "_=" + nonce++;
						}
					}

					if (s.ifModified) {
						if (jQuery.lastModified[cacheURL]) {
							jqXHR.setRequestHeader("If-Modified-Since", jQuery.lastModified[cacheURL]);
						}
						if (jQuery.etag[cacheURL]) {
							jqXHR.setRequestHeader("If-None-Match", jQuery.etag[cacheURL]);
						}
					}

					if (s.data && s.hasContent && s.contentType !== false || options.contentType) {
						jqXHR.setRequestHeader("Content-Type", s.contentType);
					}

					jqXHR.setRequestHeader("Accept", s.dataTypes[0] && s.accepts[s.dataTypes[0]] ? s.accepts[s.dataTypes[0]] + (s.dataTypes[0] !== "*" ? ", " + allTypes + "; q=0.01" : "") : s.accepts["*"]);

					for (i in s.headers) {
						jqXHR.setRequestHeader(i, s.headers[i]);
					}

					if (s.beforeSend && (s.beforeSend.call(callbackContext, jqXHR, s) === false || state === 2)) {

						return jqXHR.abort();
					}

					strAbort = "abort";

					for (i in { success: 1, error: 1, complete: 1 }) {
						jqXHR[i](s[i]);
					}

					transport = inspectPrefiltersOrTransports(transports, s, options, jqXHR);

					if (!transport) {
						done(-1, "No Transport");
					} else {
						jqXHR.readyState = 1;

						if (fireGlobals) {
							globalEventContext.trigger("ajaxSend", [jqXHR, s]);
						}

						if (state === 2) {
							return jqXHR;
						}

						if (s.async && s.timeout > 0) {
							timeoutTimer = window.setTimeout(function () {
								jqXHR.abort("timeout");
							}, s.timeout);
						}

						try {
							state = 1;
							transport.send(requestHeaders, done);
						} catch (e) {

							if (state < 2) {
								done(-1, e);

							} else {
								throw e;
							}
						}
					}

					function done(status, nativeStatusText, responses, headers) {
						var isSuccess,
						    success,
						    error,
						    response,
						    modified,
						    statusText = nativeStatusText;

						if (state === 2) {
							return;
						}

						state = 2;

						if (timeoutTimer) {
							window.clearTimeout(timeoutTimer);
						}

						transport = undefined;

						responseHeadersString = headers || "";

						jqXHR.readyState = status > 0 ? 4 : 0;

						isSuccess = status >= 200 && status < 300 || status === 304;

						if (responses) {
							response = ajaxHandleResponses(s, jqXHR, responses);
						}

						response = ajaxConvert(s, response, jqXHR, isSuccess);

						if (isSuccess) {

							if (s.ifModified) {
								modified = jqXHR.getResponseHeader("Last-Modified");
								if (modified) {
									jQuery.lastModified[cacheURL] = modified;
								}
								modified = jqXHR.getResponseHeader("etag");
								if (modified) {
									jQuery.etag[cacheURL] = modified;
								}
							}

							if (status === 204 || s.type === "HEAD") {
								statusText = "nocontent";

							} else if (status === 304) {
								statusText = "notmodified";

							} else {
								statusText = response.state;
								success = response.data;
								error = response.error;
								isSuccess = !error;
							}
						} else {

							error = statusText;
							if (status || !statusText) {
								statusText = "error";
								if (status < 0) {
									status = 0;
								}
							}
						}

						jqXHR.status = status;
						jqXHR.statusText = (nativeStatusText || statusText) + "";

						if (isSuccess) {
							deferred.resolveWith(callbackContext, [success, statusText, jqXHR]);
						} else {
							deferred.rejectWith(callbackContext, [jqXHR, statusText, error]);
						}

						jqXHR.statusCode(_statusCode);
						_statusCode = undefined;

						if (fireGlobals) {
							globalEventContext.trigger(isSuccess ? "ajaxSuccess" : "ajaxError", [jqXHR, s, isSuccess ? success : error]);
						}

						completeDeferred.fireWith(callbackContext, [jqXHR, statusText]);

						if (fireGlobals) {
							globalEventContext.trigger("ajaxComplete", [jqXHR, s]);

							if (! --jQuery.active) {
								jQuery.event.trigger("ajaxStop");
							}
						}
					}

					return jqXHR;
				},

				getJSON: function getJSON(url, data, callback) {
					return jQuery.get(url, data, callback, "json");
				},

				getScript: function getScript(url, callback) {
					return jQuery.get(url, undefined, callback, "script");
				}
			});

			jQuery.each(["get", "post"], function (i, method) {
				jQuery[method] = function (url, data, callback, type) {

					if (jQuery.isFunction(data)) {
						type = type || callback;
						callback = data;
						data = undefined;
					}

					return jQuery.ajax(jQuery.extend({
						url: url,
						type: method,
						dataType: type,
						data: data,
						success: callback
					}, jQuery.isPlainObject(url) && url));
				};
			});

			jQuery._evalUrl = function (url) {
				return jQuery.ajax({
					url: url,

					type: "GET",
					dataType: "script",
					cache: true,
					async: false,
					global: false,
					"throws": true
				});
			};

			jQuery.fn.extend({
				wrapAll: function wrapAll(html) {
					if (jQuery.isFunction(html)) {
						return this.each(function (i) {
							jQuery(this).wrapAll(html.call(this, i));
						});
					}

					if (this[0]) {

						var wrap = jQuery(html, this[0].ownerDocument).eq(0).clone(true);

						if (this[0].parentNode) {
							wrap.insertBefore(this[0]);
						}

						wrap.map(function () {
							var elem = this;

							while (elem.firstChild && elem.firstChild.nodeType === 1) {
								elem = elem.firstChild;
							}

							return elem;
						}).append(this);
					}

					return this;
				},

				wrapInner: function wrapInner(html) {
					if (jQuery.isFunction(html)) {
						return this.each(function (i) {
							jQuery(this).wrapInner(html.call(this, i));
						});
					}

					return this.each(function () {
						var self = jQuery(this),
						    contents = self.contents();

						if (contents.length) {
							contents.wrapAll(html);
						} else {
							self.append(html);
						}
					});
				},

				wrap: function wrap(html) {
					var isFunction = jQuery.isFunction(html);

					return this.each(function (i) {
						jQuery(this).wrapAll(isFunction ? html.call(this, i) : html);
					});
				},

				unwrap: function unwrap() {
					return this.parent().each(function () {
						if (!jQuery.nodeName(this, "body")) {
							jQuery(this).replaceWith(this.childNodes);
						}
					}).end();
				}
			});

			function getDisplay(elem) {
				return elem.style && elem.style.display || jQuery.css(elem, "display");
			}

			function filterHidden(elem) {

				if (!jQuery.contains(elem.ownerDocument || document, elem)) {
					return true;
				}
				while (elem && elem.nodeType === 1) {
					if (getDisplay(elem) === "none" || elem.type === "hidden") {
						return true;
					}
					elem = elem.parentNode;
				}
				return false;
			}

			jQuery.expr.filters.hidden = function (elem) {

				return support.reliableHiddenOffsets() ? elem.offsetWidth <= 0 && elem.offsetHeight <= 0 && !elem.getClientRects().length : filterHidden(elem);
			};

			jQuery.expr.filters.visible = function (elem) {
				return !jQuery.expr.filters.hidden(elem);
			};

			var r20 = /%20/g,
			    rbracket = /\[\]$/,
			    rCRLF = /\r?\n/g,
			    rsubmitterTypes = /^(?:submit|button|image|reset|file)$/i,
			    rsubmittable = /^(?:input|select|textarea|keygen)/i;

			function buildParams(prefix, obj, traditional, add) {
				var name;

				if (jQuery.isArray(obj)) {

					jQuery.each(obj, function (i, v) {
						if (traditional || rbracket.test(prefix)) {

							add(prefix, v);
						} else {

							buildParams(prefix + "[" + ((typeof v === "undefined" ? "undefined" : _typeof(v)) === "object" && v != null ? i : "") + "]", v, traditional, add);
						}
					});
				} else if (!traditional && jQuery.type(obj) === "object") {

					for (name in obj) {
						buildParams(prefix + "[" + name + "]", obj[name], traditional, add);
					}
				} else {

					add(prefix, obj);
				}
			}

			jQuery.param = function (a, traditional) {
				var prefix,
				    s = [],
				    add = function add(key, value) {

					value = jQuery.isFunction(value) ? value() : value == null ? "" : value;
					s[s.length] = encodeURIComponent(key) + "=" + encodeURIComponent(value);
				};

				if (traditional === undefined) {
					traditional = jQuery.ajaxSettings && jQuery.ajaxSettings.traditional;
				}

				if (jQuery.isArray(a) || a.jquery && !jQuery.isPlainObject(a)) {

					jQuery.each(a, function () {
						add(this.name, this.value);
					});
				} else {

					for (prefix in a) {
						buildParams(prefix, a[prefix], traditional, add);
					}
				}

				return s.join("&").replace(r20, "+");
			};

			jQuery.fn.extend({
				serialize: function serialize() {
					return jQuery.param(this.serializeArray());
				},
				serializeArray: function serializeArray() {
					return this.map(function () {

						var elements = jQuery.prop(this, "elements");
						return elements ? jQuery.makeArray(elements) : this;
					}).filter(function () {
						var type = this.type;

						return this.name && !jQuery(this).is(":disabled") && rsubmittable.test(this.nodeName) && !rsubmitterTypes.test(type) && (this.checked || !rcheckableType.test(type));
					}).map(function (i, elem) {
						var val = jQuery(this).val();

						return val == null ? null : jQuery.isArray(val) ? jQuery.map(val, function (val) {
							return { name: elem.name, value: val.replace(rCRLF, "\r\n") };
						}) : { name: elem.name, value: val.replace(rCRLF, "\r\n") };
					}).get();
				}
			});

			jQuery.ajaxSettings.xhr = window.ActiveXObject !== undefined ?

			function () {

				if (this.isLocal) {
					return createActiveXHR();
				}

				if (document.documentMode > 8) {
					return createStandardXHR();
				}

				return (/^(get|post|head|put|delete|options)$/i.test(this.type) && createStandardXHR() || createActiveXHR()
				);
			} :

			createStandardXHR;

			var xhrId = 0,
			    xhrCallbacks = {},
			    xhrSupported = jQuery.ajaxSettings.xhr();

			if (window.attachEvent) {
				window.attachEvent("onunload", function () {
					for (var key in xhrCallbacks) {
						xhrCallbacks[key](undefined, true);
					}
				});
			}

			support.cors = !!xhrSupported && "withCredentials" in xhrSupported;
			xhrSupported = support.ajax = !!xhrSupported;

			if (xhrSupported) {

				jQuery.ajaxTransport(function (options) {

					if (!options.crossDomain || support.cors) {

						var _callback;

						return {
							send: function send(headers, complete) {
								var i,
								    xhr = options.xhr(),
								    id = ++xhrId;

								xhr.open(options.type, options.url, options.async, options.username, options.password);

								if (options.xhrFields) {
									for (i in options.xhrFields) {
										xhr[i] = options.xhrFields[i];
									}
								}

								if (options.mimeType && xhr.overrideMimeType) {
									xhr.overrideMimeType(options.mimeType);
								}

								if (!options.crossDomain && !headers["X-Requested-With"]) {
									headers["X-Requested-With"] = "XMLHttpRequest";
								}

								for (i in headers) {

									if (headers[i] !== undefined) {
										xhr.setRequestHeader(i, headers[i] + "");
									}
								}

								xhr.send(options.hasContent && options.data || null);

								_callback = function callback(_, isAbort) {
									var status, statusText, responses;

									if (_callback && (isAbort || xhr.readyState === 4)) {

										delete xhrCallbacks[id];
										_callback = undefined;
										xhr.onreadystatechange = jQuery.noop;

										if (isAbort) {
											if (xhr.readyState !== 4) {
												xhr.abort();
											}
										} else {
											responses = {};
											status = xhr.status;

											if (typeof xhr.responseText === "string") {
												responses.text = xhr.responseText;
											}

											try {
												statusText = xhr.statusText;
											} catch (e) {

												statusText = "";
											}


											if (!status && options.isLocal && !options.crossDomain) {
												status = responses.text ? 200 : 404;

											} else if (status === 1223) {
												status = 204;
											}
										}
									}

									if (responses) {
										complete(status, statusText, responses, xhr.getAllResponseHeaders());
									}
								};

								if (!options.async) {

									_callback();
								} else if (xhr.readyState === 4) {

									window.setTimeout(_callback);
								} else {

									xhr.onreadystatechange = xhrCallbacks[id] = _callback;
								}
							},

							abort: function abort() {
								if (_callback) {
									_callback(undefined, true);
								}
							}
						};
					}
				});
			}

			function createStandardXHR() {
				try {
					return new window.XMLHttpRequest();
				} catch (e) {}
			}

			function createActiveXHR() {
				try {
					return new window.ActiveXObject("Microsoft.XMLHTTP");
				} catch (e) {}
			}

			jQuery.ajaxSetup({
				accepts: {
					script: "text/javascript, application/javascript, " + "application/ecmascript, application/x-ecmascript"
				},
				contents: {
					script: /\b(?:java|ecma)script\b/
				},
				converters: {
					"text script": function textScript(text) {
						jQuery.globalEval(text);
						return text;
					}
				}
			});

			jQuery.ajaxPrefilter("script", function (s) {
				if (s.cache === undefined) {
					s.cache = false;
				}
				if (s.crossDomain) {
					s.type = "GET";
					s.global = false;
				}
			});

			jQuery.ajaxTransport("script", function (s) {

				if (s.crossDomain) {

					var script,
					    head = document.head || jQuery("head")[0] || document.documentElement;

					return {

						send: function send(_, callback) {

							script = document.createElement("script");

							script.async = true;

							if (s.scriptCharset) {
								script.charset = s.scriptCharset;
							}

							script.src = s.url;

							script.onload = script.onreadystatechange = function (_, isAbort) {

								if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {

									script.onload = script.onreadystatechange = null;

									if (script.parentNode) {
										script.parentNode.removeChild(script);
									}

									script = null;

									if (!isAbort) {
										callback(200, "success");
									}
								}
							};

							head.insertBefore(script, head.firstChild);
						},

						abort: function abort() {
							if (script) {
								script.onload(undefined, true);
							}
						}
					};
				}
			});

			var oldCallbacks = [],
			    rjsonp = /(=)\?(?=&|$)|\?\?/;

			jQuery.ajaxSetup({
				jsonp: "callback",
				jsonpCallback: function jsonpCallback() {
					var callback = oldCallbacks.pop() || jQuery.expando + "_" + nonce++;
					this[callback] = true;
					return callback;
				}
			});

			jQuery.ajaxPrefilter("json jsonp", function (s, originalSettings, jqXHR) {

				var callbackName,
				    overwritten,
				    responseContainer,
				    jsonProp = s.jsonp !== false && (rjsonp.test(s.url) ? "url" : typeof s.data === "string" && (s.contentType || "").indexOf("application/x-www-form-urlencoded") === 0 && rjsonp.test(s.data) && "data");

				if (jsonProp || s.dataTypes[0] === "jsonp") {

					callbackName = s.jsonpCallback = jQuery.isFunction(s.jsonpCallback) ? s.jsonpCallback() : s.jsonpCallback;

					if (jsonProp) {
						s[jsonProp] = s[jsonProp].replace(rjsonp, "$1" + callbackName);
					} else if (s.jsonp !== false) {
						s.url += (rquery.test(s.url) ? "&" : "?") + s.jsonp + "=" + callbackName;
					}

					s.converters["script json"] = function () {
						if (!responseContainer) {
							jQuery.error(callbackName + " was not called");
						}
						return responseContainer[0];
					};

					s.dataTypes[0] = "json";

					overwritten = window[callbackName];
					window[callbackName] = function () {
						responseContainer = arguments;
					};

					jqXHR.always(function () {

						if (overwritten === undefined) {
							jQuery(window).removeProp(callbackName);

						} else {
							window[callbackName] = overwritten;
						}

						if (s[callbackName]) {

							s.jsonpCallback = originalSettings.jsonpCallback;

							oldCallbacks.push(callbackName);
						}

						if (responseContainer && jQuery.isFunction(overwritten)) {
							overwritten(responseContainer[0]);
						}

						responseContainer = overwritten = undefined;
					});

					return "script";
				}
			});

			jQuery.parseHTML = function (data, context, keepScripts) {
				if (!data || typeof data !== "string") {
					return null;
				}
				if (typeof context === "boolean") {
					keepScripts = context;
					context = false;
				}
				context = context || document;

				var parsed = rsingleTag.exec(data),
				    scripts = !keepScripts && [];

				if (parsed) {
					return [context.createElement(parsed[1])];
				}

				parsed = buildFragment([data], context, scripts);

				if (scripts && scripts.length) {
					jQuery(scripts).remove();
				}

				return jQuery.merge([], parsed.childNodes);
			};

			var _load = jQuery.fn.load;

			/**
    * Load a url into a page
    */
			jQuery.fn.load = function (url, params, callback) {
				if (typeof url !== "string" && _load) {
					return _load.apply(this, arguments);
				}

				var selector,
				    type,
				    response,
				    self = this,
				    off = url.indexOf(" ");

				if (off > -1) {
					selector = jQuery.trim(url.slice(off, url.length));
					url = url.slice(0, off);
				}

				if (jQuery.isFunction(params)) {

					callback = params;
					params = undefined;

				} else if (params && (typeof params === "undefined" ? "undefined" : _typeof(params)) === "object") {
					type = "POST";
				}

				if (self.length > 0) {
					jQuery.ajax({
						url: url,

						type: type || "GET",
						dataType: "html",
						data: params
					}).done(function (responseText) {

						response = arguments;

						self.html(selector ?

						jQuery("<div>").append(jQuery.parseHTML(responseText)).find(selector) :

						responseText);

					}).always(callback && function (jqXHR, status) {
						self.each(function () {
							callback.apply(this, response || [jqXHR.responseText, status, jqXHR]);
						});
					});
				}

				return this;
			};

			jQuery.each(["ajaxStart", "ajaxStop", "ajaxComplete", "ajaxError", "ajaxSuccess", "ajaxSend"], function (i, type) {
				jQuery.fn[type] = function (fn) {
					return this.on(type, fn);
				};
			});

			jQuery.expr.filters.animated = function (elem) {
				return jQuery.grep(jQuery.timers, function (fn) {
					return elem === fn.elem;
				}).length;
			};

			/**
    * Gets a window from an element
    */
			function getWindow(elem) {
				return jQuery.isWindow(elem) ? elem : elem.nodeType === 9 ? elem.defaultView || elem.parentWindow : false;
			}

			jQuery.offset = {
				setOffset: function setOffset(elem, options, i) {
					var curPosition,
					    curLeft,
					    curCSSTop,
					    curTop,
					    curOffset,
					    curCSSLeft,
					    calculatePosition,
					    position = jQuery.css(elem, "position"),
					    curElem = jQuery(elem),
					    props = {};

					if (position === "static") {
						elem.style.position = "relative";
					}

					curOffset = curElem.offset();
					curCSSTop = jQuery.css(elem, "top");
					curCSSLeft = jQuery.css(elem, "left");
					calculatePosition = (position === "absolute" || position === "fixed") && jQuery.inArray("auto", [curCSSTop, curCSSLeft]) > -1;

					if (calculatePosition) {
						curPosition = curElem.position();
						curTop = curPosition.top;
						curLeft = curPosition.left;
					} else {
						curTop = parseFloat(curCSSTop) || 0;
						curLeft = parseFloat(curCSSLeft) || 0;
					}

					if (jQuery.isFunction(options)) {

						options = options.call(elem, i, jQuery.extend({}, curOffset));
					}

					if (options.top != null) {
						props.top = options.top - curOffset.top + curTop;
					}
					if (options.left != null) {
						props.left = options.left - curOffset.left + curLeft;
					}

					if ("using" in options) {
						options.using.call(elem, props);
					} else {
						curElem.css(props);
					}
				}
			};

			jQuery.fn.extend({
				offset: function offset(options) {
					if (arguments.length) {
						return options === undefined ? this : this.each(function (i) {
							jQuery.offset.setOffset(this, options, i);
						});
					}

					var docElem,
					    win,
					    box = { top: 0, left: 0 },
					    elem = this[0],
					    doc = elem && elem.ownerDocument;

					if (!doc) {
						return;
					}

					docElem = doc.documentElement;

					if (!jQuery.contains(docElem, elem)) {
						return box;
					}

					if (typeof elem.getBoundingClientRect !== "undefined") {
						box = elem.getBoundingClientRect();
					}
					win = getWindow(doc);
					return {
						top: box.top + (win.pageYOffset || docElem.scrollTop) - (docElem.clientTop || 0),
						left: box.left + (win.pageXOffset || docElem.scrollLeft) - (docElem.clientLeft || 0)
					};
				},

				position: function position() {
					if (!this[0]) {
						return;
					}

					var offsetParent,
					    offset,
					    parentOffset = { top: 0, left: 0 },
					    elem = this[0];

					if (jQuery.css(elem, "position") === "fixed") {

						offset = elem.getBoundingClientRect();
					} else {

						offsetParent = this.offsetParent();

						offset = this.offset();
						if (!jQuery.nodeName(offsetParent[0], "html")) {
							parentOffset = offsetParent.offset();
						}

						parentOffset.top += jQuery.css(offsetParent[0], "borderTopWidth", true);
						parentOffset.left += jQuery.css(offsetParent[0], "borderLeftWidth", true);
					}

					return {
						top: offset.top - parentOffset.top - jQuery.css(elem, "marginTop", true),
						left: offset.left - parentOffset.left - jQuery.css(elem, "marginLeft", true)
					};
				},

				offsetParent: function offsetParent() {
					return this.map(function () {
						var offsetParent = this.offsetParent;

						while (offsetParent && !jQuery.nodeName(offsetParent, "html") && jQuery.css(offsetParent, "position") === "static") {
							offsetParent = offsetParent.offsetParent;
						}
						return offsetParent || documentElement;
					});
				}
			});

			jQuery.each({ scrollLeft: "pageXOffset", scrollTop: "pageYOffset" }, function (method, prop) {
				var top = /Y/.test(prop);

				jQuery.fn[method] = function (val) {
					return access(this, function (elem, method, val) {
						var win = getWindow(elem);

						if (val === undefined) {
							return win ? prop in win ? win[prop] : win.document.documentElement[method] : elem[method];
						}

						if (win) {
							win.scrollTo(!top ? val : jQuery(win).scrollLeft(), top ? val : jQuery(win).scrollTop());
						} else {
							elem[method] = val;
						}
					}, method, val, arguments.length, null);
				};
			});

			jQuery.each(["top", "left"], function (i, prop) {
				jQuery.cssHooks[prop] = addGetHookIf(support.pixelPosition, function (elem, computed) {
					if (computed) {
						computed = curCSS(elem, prop);

						return rnumnonpx.test(computed) ? jQuery(elem).position()[prop] + "px" : computed;
					}
				});
			});

			jQuery.each({ Height: "height", Width: "width" }, function (name, type) {
				jQuery.each({ padding: "inner" + name, content: type, "": "outer" + name }, function (defaultExtra, funcName) {

					jQuery.fn[funcName] = function (margin, value) {
						var chainable = arguments.length && (defaultExtra || typeof margin !== "boolean"),
						    extra = defaultExtra || (margin === true || value === true ? "margin" : "border");

						return access(this, function (elem, type, value) {
							var doc;

							if (jQuery.isWindow(elem)) {

								return elem.document.documentElement["client" + name];
							}

							if (elem.nodeType === 9) {
								doc = elem.documentElement;

								return Math.max(elem.body["scroll" + name], doc["scroll" + name], elem.body["offset" + name], doc["offset" + name], doc["client" + name]);
							}

							return value === undefined ?

							jQuery.css(elem, type, extra) :

							jQuery.style(elem, type, value, extra);
						}, type, chainable ? margin : undefined, chainable, null);
					};
				});
			});

			jQuery.fn.extend({

				bind: function bind(types, data, fn) {
					return this.on(types, null, data, fn);
				},
				unbind: function unbind(types, fn) {
					return this.off(types, null, fn);
				},

				delegate: function delegate(selector, types, data, fn) {
					return this.on(types, selector, data, fn);
				},
				undelegate: function undelegate(selector, types, fn) {

					return arguments.length === 1 ? this.off(selector, "**") : this.off(types, selector || "**", fn);
				}
			});

			jQuery.fn.size = function () {
				return this.length;
			};

			jQuery.fn.andSelf = jQuery.fn.addBack;



			if (typeof define === "function" && define.amd) {
				define("jquery", [], function () {
					return jQuery;
				});
			}

			var

			_jQuery = window.jQuery,


			_$ = window.$;

			jQuery.noConflict = function (deep) {
				if (window.$ === jQuery) {
					window.$ = _$;
				}

				if (deep && window.jQuery === jQuery) {
					window.jQuery = _jQuery;
				}

				return jQuery;
			};

			if (!noGlobal) {
				window.jQuery = window.$ = jQuery;
			}

			return jQuery;
		});
	}, {}], 5: [function (require, module, exports) {
		(function ($, undefined) {
			'use strict';

			var defaults = {
				item: 3,
				autoWidth: false,
				slideMove: 1,
				slideMargin: 10,
				addClass: '',
				mode: 'slide',
				useCSS: true,
				cssEasing: 'ease', 
				easing: 'linear', 
				speed: 400, 
				auto: false,
				pauseOnHover: false,
				loop: false,
				slideEndAnimation: true,
				pause: 2000,
				keyPress: false,
				controls: true,
				prevHtml: '',
				nextHtml: '',
				rtl: false,
				adaptiveHeight: false,
				vertical: false,
				verticalHeight: 500,
				vThumbWidth: 100,
				thumbItem: 10,
				pager: true,
				gallery: false,
				galleryMargin: 5,
				thumbMargin: 5,
				currentPagerPosition: 'middle',
				enableTouch: true,
				enableDrag: true,
				freeMove: true,
				swipeThreshold: 40,
				responsive: [],
				onBeforeStart: function onBeforeStart($el) {},
				onSliderLoad: function onSliderLoad($el) {},
				onBeforeSlide: function onBeforeSlide($el, scene) {},
				onAfterSlide: function onAfterSlide($el, scene) {},
				onBeforeNextSlide: function onBeforeNextSlide($el, scene) {},
				onBeforePrevSlide: function onBeforePrevSlide($el, scene) {}
			};
			$.fn.lightSlider = function (options) {
				if (this.length === 0) {
					return this;
				}

				if (this.length > 1) {
					this.each(function () {
						$(this).lightSlider(options);
					});
					return this;
				}

				var plugin = {},
				    settings = $.extend(true, {}, defaults, options),
				    settingsTemp = {},
				    $el = this;
				plugin.$el = this;

				if (settings.mode === 'fade') {
					settings.vertical = false;
				}
				var $children = $el.children(),
				    windowW = $(window).width(),
				    breakpoint = null,
				    resposiveObj = null,
				    length = 0,
				    w = 0,
				    on = false,
				    elSize = 0,
				    $slide = '',
				    scene = 0,
				    property = settings.vertical === true ? 'height' : 'width',
				    gutter = settings.vertical === true ? 'margin-bottom' : 'margin-right',
				    slideValue = 0,
				    pagerWidth = 0,
				    slideWidth = 0,
				    thumbWidth = 0,
				    interval = null,
				    isTouch = 'ontouchstart' in document.documentElement;
				var refresh = {};

				refresh.chbreakpoint = function () {
					windowW = $(window).width();
					if (settings.responsive.length) {
						var item;
						if (settings.autoWidth === false) {
							item = settings.item;
						}
						if (windowW < settings.responsive[0].breakpoint) {
							for (var i = 0; i < settings.responsive.length; i++) {
								if (windowW < settings.responsive[i].breakpoint) {
									breakpoint = settings.responsive[i].breakpoint;
									resposiveObj = settings.responsive[i];
								}
							}
						}
						if (typeof resposiveObj !== 'undefined' && resposiveObj !== null) {
							for (var j in resposiveObj.settings) {
								if (resposiveObj.settings.hasOwnProperty(j)) {
									if (typeof settingsTemp[j] === 'undefined' || settingsTemp[j] === null) {
										settingsTemp[j] = settings[j];
									}
									settings[j] = resposiveObj.settings[j];
								}
							}
						}
						if (!$.isEmptyObject(settingsTemp) && windowW > settings.responsive[0].breakpoint) {
							for (var k in settingsTemp) {
								if (settingsTemp.hasOwnProperty(k)) {
									settings[k] = settingsTemp[k];
								}
							}
						}
						if (settings.autoWidth === false) {
							if (slideValue > 0 && slideWidth > 0) {
								if (item !== settings.item) {
									scene = Math.round(slideValue / ((slideWidth + settings.slideMargin) * settings.slideMove));
								}
							}
						}
					}
				};

				refresh.calSW = function () {
					if (settings.autoWidth === false) {
						slideWidth = (elSize - (settings.item * settings.slideMargin - settings.slideMargin)) / settings.item;
					}
				};

				refresh.calWidth = function (cln) {
					var ln = cln === true ? $slide.find('.lslide').length : $children.length;
					if (settings.autoWidth === false) {
						w = ln * (slideWidth + settings.slideMargin);
					} else {
						w = 0;
						for (var i = 0; i < ln; i++) {
							w += parseInt($children.eq(i).width()) + settings.slideMargin;
						}
					}
					return w;
				};
				plugin = {
					doCss: function doCss() {
						var support = function support() {
							var transition = ['transition', 'MozTransition', 'WebkitTransition', 'OTransition', 'msTransition', 'KhtmlTransition'];
							var root = document.documentElement;
							for (var i = 0; i < transition.length; i++) {
								if (transition[i] in root.style) {
									return true;
								}
							}
						};
						if (settings.useCSS && support()) {
							return true;
						}
						return false;
					},
					keyPress: function keyPress() {
						if (settings.keyPress) {
							$(document).on('keyup.lightslider', function (e) {
								if (!$(':focus').is('input, textarea')) {
									if (e.preventDefault) {
										e.preventDefault();
									} else {
										e.returnValue = false;
									}
									if (e.keyCode === 37) {
										$el.goToPrevSlide();
									} else if (e.keyCode === 39) {
										$el.goToNextSlide();
									}
								}
							});
						}
					},
					controls: function controls() {
						if (settings.controls) {
							$el.after('<div class="lSAction"><a class="lSPrev">' + settings.prevHtml + '</a><a class="lSNext">' + settings.nextHtml + '</a></div>');
							if (!settings.autoWidth) {
								if (length <= settings.item) {
									$slide.find('.lSAction').hide();
								}
							} else {
								if (refresh.calWidth(false) < elSize) {
									$slide.find('.lSAction').hide();
								}
							}
							$slide.find('.lSAction a').on('click', function (e) {
								if (e.preventDefault) {
									e.preventDefault();
								} else {
									e.returnValue = false;
								}
								if ($(this).attr('class') === 'lSPrev') {
									$el.goToPrevSlide();
								} else {
									$el.goToNextSlide();
								}
								return false;
							});
						}
					},
					initialStyle: function initialStyle() {
						var $this = this;
						if (settings.mode === 'fade') {
							settings.autoWidth = false;
							settings.slideEndAnimation = false;
						}
						if (settings.auto) {
							settings.slideEndAnimation = false;
						}
						if (settings.autoWidth) {
							settings.slideMove = 1;
							settings.item = 1;
						}
						if (settings.loop) {
							settings.slideMove = 1;
							settings.freeMove = false;
						}
						settings.onBeforeStart.call(this, $el);
						refresh.chbreakpoint();
						$el.addClass('lightSlider').wrap('<div class="lSSlideOuter ' + settings.addClass + '"><div class="lSSlideWrapper"></div></div>');
						$slide = $el.parent('.lSSlideWrapper');
						if (settings.rtl === true) {
							$slide.parent().addClass('lSrtl');
						}
						if (settings.vertical) {
							$slide.parent().addClass('vertical');
							elSize = settings.verticalHeight;
							$slide.css('height', elSize + 'px');
						} else {
							elSize = $el.outerWidth();
						}
						$children.addClass('lslide');
						if (settings.loop === true && settings.mode === 'slide') {
							refresh.calSW();
							refresh.clone = function () {
								if (refresh.calWidth(true) > elSize) {
									var tWr = 0,
									    tI = 0;
									for (var k = 0; k < $children.length; k++) {
										tWr += parseInt($el.find('.lslide').eq(k).width()) + settings.slideMargin;
										tI++;
										if (tWr >= elSize + settings.slideMargin) {
											break;
										}
									}
									var tItem = settings.autoWidth === true ? tI : settings.item;

									if (tItem < $el.find('.clone.left').length) {
										for (var i = 0; i < $el.find('.clone.left').length - tItem; i++) {
											$children.eq(i).remove();
										}
									}
									if (tItem < $el.find('.clone.right').length) {
										for (var j = $children.length - 1; j > $children.length - 1 - $el.find('.clone.right').length; j--) {
											scene--;
											$children.eq(j).remove();
										}
									}
									for (var n = $el.find('.clone.right').length; n < tItem; n++) {
										$el.find('.lslide').eq(n).clone().removeClass('lslide').addClass('clone right').appendTo($el);
										scene++;
									}
									for (var m = $el.find('.lslide').length - $el.find('.clone.left').length; m > $el.find('.lslide').length - tItem; m--) {
										$el.find('.lslide').eq(m - 1).clone().removeClass('lslide').addClass('clone left').prependTo($el);
									}
									$children = $el.children();
								} else {
									if ($children.hasClass('clone')) {
										$el.find('.clone').remove();
										$this.move($el, 0);
									}
								}
							};
							refresh.clone();
						}
						refresh.sSW = function () {
							length = $children.length;
							if (settings.rtl === true && settings.vertical === false) {
								gutter = 'margin-left';
							}
							if (settings.autoWidth === false) {
								$children.css(property, slideWidth + 'px');
							}
							$children.css(gutter, settings.slideMargin + 'px');
							w = refresh.calWidth(false);
							$el.css(property, w + 'px');
							if (settings.loop === true && settings.mode === 'slide') {
								if (on === false) {
									scene = $el.find('.clone.left').length;
								}
							}
						};
						refresh.calL = function () {
							$children = $el.children();
							length = $children.length;
						};
						if (this.doCss()) {
							$slide.addClass('usingCss');
						}
						refresh.calL();
						if (settings.mode === 'slide') {
							refresh.calSW();
							refresh.sSW();
							if (settings.loop === true) {
								slideValue = $this.slideValue();
								this.move($el, slideValue);
							}
							if (settings.vertical === false) {
								this.setHeight($el, false);
							}
						} else {
							this.setHeight($el, true);
							$el.addClass('lSFade');
							if (!this.doCss()) {
								$children.fadeOut(0);
								$children.eq(scene).fadeIn(0);
							}
						}
						if (settings.loop === true && settings.mode === 'slide') {
							$children.eq(scene).addClass('active');
						} else {
							$children.first().addClass('active');
						}
					},
					pager: function pager() {
						var $this = this;
						refresh.createPager = function () {
							thumbWidth = (elSize - (settings.thumbItem * settings.thumbMargin - settings.thumbMargin)) / settings.thumbItem;
							var $children = $slide.find('.lslide');
							var length = $slide.find('.lslide').length;
							var i = 0,
							    pagers = '',
							    v = 0;
							for (i = 0; i < length; i++) {
								if (settings.mode === 'slide') {
									if (!settings.autoWidth) {
										v = i * ((slideWidth + settings.slideMargin) * settings.slideMove);
									} else {
										v += (parseInt($children.eq(i).width()) + settings.slideMargin) * settings.slideMove;
									}
								}
								var thumb = $children.eq(i * settings.slideMove).attr('data-thumb');
								if (settings.gallery === true) {
									pagers += '<li style="width:100%;' + property + ':' + thumbWidth + 'px;' + gutter + ':' + settings.thumbMargin + 'px"><a href="#"><img src="' + thumb + '" /></a></li>';
								} else {
									pagers += '<li><a href="#">' + (i + 1) + '</a></li>';
								}
								if (settings.mode === 'slide') {
									if (v >= w - elSize - settings.slideMargin) {
										i = i + 1;
										var minPgr = 2;
										if (settings.autoWidth) {
											pagers += '<li><a href="#">' + (i + 1) + '</a></li>';
											minPgr = 1;
										}
										if (i < minPgr) {
											pagers = null;
											$slide.parent().addClass('noPager');
										} else {
											$slide.parent().removeClass('noPager');
										}
										break;
									}
								}
							}
							var $cSouter = $slide.parent();
							$cSouter.find('.lSPager').html(pagers);
							if (settings.gallery === true) {
								if (settings.vertical === true) {
									$cSouter.find('.lSPager').css('width', settings.vThumbWidth + 'px');
								}
								pagerWidth = i * (settings.thumbMargin + thumbWidth) + 0.5;
								$cSouter.find('.lSPager').css({
									property: pagerWidth + 'px',
									'transition-duration': settings.speed + 'ms'
								});
								if (settings.vertical === true) {
									$slide.parent().css('padding-right', settings.vThumbWidth + settings.galleryMargin + 'px');
								}
								$cSouter.find('.lSPager').css(property, pagerWidth + 'px');
							}
							var $pager = $cSouter.find('.lSPager').find('li');
							$pager.first().addClass('active');
							$pager.on('click', function () {
								if (settings.loop === true && settings.mode === 'slide') {
									scene = scene + ($pager.index(this) - $cSouter.find('.lSPager').find('li.active').index());
								} else {
									scene = $pager.index(this);
								}
								$el.mode(false);
								if (settings.gallery === true) {
									$this.slideThumb();
								}
								return false;
							});
						};
						if (settings.pager) {
							var cl = 'lSpg';
							if (settings.gallery) {
								cl = 'lSGallery';
							}
							$slide.after('<ul class="lSPager ' + cl + '"></ul>');
							var gMargin = settings.vertical ? 'margin-left' : 'margin-top';
							$slide.parent().find('.lSPager').css(gMargin, settings.galleryMargin + 'px');
							refresh.createPager();
						}

						setTimeout(function () {
							refresh.init();
						}, 0);
					},
					setHeight: function setHeight(ob, fade) {
						var obj = null,
						    $this = this;
						if (settings.loop) {
							obj = ob.children('.lslide ').first();
						} else {
							obj = ob.children().first();
						}
						var setCss = function setCss() {
							var tH = obj.outerHeight(),
							    tP = 0,
							    tHT = tH;
							if (fade) {
								tH = 0;
								tP = tHT * 100 / elSize;
							}
							ob.css({
								'height': tH + 'px',
								'padding-bottom': tP + '%'
							});
						};
						setCss();
						if (obj.find('img').length) {
							if (obj.find('img')[0].complete) {
								setCss();
								if (!interval) {
									$this.auto();
								}
							} else {
								obj.find('img').on('load', function () {
									setTimeout(function () {
										setCss();
										if (!interval) {
											$this.auto();
										}
									}, 100);
								});
							}
						} else {
							if (!interval) {
								$this.auto();
							}
						}
					},
					active: function active(ob, t) {
						if (this.doCss() && settings.mode === 'fade') {
							$slide.addClass('on');
						}
						var sc = 0;
						if (scene * settings.slideMove < length) {
							ob.removeClass('active');
							if (!this.doCss() && settings.mode === 'fade' && t === false) {
								ob.fadeOut(settings.speed);
							}
							if (t === true) {
								sc = scene;
							} else {
								sc = scene * settings.slideMove;
							}
							var l, nl;
							if (t === true) {
								l = ob.length;
								nl = l - 1;
								if (sc + 1 >= l) {
									sc = nl;
								}
							}
							if (settings.loop === true && settings.mode === 'slide') {
								if (t === true) {
									sc = scene - $el.find('.clone.left').length;
								} else {
									sc = scene * settings.slideMove;
								}
								if (t === true) {
									l = ob.length;
									nl = l - 1;
									if (sc + 1 === l) {
										sc = nl;
									} else if (sc + 1 > l) {
										sc = 0;
									}
								}
							}

							if (!this.doCss() && settings.mode === 'fade' && t === false) {
								ob.eq(sc).fadeIn(settings.speed);
							}
							ob.eq(sc).addClass('active');
						} else {
							ob.removeClass('active');
							ob.eq(ob.length - 1).addClass('active');
							if (!this.doCss() && settings.mode === 'fade' && t === false) {
								ob.fadeOut(settings.speed);
								ob.eq(sc).fadeIn(settings.speed);
							}
						}
					},
					move: function move(ob, v) {
						if (settings.rtl === true) {
							v = -v;
						}
						if (this.doCss()) {
							if (settings.vertical === true) {
								ob.css({
									'transform': 'translate3d(0px, ' + -v + 'px, 0px)',
									'-webkit-transform': 'translate3d(0px, ' + -v + 'px, 0px)'
								});
							} else {
								ob.css({
									'transform': 'translate3d(' + -v + 'px, 0px, 0px)',
									'-webkit-transform': 'translate3d(' + -v + 'px, 0px, 0px)'
								});
							}
						} else {
							if (settings.vertical === true) {
								ob.css('position', 'relative').animate({
									top: -v + 'px'
								}, settings.speed, settings.easing);
							} else {
								ob.css('position', 'relative').animate({
									left: -v + 'px'
								}, settings.speed, settings.easing);
							}
						}
						var $thumb = $slide.parent().find('.lSPager').find('li');
						this.active($thumb, true);
					},
					fade: function fade() {
						this.active($children, false);
						var $thumb = $slide.parent().find('.lSPager').find('li');
						this.active($thumb, true);
					},
					slide: function slide() {
						var $this = this;
						refresh.calSlide = function () {
							if (w > elSize) {
								slideValue = $this.slideValue();
								$this.active($children, false);
								if (slideValue > w - elSize - settings.slideMargin) {
									slideValue = w - elSize - settings.slideMargin;
								} else if (slideValue < 0) {
									slideValue = 0;
								}
								$this.move($el, slideValue);
								if (settings.loop === true && settings.mode === 'slide') {
									if (scene >= length - $el.find('.clone.left').length / settings.slideMove) {
										$this.resetSlide($el.find('.clone.left').length);
									}
									if (scene === 0) {
										$this.resetSlide($slide.find('.lslide').length);
									}
								}
							}
						};
						refresh.calSlide();
					},
					resetSlide: function resetSlide(s) {
						var $this = this;
						$slide.find('.lSAction a').addClass('disabled');
						setTimeout(function () {
							scene = s;
							$slide.css('transition-duration', '0ms');
							slideValue = $this.slideValue();
							$this.active($children, false);
							plugin.move($el, slideValue);
							setTimeout(function () {
								$slide.css('transition-duration', settings.speed + 'ms');
								$slide.find('.lSAction a').removeClass('disabled');
							}, 50);
						}, settings.speed + 100);
					},
					slideValue: function slideValue() {
						var _sV = 0;
						if (settings.autoWidth === false) {
							_sV = scene * ((slideWidth + settings.slideMargin) * settings.slideMove);
						} else {
							_sV = 0;
							for (var i = 0; i < scene; i++) {
								_sV += parseInt($children.eq(i).width()) + settings.slideMargin;
							}
						}
						return _sV;
					},
					slideThumb: function slideThumb() {
						var position;
						switch (settings.currentPagerPosition) {
							case 'left':
								position = 0;
								break;
							case 'middle':
								position = elSize / 2 - thumbWidth / 2;
								break;
							case 'right':
								position = elSize - thumbWidth;
						}
						var sc = scene - $el.find('.clone.left').length;
						var $pager = $slide.parent().find('.lSPager');
						if (settings.mode === 'slide' && settings.loop === true) {
							if (sc >= $pager.children().length) {
								sc = 0;
							} else if (sc < 0) {
								sc = $pager.children().length;
							}
						}
						var thumbSlide = sc * (thumbWidth + settings.thumbMargin) - position;
						if (thumbSlide + elSize > pagerWidth) {
							thumbSlide = pagerWidth - elSize - settings.thumbMargin;
						}
						if (thumbSlide < 0) {
							thumbSlide = 0;
						}
						this.move($pager, thumbSlide);
					},
					auto: function auto() {
						if (settings.auto) {
							clearInterval(interval);
							interval = setInterval(function () {
								$el.goToNextSlide();
							}, settings.pause);
						}
					},
					pauseOnHover: function pauseOnHover() {
						var $this = this;
						if (settings.auto && settings.pauseOnHover) {
							$slide.on('mouseenter', function () {
								$(this).addClass('ls-hover');
								$el.pause();
								settings.auto = true;
							});
							$slide.on('mouseleave', function () {
								$(this).removeClass('ls-hover');
								if (!$slide.find('.lightSlider').hasClass('lsGrabbing')) {
									$this.auto();
								}
							});
						}
					},
					touchMove: function touchMove(endCoords, startCoords) {
						$slide.css('transition-duration', '0ms');
						if (settings.mode === 'slide') {
							var distance = endCoords - startCoords;
							var swipeVal = slideValue - distance;
							if (swipeVal >= w - elSize - settings.slideMargin) {
								if (settings.freeMove === false) {
									swipeVal = w - elSize - settings.slideMargin;
								} else {
									var swipeValT = w - elSize - settings.slideMargin;
									swipeVal = swipeValT + (swipeVal - swipeValT) / 5;
								}
							} else if (swipeVal < 0) {
								if (settings.freeMove === false) {
									swipeVal = 0;
								} else {
									swipeVal = swipeVal / 5;
								}
							}
							this.move($el, swipeVal);
						}
					},

					touchEnd: function touchEnd(distance) {
						$slide.css('transition-duration', settings.speed + 'ms');
						if (settings.mode === 'slide') {
							var mxVal = false;
							var _next = true;
							slideValue = slideValue - distance;
							if (slideValue > w - elSize - settings.slideMargin) {
								slideValue = w - elSize - settings.slideMargin;
								if (settings.autoWidth === false) {
									mxVal = true;
								}
							} else if (slideValue < 0) {
								slideValue = 0;
							}
							var gC = function gC(next) {
								var ad = 0;
								if (!mxVal) {
									if (next) {
										ad = 1;
									}
								}
								if (!settings.autoWidth) {
									var num = slideValue / ((slideWidth + settings.slideMargin) * settings.slideMove);
									scene = parseInt(num) + ad;
									if (slideValue >= w - elSize - settings.slideMargin) {
										if (num % 1 !== 0) {
											scene++;
										}
									}
								} else {
									var tW = 0;
									for (var i = 0; i < $children.length; i++) {
										tW += parseInt($children.eq(i).width()) + settings.slideMargin;
										scene = i + ad;
										if (tW >= slideValue) {
											break;
										}
									}
								}
							};
							if (distance >= settings.swipeThreshold) {
								gC(false);
								_next = false;
							} else if (distance <= -settings.swipeThreshold) {
								gC(true);
								_next = false;
							}
							$el.mode(_next);
							this.slideThumb();
						} else {
							if (distance >= settings.swipeThreshold) {
								$el.goToPrevSlide();
							} else if (distance <= -settings.swipeThreshold) {
								$el.goToNextSlide();
							}
						}
					},

					enableDrag: function enableDrag() {
						var $this = this;
						if (!isTouch) {
							var startCoords = 0,
							    endCoords = 0,
							    isDraging = false;
							$slide.find('.lightSlider').addClass('lsGrab');
							$slide.on('mousedown', function (e) {
								if (w < elSize) {
									if (w !== 0) {
										return false;
									}
								}
								if ($(e.target).attr('class') !== 'lSPrev' && $(e.target).attr('class') !== 'lSNext') {
									startCoords = settings.vertical === true ? e.pageY : e.pageX;
									isDraging = true;
									if (e.preventDefault) {
										e.preventDefault();
									} else {
										e.returnValue = false;
									}
									$slide.scrollLeft += 1;
									$slide.scrollLeft -= 1;
									$slide.find('.lightSlider').removeClass('lsGrab').addClass('lsGrabbing');
									clearInterval(interval);
								}
							});
							$(window).on('mousemove', function (e) {
								if (isDraging) {
									endCoords = settings.vertical === true ? e.pageY : e.pageX;
									$this.touchMove(endCoords, startCoords);
								}
							});
							$(window).on('mouseup', function (e) {
								if (isDraging) {
									$slide.find('.lightSlider').removeClass('lsGrabbing').addClass('lsGrab');
									isDraging = false;
									endCoords = settings.vertical === true ? e.pageY : e.pageX;
									var distance = endCoords - startCoords;
									if (Math.abs(distance) >= settings.swipeThreshold) {
										$(window).on('click.ls', function (e) {
											if (e.preventDefault) {
												e.preventDefault();
											} else {
												e.returnValue = false;
											}
											e.stopImmediatePropagation();
											e.stopPropagation();
											$(window).off('click.ls');
										});
									}

									$this.touchEnd(distance);
								}
							});
						}
					},

					enableTouch: function enableTouch() {
						var $this = this;
						if (isTouch) {
							var startCoords = {},
							    endCoords = {};
							$slide.on('touchstart', function (e) {
								endCoords = e.originalEvent.targetTouches[0];
								startCoords.pageX = e.originalEvent.targetTouches[0].pageX;
								startCoords.pageY = e.originalEvent.targetTouches[0].pageY;
								clearInterval(interval);
							});
							$slide.on('touchmove', function (e) {
								if (w < elSize) {
									if (w !== 0) {
										return false;
									}
								}
								var orig = e.originalEvent;
								endCoords = orig.targetTouches[0];
								var xMovement = Math.abs(endCoords.pageX - startCoords.pageX);
								var yMovement = Math.abs(endCoords.pageY - startCoords.pageY);
								if (settings.vertical === true) {
									if (yMovement * 3 > xMovement) {
										e.preventDefault();
									}
									$this.touchMove(endCoords.pageY, startCoords.pageY);
								} else {
									if (xMovement * 3 > yMovement) {
										e.preventDefault();
									}
									$this.touchMove(endCoords.pageX, startCoords.pageX);
								}
							});
							$slide.on('touchend', function () {
								if (w < elSize) {
									if (w !== 0) {
										return false;
									}
								}
								var distance;
								if (settings.vertical === true) {
									distance = endCoords.pageY - startCoords.pageY;
								} else {
									distance = endCoords.pageX - startCoords.pageX;
								}
								$this.touchEnd(distance);
							});
						}
					},
					build: function build() {
						var $this = this;
						$this.initialStyle();
						if (this.doCss()) {

							if (settings.enableTouch === true) {
								$this.enableTouch();
							}
							if (settings.enableDrag === true) {
								$this.enableDrag();
							}
						}

						$(window).on('focus', function () {
							$this.auto();
						});

						$(window).on('blur', function () {
							clearInterval(interval);
						});

						$this.pager();
						$this.pauseOnHover();
						$this.controls();
						$this.keyPress();
					}
				};
				plugin.build();
				refresh.init = function () {
					refresh.chbreakpoint();
					if (settings.vertical === true) {
						if (settings.item > 1) {
							elSize = settings.verticalHeight;
						} else {
							elSize = $children.outerHeight();
						}
						$slide.css('height', elSize + 'px');
					} else {
						elSize = $slide.outerWidth();
					}
					if (settings.loop === true && settings.mode === 'slide') {
						refresh.clone();
					}
					refresh.calL();
					if (settings.mode === 'slide') {
						$el.removeClass('lSSlide');
					}
					if (settings.mode === 'slide') {
						refresh.calSW();
						refresh.sSW();
					}
					setTimeout(function () {
						if (settings.mode === 'slide') {
							$el.addClass('lSSlide');
						}
					}, 1000);
					if (settings.pager) {
						refresh.createPager();
					}
					if (settings.adaptiveHeight === true && settings.vertical === false) {
						$el.css('height', $children.eq(scene).outerHeight(true));
					}
					if (settings.adaptiveHeight === false) {
						if (settings.mode === 'slide') {
							if (settings.vertical === false) {
								plugin.setHeight($el, false);
							} else {
								plugin.auto();
							}
						} else {
							plugin.setHeight($el, true);
						}
					}
					if (settings.gallery === true) {
						plugin.slideThumb();
					}
					if (settings.mode === 'slide') {
						plugin.slide();
					}
					if (settings.autoWidth === false) {
						if ($children.length <= settings.item) {
							$slide.find('.lSAction').hide();
						} else {
							$slide.find('.lSAction').show();
						}
					} else {
						if (refresh.calWidth(false) < elSize && w !== 0) {
							$slide.find('.lSAction').hide();
						} else {
							$slide.find('.lSAction').show();
						}
					}
				};
				$el.goToPrevSlide = function () {
					if (scene > 0) {
						settings.onBeforePrevSlide.call(this, $el, scene);
						scene--;
						$el.mode(false);
						if (settings.gallery === true) {
							plugin.slideThumb();
						}
					} else {
						if (settings.loop === true) {
							settings.onBeforePrevSlide.call(this, $el, scene);
							if (settings.mode === 'fade') {
								var l = length - 1;
								scene = parseInt(l / settings.slideMove);
							}
							$el.mode(false);
							if (settings.gallery === true) {
								plugin.slideThumb();
							}
						} else if (settings.slideEndAnimation === true) {
							$el.addClass('leftEnd');
							setTimeout(function () {
								$el.removeClass('leftEnd');
							}, 400);
						}
					}
				};
				$el.goToNextSlide = function () {
					var nextI = true;
					if (settings.mode === 'slide') {
						var _slideValue = plugin.slideValue();
						nextI = _slideValue < w - elSize - settings.slideMargin;
					}
					if (scene * settings.slideMove < length - settings.slideMove && nextI) {
						settings.onBeforeNextSlide.call(this, $el, scene);
						scene++;
						$el.mode(false);
						if (settings.gallery === true) {
							plugin.slideThumb();
						}
					} else {
						if (settings.loop === true) {
							settings.onBeforeNextSlide.call(this, $el, scene);
							scene = 0;
							$el.mode(false);
							if (settings.gallery === true) {
								plugin.slideThumb();
							}
						} else if (settings.slideEndAnimation === true) {
							$el.addClass('rightEnd');
							setTimeout(function () {
								$el.removeClass('rightEnd');
							}, 400);
						}
					}
				};
				$el.mode = function (_touch) {
					if (settings.adaptiveHeight === true && settings.vertical === false) {
						$el.css('height', $children.eq(scene).outerHeight(true));
					}
					if (on === false) {
						if (settings.mode === 'slide') {
							if (plugin.doCss()) {
								$el.addClass('lSSlide');
								if (settings.speed !== '') {
									$slide.css('transition-duration', settings.speed + 'ms');
								}
								if (settings.cssEasing !== '') {
									$slide.css('transition-timing-function', settings.cssEasing);
								}
							}
						} else {
							if (plugin.doCss()) {
								if (settings.speed !== '') {
									$el.css('transition-duration', settings.speed + 'ms');
								}
								if (settings.cssEasing !== '') {
									$el.css('transition-timing-function', settings.cssEasing);
								}
							}
						}
					}
					if (!_touch) {
						settings.onBeforeSlide.call(this, $el, scene);
					}
					if (settings.mode === 'slide') {
						plugin.slide();
					} else {
						plugin.fade();
					}
					if (!$slide.hasClass('ls-hover')) {
						plugin.auto();
					}
					setTimeout(function () {
						if (!_touch) {
							settings.onAfterSlide.call(this, $el, scene);
						}
					}, settings.speed);
					on = true;
				};
				$el.play = function () {
					$el.goToNextSlide();
					settings.auto = true;
					plugin.auto();
				};
				$el.pause = function () {
					settings.auto = false;
					clearInterval(interval);
				};
				$el.refresh = function () {
					refresh.init();
				};
				$el.getCurrentSlideCount = function () {
					var sc = scene;
					if (settings.loop) {
						var ln = $slide.find('.lslide').length,
						    cl = $el.find('.clone.left').length;
						if (scene <= cl - 1) {
							sc = ln + (scene - cl);
						} else if (scene >= ln + cl) {
							sc = scene - ln - cl;
						} else {
							sc = scene - cl;
						}
					}
					return sc + 1;
				};
				$el.getTotalSlideCount = function () {
					return $slide.find('.lslide').length;
				};
				$el.goToSlide = function (s) {
					if (settings.loop) {
						scene = s + $el.find('.clone.left').length - 1;
					} else {
						scene = s;
					}
					$el.mode(false);
					if (settings.gallery === true) {
						plugin.slideThumb();
					}
				};
				$el.destroy = function () {
					if ($el.lightSlider) {
						$el.goToPrevSlide = function () {};
						$el.goToNextSlide = function () {};
						$el.mode = function () {};
						$el.play = function () {};
						$el.pause = function () {};
						$el.refresh = function () {};
						$el.getCurrentSlideCount = function () {};
						$el.getTotalSlideCount = function () {};
						$el.goToSlide = function () {};
						$el.lightSlider = null;
						refresh = {
							init: function init() {}
						};
						$el.parent().parent().find('.lSAction, .lSPager').remove();
						$el.removeClass('lightSlider lSFade lSSlide lsGrab lsGrabbing leftEnd right').removeAttr('style').unwrap().unwrap();
						$el.children().removeAttr('style');
						$children.removeClass('lslide active');
						$el.find('.clone').remove();
						$children = null;
						interval = null;
						on = false;
						scene = 0;
					}
				};
				setTimeout(function () {
					settings.onSliderLoad.call(this, $el);
				}, 10);
				$(window).on('resize orientationchange', function (e) {
					setTimeout(function () {
						if (e.preventDefault) {
							e.preventDefault();
						} else {
							e.returnValue = false;
						}
						refresh.init();
					}, 200);
				});
				return this;
			};
		})(jQuery);
	}, {}] }, {}, [3]);